import { Passwordless } from '.';

/**
 * Copyright Amazon.com, Inc. and its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You
 * may not use this file except in compliance with the License. A copy of
 * the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is
 * distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF
 * ANY KIND, either express or implied. See the License for the specific
 * language governing permissions and limitations under the License.
 */
export function determineMobileDeviceName() {
  const mobileDevices = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
  ] as const;
  return mobileDevices.find((dev) =>
    // eslint-disable-next-line security/detect-non-literal-regexp
    navigator.userAgent.match(new RegExp(dev, 'i')),
  );
}

export function determineOtpIssuer() {
  if (typeof window !== 'undefined') {
    return Passwordless.configure().totp?.issuer;
  } else {
    return 'VerifiMe';
  }
}

const cognitoStoragePrefix = 'CognitoIdentityServiceProvider';
export function getSignedInCustomerEmail() {
  const configure = Passwordless.configure;
  const { clientId, storage } = configure();

  const lastAuthUser = storage.getItem(`${cognitoStoragePrefix}.${clientId}.LastAuthUser`);

  if (!lastAuthUser) {
    return null;
  }

  const stringUserData = storage.getItem(
    `${cognitoStoragePrefix}.${clientId}.${lastAuthUser}.userData`,
  ) as string;
  const { UserAttributes } = JSON.parse(stringUserData);

  const emailAttribute = UserAttributes.find(({ Name }: { Name: string }) => Name === 'email');

  if (!emailAttribute) {
    return null;
  }
  return emailAttribute.Value;
}

export function getLastSignedInCustomerEmail() {
  const configure = Passwordless.configure;
  const { clientId, storage } = configure();
  const stringLastUsers = storage.getItem(`Passwordless.${clientId}.lastUsers`) as string;
  if (!stringLastUsers) {
    return null;
  }
  const lastUsers = JSON.parse(stringLastUsers);
  const lastUserEmail = lastUsers[0]?.['email'];
  return lastUserEmail;
}
