import { Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';
import SuccessIcon from './success.svg';

export default function CheckSuccess({ customisedSuccess }: { customisedSuccess?: ReactNode }) {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <SuccessIcon />
        <Typography variant="body1">
          Thank you for completing the face check.
          <br />
        </Typography>
        {customisedSuccess}
      </Stack>
    </Paper>
  );
}
