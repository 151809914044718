import { TAddress, TPartnership } from '@verifime/utils';
import DisplayAddress from './DisplayAddress';
import InformationDisplay, { TInformationDisplayRows } from './InformationDisplay';

const partnershipInformationRows: TInformationDisplayRows<keyof TPartnership> = {
  row1: [{ label: 'Partnership Name', fieldName: 'partnershipName' }],
  row2: [{ label: 'Australian Business Number (ABN)', fieldName: 'businessNumber' }],
  row3: [
    {
      label: 'Principal Place of Business',
      fieldName: 'principalPlaceOfBusiness',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

export default function PartnershipContact({ identity }: { identity: TPartnership }) {
  return (
    <InformationDisplay<TPartnership>
      informationDisplaysRows={partnershipInformationRows}
      data={identity}
    />
  );
}
