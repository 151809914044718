import { Alert } from '@mui/material';
import { FlexBox, NoDataFound } from '@verifime/components';

export default function PEP({
  type,
  isMatched,
}: {
  type: 'PEP' | 'Sanction';
  isMatched?: boolean;
}) {
  if (typeof isMatched === undefined) {
    return <NoDataFound />;
  }

  return (
    <FlexBox sx={{ flexDirection: 'column' }}>
      <FlexBox sx={{ flex: 1 }}>
        {isMatched ? (
          <Alert style={{ width: '100%' }} severity="error">
            Found matching {type}
          </Alert>
        ) : (
          <Alert style={{ width: '100%' }} severity="success">
            No {type} matches found
          </Alert>
        )}
      </FlexBox>
    </FlexBox>
  );
}
