import { LoginMessageContainer } from './common/LoginContainer';
import { LoginStatusMessageDisplay } from './common/LoginStatusMessageDisplay';

export default function ValidatingSignInStatus() {
  return (
    <LoginMessageContainer>
      <LoginStatusMessageDisplay>Checking your sign-in status...</LoginStatusMessageDisplay>
    </LoginMessageContainer>
  );
}
