import LightHorizontalLogo from './logo-light-horizontal.svg';
import LightStackedLogo from './logo-light-stacked.svg';
import LightMarkOnlyLogo from './logo-light-markOnly.svg';
import DarkHorizontalLogo from './logo-dark-horizontal.svg';
import DarkStackedLogo from './logo-dark-stacked.svg';
import DarkMarkOnlyLogo from './logo-dark-markOnly.svg';
import OnPrimaryHorizontalLogo from './logo-onPrimary-horizontal.svg';
import OnPrimaryMarkOnlyLogo from './logo-onPrimary-markOnly.svg';
import OnPrimaryStackedLogo from './logo-onPrimary-stacked.svg';

import { SvgIcon } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { FC, SVGAttributes } from 'react';

export type TLogoColorMode = 'light' | 'dark' | 'onPrimary';
export type TLogoSize = 'small' | 'medium' | 'large';
export type TLogoLayout = 'markOnly' | 'horizontal' | 'stacked';
type TIconMapping = { [key in TLogoColorMode]: FC<SVGAttributes<SVGAElement>> };
type TSizeMapping = { [key in TLogoSize]: string };
type TSvgIconMapping = {
  [key in TLogoLayout]: { icon: TIconMapping; size: TSizeMapping; viewbox: string };
};

// make sure viewbox property matches each of the viewbox in the svg file.
// This is important to ensure the icons can be scaled properly.
const ICON_MAPPINGS: TSvgIconMapping = {
  horizontal: {
    icon: {
      light: LightHorizontalLogo,
      dark: DarkHorizontalLogo,
      onPrimary: OnPrimaryHorizontalLogo,
    },
    size: {
      small: tokens.size7xs,
      medium: tokens.size6xs,
      large: tokens.size4xs,
    },
    viewbox: '0 0 195 64',
  },
  stacked: {
    icon: {
      light: LightStackedLogo,
      dark: DarkStackedLogo,
      onPrimary: OnPrimaryStackedLogo,
    },
    size: {
      small: tokens.size5xs,
      medium: tokens.size4xs,
      large: tokens.size3xs,
    },
    viewbox: '0 0 139 128',
  },
  markOnly: {
    icon: {
      light: LightMarkOnlyLogo,
      dark: DarkMarkOnlyLogo,
      onPrimary: OnPrimaryMarkOnlyLogo,
    },
    size: {
      small: tokens.size6xs,
      medium: tokens.size5xs,
      large: tokens.size4xs,
    },
    viewbox: '0 0 64 64',
  },
};

export default function Logo({
  size = 'medium',
  layout = 'horizontal',
  colorMode = 'light',
}: {
  colorMode?: TLogoColorMode;
  layout?: TLogoLayout;
  size?: TLogoSize;
}) {
  const { icon: iconMapping, size: sizeMapping, viewbox } = ICON_MAPPINGS[layout];

  return (
    <SvgIcon
      sx={{
        width: 'auto',
        height: sizeMapping[size],
        fontSize: 'inherit',
      }}
      viewBox={viewbox}
      component={iconMapping[colorMode]}
    />
  );
}
