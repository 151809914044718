import { Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import MoreOptions from './MoreOptions';
import NoCameraIcon from './noCamera.svg';
import QRCodeIcon from './qr-code.svg';

export default function NoCamera({
  onSkipConfirm,
  isEnableOptions = true,
  isShowQrCodeWhenNoCamera = false,
}: {
  onSkipConfirm: () => void;
  isEnableOptions?: boolean;
  isShowQrCodeWhenNoCamera?: boolean;
}) {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <NoCameraIcon />
        <Typography variant="h6">Unable to detect a camera</Typography>
        <Typography variant="body2" color="textSecondary">
          Please make sure your device has a working camera and that your browser has access to it.
        </Typography>
        {isEnableOptions && (
          <MoreOptions
            leadingContent={
              <>
                <Typography variant="body2">Scan code to continue on a different device</Typography>
                <QRCodeIcon />
                <Typography variant="body2">OR</Typography>
              </>
            }
            onConfirm={onSkipConfirm}
          />
        )}
        {isShowQrCodeWhenNoCamera && (
          <>
            <Typography variant="body2">Scan code to continue on a different device</Typography>
            <QRCodeIcon />
          </>
        )}
      </Stack>
    </Paper>
  );
}
