import { Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';
import CheckErrorIcon from './error.svg';

export default function ReachedMaxTries({
  customisedReachMaxTries,
}: {
  customisedReachMaxTries?: ReactNode;
}) {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <CheckErrorIcon />
        <Typography variant="h6">Something went wrong</Typography>
        <Stack gap={tokens.spacingBase}>
          <Typography variant="body2" textAlign="center">
            We are unable to complete a face check at the moment.
          </Typography>
          {customisedReachMaxTries}
        </Stack>
      </Stack>
    </Paper>
  );
}
