import { TAddress, TSoleTrader } from '@verifime/utils';
import DisplayAddress from './DisplayAddress';
import InformationDisplay, { TInformationDisplayRows } from './InformationDisplay';

const soleTraderInformationRows: TInformationDisplayRows<keyof TSoleTrader> = {
  row1: [{ label: 'Business Name', fieldName: 'soleTraderName' }],
  row2: [{ label: 'Australian Business Number (ABN)', fieldName: 'businessNumber' }],
  row3: [
    {
      label: 'Principal Place of Business',
      fieldName: 'principalPlaceOfBusiness',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

export default function SoleTraderContact({ identity }: { identity: TSoleTrader }) {
  return (
    <InformationDisplay<TSoleTrader>
      informationDisplaysRows={soleTraderInformationRows}
      data={identity}
    />
  );
}
