import { ReactNode } from 'react';
import { Box, Stack, Typography, TypographyTypeMap, StackProps } from '@mui/material';
import CopyableText from './CopyableText';
import tokens from '@verifime/design-tokens';

type TypographyVariant = TypographyTypeMap['props']['variant'];

export type TNameValueDisplayType = 'vertical' | 'horizontal';

export type TNameValueDisplayProps = {
  name: string;
  value?: ReactNode;
  copyable?: boolean;
  displayType?: TNameValueDisplayType;
  nameColor?: TypographyTypeMap['props']['color'];
  valueColor?: TypographyTypeMap['props']['color'];
  nameVariant?: TypographyVariant;
  valueVariant?: TypographyVariant;
} & StackProps;

function HorizontalNameValueDisplay({
  nameVariant = 'body2',
  alignItems = 'center',
  gap = tokens.spacing2xs,
  ...props
}: Omit<TNameValueDisplayProps, 'displayType' | 'direction'>) {
  return (
    <NameValueDisplayInternal
      nameVariant={nameVariant}
      flex={1}
      gap={gap}
      alignItems={alignItems}
      {...props}
      direction="row"
    />
  );
}

function VerticalNameValueDisplay({
  nameVariant = 'caption',
  alignItems = 'flex-start',
  gap = tokens.spacing3xs,
  ...props
}: Omit<TNameValueDisplayProps, 'displayType' | 'direction'>) {
  return (
    <NameValueDisplayInternal
      nameVariant={nameVariant}
      flex={1}
      gap={gap}
      alignItems={alignItems}
      {...props}
      direction="column"
    />
  );
}

function NameValueDisplayInternal({
  name,
  value,
  copyable = false,
  nameVariant,
  nameColor = 'textSecondary',
  valueColor = 'textPrimary',
  valueVariant = 'body2',
  ...containerProps
}: Omit<TNameValueDisplayProps, 'nameVariant'> & { nameVariant: TypographyVariant }) {
  return (
    <Stack {...containerProps}>
      <Typography color={nameColor} variant={nameVariant}>
        {name}
      </Typography>
      {['string', 'number', 'boolean'].includes(typeof value) ? (
        copyable ? (
          <CopyableText
            text={value}
            notAvailableText="n/a"
            textColor={valueColor}
            textVariant={valueVariant}
          />
        ) : (
          <Typography color={valueColor} variant={valueVariant} sx={{ whiteSpace: 'pre-wrap' }}>
            {value}
          </Typography>
        )
      ) : (
        <Box minHeight={tokens.fontSizeBase}>{value}</Box>
      )}
    </Stack>
  );
}

export default function NameValueDisplay({
  displayType = 'vertical',
  ...props
}: TNameValueDisplayProps) {
  return displayType === 'vertical' ? (
    <VerticalNameValueDisplay {...props} />
  ) : (
    <HorizontalNameValueDisplay {...props} />
  );
}
