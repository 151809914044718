import { Brightness7 as DarkModeIcon, Brightness4 as LightModeIcon } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ColorModeContext } from '@verifime/styles';
import { useContext } from 'react';

export default function ToggleColorMode() {
  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: tokens.spacingMarginBottom,
      }}
    >
      <IconButton onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>
    </Box>
  );
}
