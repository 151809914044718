import { ReactNode } from 'react';
import NameValueDisplay, { TNameValueDisplayProps } from './NameValueDisplay';
import Stack from '@mui/material/Stack';
import tokens from '@verifime/design-tokens';
import { DisplayVariant } from './Form/utils';
import { createFormFieldStack } from './Form/FormFieldsContainerSupportDisplayVariant';

export type TInformationItemDisplay<F> = {
  label: string;
  fieldName: F | F[];
  copyable?: boolean;
  displayType?: TNameValueDisplayProps['displayType'];
  labelColor?: TNameValueDisplayProps['nameColor'];
  labelVariant?: TNameValueDisplayProps['nameVariant'];
  valueColor?: TNameValueDisplayProps['valueColor'];
  valueVariant?: TNameValueDisplayProps['valueVariant'];
  valueFormatter?: (value: any) => ReactNode;
};

export type TInformationDisplayRows<F> = {
  [rowNumber: string]: TInformationItemDisplay<F>[];
};

export type TInformationDisplayProps<T> = {
  informationDisplaysRows: TInformationDisplayRows<keyof T>;
  data: T;
  variant?: DisplayVariant;
};

export default function InformationDisplay<T>({
  informationDisplaysRows,
  data,
  variant = DisplayVariant.MULTIPLE_COLUMNS,
}: TInformationDisplayProps<T>) {
  const ContainerComponent = createFormFieldStack(variant, {
    justifyContent: 'space-between',
  });

  return (
    <Stack rowGap={tokens.spacingLg}>
      {Object.entries(informationDisplaysRows).map(([rowNumber, inforDisplays]) => {
        return (
          <ContainerComponent key={rowNumber}>
            {inforDisplays.map((inforDisplay) => {
              let value = null;
              if (Array.isArray(inforDisplay.fieldName)) {
                const fieldsValue = inforDisplay.fieldName.reduce(
                  (tally, fieldName) => [...tally, data[fieldName]],
                  [],
                );
                value = inforDisplay?.valueFormatter?.(fieldsValue);
              } else {
                value =
                  inforDisplay?.valueFormatter?.(data[inforDisplay.fieldName]) ??
                  (data[inforDisplay.fieldName] as ReactNode);
              }

              return (
                <NameValueDisplay
                  key={inforDisplay.label}
                  name={inforDisplay.label}
                  value={value}
                  copyable={inforDisplay.copyable ?? true}
                  displayType={inforDisplay.displayType}
                  nameColor={inforDisplay.labelColor}
                  nameVariant={inforDisplay.labelVariant}
                  valueColor={inforDisplay.valueColor}
                  valueVariant={inforDisplay.valueVariant}
                />
              );
            })}
          </ContainerComponent>
        );
      })}
    </Stack>
  );
}
