import { DataGrid, DataGridProps, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import NoDataFound from './NoDataFound';
export type TDataGridColumns = GridColDef[];
export type TDataGridRows = GridRowsProp;

export type TDataGridProps = {
  columns: TDataGridColumns;
  rows: TDataGridRows;
  isDisableCellSelectionHighlight?: boolean;
} & DataGridProps;

export default function ({ isDisableCellSelectionHighlight = true, ...props }: TDataGridProps) {
  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      pageSizeOptions={[10, 25, 50]}
      slots={{ noRowsOverlay: NoDataFound }}
      {...props}
      sx={{
        ...props.sx,
        [isDisableCellSelectionHighlight && '.MuiDataGrid-cell:focus']: {
          outline: 'none',
        },
        [typeof props.onRowDoubleClick === 'function' && '.MuiDataGrid-row:hover']: {
          cursor: 'pointer',
        },
      }}
    />
  );
}
