export type TFeatureToggle = {
  isSetupCredentialNotAfterActivateTrackId: boolean;
};

export enum FeatureToggle {
  On = 'on',
  Off = 'off',
}

export function useFeatureToggle(): TFeatureToggle {
  return {
    isSetupCredentialNotAfterActivateTrackId:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SETUP_CREDENTIAL_NOT_AFTER_ACTIVATE_TRACK_ID ===
      FeatureToggle.On,
  };
}
