/**
 * @desc All support document sub types and whether they allowed be created for a customer entity
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** entityId */
  entityId;
}

export const method = 'GET';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey(
      '/v1/support_document/customer/{entityId}/sub_types',
      params,
      'GET',
    ),
    newValue,
    shouldRevalidate,
  );
}

export function useRequest(params = {}, swrOptions = {}) {
  return Hooks.useRequest(
    '/v1/support_document/customer/{entityId}/sub_types',
    params,
    swrOptions,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl(
    '/v1/support_document/customer/{entityId}/sub_types',
    params,
    'GET',
    option,
  );
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'GET',
  });
}
