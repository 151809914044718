'use client';

import { forwardRef, useRef } from 'react';
import { Typography, Box, OutlinedInput, useTheme } from '@mui/material';
import UploadFileRoundedIcon from '@mui/icons-material/ImageRounded';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import tokens from '@verifime/design-tokens';
import { FlexBox } from '@verifime/components';
import { FileType } from '@verifime/utils';

type InputProps = InputBaseComponentProps & {
  text: string;
  isPlaceholder: boolean;
};

const Input = forwardRef(
  (
    { text, isMultiple, accept, onChange, disabled }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();

    return (
      <Box
        sx={{
          position: 'relative',
          paddingY: tokens.spacingXs,
          paddingX: tokens.spacingBase,
          cursor: 'pointer',
        }}
      >
        <FlexBox alignItems="center">
          <UploadFileRoundedIcon color={disabled ? 'disabled' : 'primary'} />
          <Typography color={disabled ? theme.palette.action.disabled : 'primary'} variant="button">
            {text}
          </Typography>
        </FlexBox>
        <input
          ref={ref}
          type="file"
          multiple={isMultiple}
          style={{ opacity: 0, position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          accept={accept}
          onChange={onChange}
          disabled={disabled}
        ></input>
      </Box>
    );
  },
);

export type FileSelectorProps = Omit<
  MuiTextFieldProps,
  'onChange' | 'select' | 'type' | 'multiline' | 'defaultValue'
> & {
  isMultiple?: boolean;
  isDisabled?: boolean;
  fileTypes?: FileType[];
  // Max file size in MB
  maxFileSizeInMB?: number;
  onChange: (files: File[]) => void;
  // To allow same file to be selected again
  isDetectSameFileSelection?: boolean;
};

export default function FileSelector({
  label,
  onChange,
  isMultiple,
  isDisabled,
  fileTypes = [],
  maxFileSizeInMB: maxFileSize,
  isDetectSameFileSelection,
}: FileSelectorProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  let accept: string;

  if (fileTypes.length > 0) {
    accept = fileTypes.reduce((fileTypes, fileType) => {
      return fileTypes === '' ? fileType : `${fileTypes},${fileType}`;
    }, '');
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const selectedFiles: File[] = fileList ? Array.from(fileList) : [];

    if (typeof onChange === 'function') {
      onChange(selectedFiles);
    }
  };

  const info = () => {
    let info;
    const maxText = maxFileSize ? `max. ${maxFileSize}MB each` : '';

    if (accept) {
      info = accept.toUpperCase();
      if (maxFileSize) {
        info += ` (${maxText})`;
      }
    } else {
      info = maxText;
    }

    return info;
  };

  return (
    <>
      <OutlinedInput
        inputComponent={Input}
        onChange={handleChange}
        inputProps={{
          text: label,
          multiple: isMultiple,
          ref: inputRef,
          accept,
        }}
        value={isDetectSameFileSelection && ''}
        disabled={isDisabled}
      />

      <Typography variant="body2" sx={{ pt: tokens.spacingXs }}>
        {info()}
      </Typography>
    </>
  );
}
