import { Stack, useTheme, Typography } from '@mui/material';
import Logo from './Logo/Logo.svg';
import { ReactNode } from 'react';
import tokens from '@verifime/design-tokens';

export default function Landing({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: ReactNode;
}) {
  const theme = useTheme();

  return (
    <Stack direction={['column', 'column', 'row']} height="100vh">
      <Stack
        sx={{ backgroundColor: theme.palette.primary.main }}
        gap={tokens.spacingLg}
        paddingX={[0, tokens.spacing3xl]}
        paddingY={tokens.spacing4xl}
        flex={[1, 1]}
        justifyContent="center"
        alignItems="center"
        color={theme.palette.primary.contrastText}
      >
        <Logo />
        <Stack maxWidth={tokens.sizeXs} gap={tokens.spacingXs} sx={{ textAlign: 'center' }}>
          {<Typography variant="h5">{title}</Typography>}
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            {description}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        flex={[3, 1]}
        marginTop={[tokens.spacing2xl, tokens.spacing2xl, tokens.spacing6xl]}
        alignItems="center"
      >
        {children}
      </Stack>
    </Stack>
  );
}
