import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';
import { IdentityStatus, IdentityStatusChip } from '../../Identities';
import LivenessViewer, { TlivenessViewerProps } from './LivenessViewer';
import { TLivenessTransaction } from './type';

export type TLivenessPanelProps = {
  showStatus?: boolean;
  fetchLivenessTransaction?: () => Promise<Partial<TLivenessTransaction>>;
} & Omit<TlivenessViewerProps, 'portraitImageUrl' | 'footer' | 'checkedDateTime'>;

function toIdentityStatus(status: TLivenessTransaction['status']): IdentityStatus {
  switch (status) {
    case 'CONFIRMED':
      return IdentityStatus.VERIFIED;
    case 'CANNOT_CONFIRM':
      return IdentityStatus.REJECTED;
    case 'NO_RESULT':
      return IdentityStatus.DRAFT;
  }
}

export default function LivenessPanel({
  showStatus = false,
  fetchLivenessTransaction,
  ...props
}: TLivenessPanelProps) {
  const [livenessTransaction, setLivenessTransaction] = useState<TLivenessTransaction | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchLivenessTransaction?.().then(setLivenessTransaction);
  }, [fetchLivenessTransaction]);

  return (
    <Stack gap={tokens.spacingXs} padding={tokens.spacingXs}>
      {showStatus && (
        <Stack direction="row" justifyContent="flex-start" spacing={tokens.spacing3xs}>
          <Typography variant="h6">Liveness Check</Typography>
          <Box>
            {livenessTransaction?.status && (
              <IdentityStatusChip
                status={toIdentityStatus(livenessTransaction?.status)}
                isCompact
              />
            )}
          </Box>
        </Stack>
      )}
      {!livenessTransaction ? (
        <CircularProgress />
      ) : (
        <LivenessViewer
          portraitImageUrl={livenessTransaction.portraitImageUrl}
          checkedDateTime={livenessTransaction.checkedDateTime}
          requestDetail={{
            ...props.requestDetail,
            // We decided to use whether the onRequest callback exists or not to determine whether display the request button,
            // thus reset onRequest to null to not display the request button once liveness transaction is CONFIRMED
            onRequest:
              livenessTransaction.status === 'CONFIRMED' ? null : props.requestDetail.onRequest,
          }}
          {...props}
        />
      )}
    </Stack>
  );
}
