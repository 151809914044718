import { LoginMessageContainer } from './common/LoginContainer';
import { LoginStatusMessageDisplay } from './common/LoginStatusMessageDisplay';

export function SigningYouIn() {
  return (
    <LoginMessageContainer>
      <LoginStatusMessageDisplay>Signing you in ...</LoginStatusMessageDisplay>
    </LoginMessageContainer>
  );
}
