import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from './TableRow';
import TableCell from './TableCell';
import { TDataTableRow } from '../types';
import Title from '../Title';

export type TDataTable = {
  title?: string;
  headers: string[];
  rows: TDataTableRow[];
  dataCy?: string;
};

export default function DataTable({ title, headers, rows, dataCy }: TDataTable) {
  return (
    <TableContainer component={Paper} data-cy={dataCy}>
      {title && (
        <Title sx={{ mb: '8px' }} data-cy="text-title">
          {title}
        </Title>
      )}
      <Table sx={{ minWidth: 900, tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} data-cy={row.dataCy}>
              {row.cells.map((cell, cellIndex) => (
                <TableCell component="th" data-cy={cell.dataCy} key={`${rowIndex}-${cellIndex}`}>
                  {cell.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
