'use client';

import { useEffect, useRef } from 'react';
import {
  CognitoAccessTokenPayload,
  CognitoIdTokenPayload,
} from 'amazon-cognito-passwordless-auth/jwt-model';
import { usePasswordless } from './usePasswordless';
import { TokensFromStorage } from 'amazon-cognito-passwordless-auth/storage';

const MAX_TRY_TIMES = 10;

const getResolvedData = async (refCurrent: Record<string, any>, key: string): Promise<any> => {
  let resolvedData = null;
  let triedTimes = 0;
  let timerId: NodeJS.Timeout;

  const waitForData = async () => {
    const data: Record<string, any> = await new Promise((resolve) => {
      timerId = setTimeout(() => {
        resolve(refCurrent);
      }, 50);
    });

    if (!data?.[key]) {
      if (triedTimes >= MAX_TRY_TIMES) {
        clearTimeout(timerId);
        return;
      }
      triedTimes++;
      await waitForData();
    } else {
      resolvedData = data?.[key];
      clearTimeout(timerId);
    }
  };

  await waitForData();
  return resolvedData;
};

export default function useTokens() {
  const { tokens, tokensParsed } = usePasswordless();
  const ref = useRef({ tokens, tokensParsed });

  useEffect(() => {
    ref.current.tokens = tokens;
    ref.current.tokensParsed = tokensParsed;
  }, [tokens, tokensParsed]);

  const getTokens = async (): Promise<TokensFromStorage> => {
    const tokens = await getResolvedData(ref.current, 'tokens');
    return tokens!;
  };

  const getTokensParsed = async (): Promise<{
    idToken: CognitoIdTokenPayload;
    accessToken: CognitoAccessTokenPayload;
    expireAt: Date;
  }> => {
    const tokensParsed = await getResolvedData(ref.current, 'tokensParsed');
    return tokensParsed!;
  };

  return { getTokens, getTokensParsed };
}
