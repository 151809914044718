import { Paper, PaperProps } from '@mui/material';
import tokens from '@verifime/design-tokens';

export default function FlexPaper({ ...props }: PaperProps) {
  return (
    <Paper
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingLg,
        padding: tokens.spacingBase,
        ...props.sx,
      }}
    >
      {props.children}
    </Paper>
  );
}
