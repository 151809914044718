'use client';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Theme,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import tokens from '@verifime/design-tokens';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { UrlObject } from 'url';
import { TNavItem } from '../types';
import HomeLink from './HomeLink';
import LogoutButton, { TLogoutButtonEmail } from './LogoutButton';
import ToggleColorMode from './ToggleColorMode';

type TNavHeaderProps = {
  homeNavItem?: TNavItem;
  additionalNavHeaderComponents?: () => JSX.Element;
  navItemHrefProcessor?: (href: UrlObject) => UrlObject;
};

type TNavFooterProps = {
  doSignOut: () => void;
  email?: TLogoutButtonEmail;
};

type TNavMainProps = {
  navItems: TNavItem[];
  navItemHrefProcessor?: (href: UrlObject) => UrlObject;
};

export type TBasicSideNavProps = TNavHeaderProps & TNavFooterProps & TNavMainProps;

const SideNavDivider = () => {
  return (
    <Box width="100%" paddingX={tokens.spacingBase}>
      <Divider />
    </Box>
  );
};

const NavHeader = ({
  homeNavItem,
  additionalNavHeaderComponents,
  navItemHrefProcessor,
}: TNavHeaderProps) => {
  const additionalComponents =
    typeof additionalNavHeaderComponents === 'function' ? additionalNavHeaderComponents() : <></>;
  return (
    <Box
      sx={{
        gridArea: 'header',
      }}
    >
      <HomeLink homeNavItem={homeNavItem} navItemHrefProcessor={navItemHrefProcessor} />
      {additionalComponents}
      <SideNavDivider />
    </Box>
  );
};

const NavFooter = (props: TNavFooterProps) => {
  return (
    <Box
      sx={{
        gridArea: 'footer',
      }}
    >
      <ToggleColorMode />
      <SideNavDivider />
      <LogoutButton {...props} />
    </Box>
  );
};

const NavMain = ({ navItems, navItemHrefProcessor }: TNavMainProps) => {
  const theme = useTheme();
  const router = useRouter();
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    if (navItems.length < 1) {
      return;
    }
    const activeIdx = navItems.findIndex((item) => item.path === router.pathname) || 0;
    setActiveIdx(activeIdx);
  }, [router.pathname, navItems]);

  return (
    <Box
      sx={{
        gridArea: 'main',
      }}
    >
      <List>
        {navItems.map((sideNavItem, index) => {
          return sideNavItem.isDivider === true ? (
            <ListItem key={index}>
              <SideNavDivider />
            </ListItem>
          ) : (
            <ListItem key={index} disablePadding>
              <Box
                component={Link}
                href={navItemHrefProcessor({ pathname: sideNavItem.path })}
                sx={{
                  width: '100%',
                  textDecoration: 'none',
                  color: (theme: Theme) => theme.palette.text.primary,
                }}
                prefetch={false}
              >
                <ListItemButton
                  selected={activeIdx === index}
                  sx={{
                    marginX: tokens.spacingXs,
                    marginY: tokens.spacingXs,
                    borderRadius: tokens.borderRadiusSm,
                    '&.Mui-selected': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.2),
                    },
                  }}
                >
                  <ListItemIcon>
                    <SvgIcon component={sideNavItem.icon} />
                  </ListItemIcon>
                  <ListItemText primary={sideNavItem.label} />
                </ListItemButton>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default function SideNav({
  navItemHrefProcessor: navItemPathProcessor = (href) => href,
  ...otherProps
}: TBasicSideNavProps) {
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: tokens.size2xs,
          boxSizing: 'border-box',
          overflowX: 'hidden',
        },
      }}
      sx={{
        width: tokens.size2xs,
        flexShrink: 0,
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: 'grid',
          gridAutoColumns: '1fr',
          gridTemplateRows: 'auto 1fr auto',
          gridTemplateAreas: `"header"
                              "main"
                              "footer"`,
          height: '100vh',
        }}
      >
        <NavHeader navItemHrefProcessor={navItemPathProcessor} {...otherProps} />
        <NavMain navItemHrefProcessor={navItemPathProcessor} {...otherProps} />
        <NavFooter {...otherProps} />
      </Box>
    </Drawer>
  );
}
