import { Stack, Typography, Link } from '@mui/material';
import ResponsiveBox from './ResponsiveBox';
import { formatToDDMMMYYYY } from '@verifime/utils';
import NoDataFound from './NoDataFound';
import NameValueDisplay from './NameValueDisplay';
import tokens from '@verifime/design-tokens';
import { HTMLAttributeAnchorTarget } from 'react';

export default function WholesaleCertificate({
  dateIssued,
  dateOfExpiry,
  accountantNumber,
  lastUpdatedDate,
  containerProps,
  holder,
}: {
  dateIssued?: string;
  dateOfExpiry: string;
  accountantNumber?: string;
  lastUpdatedDate?: string;
  containerProps?: Omit<Parameters<typeof ResponsiveBox>[0], 'children'>;
  holder?: {
    holderName: string;
    holderLink: {
      href: string;
      target?: HTMLAttributeAnchorTarget;
    };
  };
}) {
  if (!dateOfExpiry) {
    return <NoDataFound />;
  }

  return (
    <Stack gap={tokens.spacingBase}>
      {Object.keys(holder || {}).length > 0 && (
        <Link target={holder.holderLink.target} href={holder.holderLink.href}>
          <Typography variant="body1">{holder.holderName}</Typography>
        </Link>
      )}

      <ResponsiveBox {...containerProps}>
        {dateIssued && (
          <NameValueDisplay
            displayType="horizontal"
            name="Date issued"
            value={formatToDDMMMYYYY(dateIssued)}
          />
        )}
        <NameValueDisplay
          displayType="horizontal"
          name="Expires on"
          value={formatToDDMMMYYYY(dateOfExpiry)}
        />
        {accountantNumber && (
          <NameValueDisplay
            displayType="horizontal"
            name="Accountant number"
            value={accountantNumber}
          />
        )}
        {lastUpdatedDate && (
          <NameValueDisplay
            displayType="horizontal"
            name="Last updated"
            value={formatToDDMMMYYYY(lastUpdatedDate)}
          />
        )}
      </ResponsiveBox>
    </Stack>
  );
}
