import { Head, OriginProps } from 'next/document';
import React from 'react';

export type THeadProps = {
  unsafeHashes?: string[];
} & OriginProps &
  React.HTMLAttributes<HTMLHeadElement>;

export default function HeadWithContentSecurityPolicy({
  unsafeHashes,
  children,
  ...props
}: THeadProps) {
  // enable unsafe-inline so for __NEXT_DATA__ (initProps?) to work.
  //   const cspHeader = `
  //     object-src 'none';
  //     img-src 'self' https://maps.gstatic.com data: blob:;
  //     script-src 'self' 'unsafe-inline' 'unsafe-eval' https://maps.googleapis.com;
  //     base-uri 'none';
  //     form-action 'self';
  // `;

  const unsafeHashesString = unsafeHashes?.length
    ? unsafeHashes.map((hash) => `'${hash}'`).join(' ')
    : '';

  const staticLogoUrl = process.env.NEXT_PUBLIC_STATIC_LOGO_URL || '';

  const cspHeader = `
    object-src 'none';
    img-src 'self' https://maps.gstatic.com ${staticLogoUrl}  data: blob:;
    script-src 'self' 'unsafe-eval' https://js-agent.newrelic.com https://bam.nr-data.net https://bam-cell.nr-data.net
     https://maps.googleapis.com ${unsafeHashesString};
    base-uri 'none';
    form-action 'self';
    worker-src 'self' https://wasm.regulaforensics.com blob:;
`;

  return (
    <Head {...props}>
      <meta httpEquiv="Content-Security-Policy" content={cspHeader} />
      {children}
    </Head>
  );
}
