import { useMobile } from '@verifime/utils';
import Link from 'next/link';
import React from 'react';
import { UrlObject } from 'url';
import { TNavItem } from '../types';
import SideNavLogo from './SideNavLogo';

export type THomeLinkProps = {
  homeNavItem: TNavItem;
  navItemHrefProcessor?: (href: UrlObject) => UrlObject;
};

export default function HomeLink({
  homeNavItem,
  navItemHrefProcessor = (href) => href,
}: THomeLinkProps) {
  const isMobile = useMobile();

  return (
    <Link
      href={
        homeNavItem
          ? navItemHrefProcessor({
              pathname: homeNavItem.path,
            })
          : '#'
      }
    >
      {isMobile ? <SideNavLogo size="small" layout="markOnly" /> : <SideNavLogo />}
    </Link>
  );
}
