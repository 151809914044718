import React from 'react';
import LoginButton from '../common/LoginButton';
import { MainLoginContext } from './MainLoginContextProvider';
import { MainLoginState } from './constants';
import { PasswordlessLoginContext } from '../PasswordlessLoginContextProvider';

export default function MainLoginFooter({
  onSignInAsDifferentUser,
}: {
  onSignInAsDifferentUser?: () => void;
}) {
  const mainLoginContextStore = React.useContext(MainLoginContext);
  const busy = mainLoginContextStore.context.busy;
  const setMainLoginContext = mainLoginContextStore.setContext;
  const setPasswordlessLoginContext = React.useContext(PasswordlessLoginContext).setContext;

  const gotoEnterYourEmail = () => {
    setPasswordlessLoginContext({ email: '', errorToShow: undefined });
    setMainLoginContext({ state: MainLoginState.ENTER_YOUR_EMAIL });
  };

  return (
    <>
      <LoginButton
        variant="text"
        disabled={busy}
        onClick={() => {
          gotoEnterYourEmail();
          onSignInAsDifferentUser?.();
        }}
      >
        Sign in as a different User
      </LoginButton>
    </>
  );
}
