'use client';

import { useEffect } from 'react';
import { useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';

export default function GlobalStyleOverride() {
  const theme = useTheme();
  useEffect(() => {
    const documentStyleElement = document.documentElement.style;
    documentStyleElement.setProperty('--link-color', theme.palette.primary.main);
    documentStyleElement.setProperty(
      '--blockquote-background-color',
      theme.palette.mode === 'dark' ? tokens.colorGrey700 : tokens.colorGrey100,
    );
  });
  return <></>;
}
