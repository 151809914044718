import { Link, LinkProps } from '@mui/material';

export default function PrivacyPolicyLink({ children, ...props }: LinkProps) {
  const childNode = children || 'Privacy Policy';
  return (
    <Link href="https://www.verifime.com/privacy-policy" target="_blank" {...props}>
      {childNode}
    </Link>
  );
}
