class AccessLevel {}

class Address {
  /** addressStatus */
  addressStatus = '';

  /** addressType */
  addressType = '';

  /** city */
  city = '';

  /** countryCode */
  countryCode = '';

  /** postcode */
  postcode = '';

  /** state */
  state = '';

  /** streetName */
  streetName = '';

  /** streetNumber */
  streetNumber = '';

  /** streetType */
  streetType = '';

  /** suburb */
  suburb = '';

  /** unitNumber */
  unitNumber = '';
}

class AssessmentStatus {}

class CertificateOfTitle {
  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** documentType */
  documentType = 'RealEstateSupportDocument';

  /** entityStatus */
  entityStatus = 'Draft';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** supportDocumentSubType */
  supportDocumentSubType = new SupportDocumentSubType();

  /** version */
  version = undefined;
}

class Company {
  /** businessNumber */
  businessNumber = '';

  /** capabilities */
  capabilities = [];

  /** changedByAdmin */
  changedByAdmin = false;

  /** changedByAdminUser */
  changedByAdminUser = '';

  /** changedByCustomer */
  changedByCustomer = false;

  /** companyExtractFileId */
  companyExtractFileId = '';

  /** companyName */
  companyName = '';

  /** companyNumber */
  companyNumber = '';

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** customerName */
  customerName = '';

  /** directors */
  directors = [];

  /** email */
  email = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** listedExchange */
  listedExchange = '';

  /** pepSanction */
  pepSanction = undefined;

  /** phone */
  phone = new Phone();

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();

  /** registeredOffice */
  registeredOffice = new Address();

  /** ticker */
  ticker = '';

  /** ultimateBeneficialOwners */
  ultimateBeneficialOwners = [];

  /** userCustomerRole */
  userCustomerRole = '';

  /** version */
  version = undefined;

  /** wholesaleCertificate */
  wholesaleCertificate = undefined;

  /** wholesaleCertificateHolder */
  wholesaleCertificateHolder = undefined;
}

class CreateCompanyRequest {
  /** businessNumber */
  businessNumber = '';

  /** companyExtractFileId */
  companyExtractFileId = '';

  /** companyName */
  companyName = '';

  /** companyNumber */
  companyNumber = '';

  /** directorIds */
  directorIds = [];

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** listedExchange */
  listedExchange = '';

  /** phone */
  phone = new Phone();

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();

  /** registeredOffice */
  registeredOffice = new Address();

  /** ticker */
  ticker = '';

  /** ultimateBeneficialOwnerIds */
  ultimateBeneficialOwnerIds = [];
}

class CreateOrganisationCodeRequest {
  /** organisationIds */
  organisationIds = [];
}

class CreatePartnershipRequest {
  /** businessNumber */
  businessNumber = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** partnerIds */
  partnerIds = [];

  /** partnershipName */
  partnershipName = '';

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();
}

class CreatePermissionRequest {
  /** customerId */
  customerId = '';

  /** organisationId */
  organisationId = '';
}

class CreatePersonRequest {
  /** addToUser */
  addToUser = false;

  /** entityStatus */
  entityStatus = '';

  /** person */
  person = new Person();
}

class CreateSupportDocumentRequest {
  /** documentType */
  documentType = new SupportDocumentSubType();

  /** fileId */
  fileId = new UUID();
}

class CreateTrustRequest {
  /** address */
  address = new Address();

  /** businessNameOfTrustee */
  businessNameOfTrustee = '';

  /** businessNumber */
  businessNumber = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** phone */
  phone = new Phone();

  /** settlorIds */
  settlorIds = [];

  /** trustName */
  trustName = '';

  /** trustType */
  trustType = '';

  /** trusteeIds */
  trusteeIds = [];

  /** ultimateBeneficialOwnerIds */
  ultimateBeneficialOwnerIds = [];
}

class CreateUserRequest {
  /** email */
  email = '';

  /** organisationId */
  organisationId = '';

  /** preferredName */
  preferredName = '';

  /** sendInvite */
  sendInvite = false;
}

class Customer {
  /** capabilities */
  capabilities = [];

  /** changedByAdmin */
  changedByAdmin = false;

  /** changedByAdminUser */
  changedByAdminUser = '';

  /** changedByCustomer */
  changedByCustomer = false;

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** customerName */
  customerName = '';

  /** email */
  email = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** pepSanction */
  pepSanction = undefined;

  /** userCustomerRole */
  userCustomerRole = '';

  /** version */
  version = undefined;
}

class CustomerAddUserRequest {
  /** username */
  username = '';
}

class CustomerListResponse {
  /** customerList */
  customerList = [];
}

class CustomerReference {
  /** customer */
  customer = new Customer();

  /** id */
  id = '';
}

class CustomerRequest {
  /** Whether to add the Customer to the current user */
  addToUser = false;

  /** The email of the user to add the Customer to. Note: the user specified must already in VerifiMe. */
  addToUserWhoHasEmail = '';

  /** customer */
  customer = new Customer();

  /** The status of the Customer */
  entityStatus = 'InProgress';

  /** The reason for rejecting the Customer */
  reason = '';

  /** Suppress email notification */
  suppressEmailNotification = false;
}

class CustomerResponse {
  /** customer */
  customer = new Customer();

  /** Validation results - caller must examine for any error messages */
  validationResults = [];
}

class DocumentType {}

class DriverLicence {
  /** licenceCardNumber */
  licenceCardNumber = '';

  /** licenceCountryOfIssue */
  licenceCountryOfIssue = '';

  /** licenceExpiry */
  licenceExpiry = '';

  /** licenceName */
  licenceName = '';

  /** licenceNumber */
  licenceNumber = '';

  /** licenceState */
  licenceState = '';

  /** licenceStatus */
  licenceStatus = '';

  /** licenceType */
  licenceType = '';

  /** licenceVersionNumber */
  licenceVersionNumber = '';

  /** status */
  status = '';
}

class EntityType {}

class FileItem {
  /** docType */
  docType = '';

  /** entityId */
  entityId = '';

  /** fileId */
  fileId = '';

  /** filename */
  filename = '';

  /** lastModified */
  lastModified = '';

  /** objectKey */
  objectKey = '';

  /** sizeInBytes */
  sizeInBytes = undefined;
}

class FileItemPageResponse {
  /** itemList */
  itemList = [];
}

class FileUploadRequest {
  /** documentType */
  documentType = '';

  /** entityId */
  entityId = '';

  /** file */
  file = '';

  /** fileType */
  fileType = '';
}

class FileUploadResponse {
  /** The unique ID given to this file. This needs to be added to the data record that is linked to this file otherwise the file will be lost and unlinked to anything */
  fileId = '';

  /** Validation results - caller must examine for any error messages */
  validationResults = [];
}

class IIdentityDocument {
  /** documentType */
  documentType = '';

  /** status */
  status = '';
}

class IdentityDocumentRequest {
  /** driverLicence */
  driverLicence = new DriverLicence();

  /** identityDocument */
  identityDocument = undefined;

  /** identityDocumentType */
  identityDocumentType = '';

  /** medicareCard */
  medicareCard = new MedicareCard();

  /** otherIdentityDocument */
  otherIdentityDocument = new OtherIdentityDocument();

  /** passport */
  passport = new Passport();
}

class IdentityDocumentType {}

class LocalDate {}

class MedicareCard {
  /** medicareCardColour */
  medicareCardColour = '';

  /** medicareCardExpiry */
  medicareCardExpiry = '';

  /** medicareCardFullName */
  medicareCardFullName = '';

  /** medicareCardIndividualRefNumber */
  medicareCardIndividualRefNumber = '';

  /** medicareCardNumber */
  medicareCardNumber = '';

  /** medicareStatus */
  medicareStatus = '';

  /** medicareValidationMethod */
  medicareValidationMethod = '';

  /** status */
  status = '';
}

class MetaAttribute {
  /** accessLevel */
  accessLevel = new AccessLevel();

  /** createOnly */
  createOnly = false;

  /** dataType */
  dataType = '';

  /** description */
  description = '';

  /** label */
  label = '';

  /** name */
  name = '';

  /** readOnly */
  readOnly = false;

  /** readRoles */
  readRoles = [];

  /** ruleList */
  ruleList = [];

  /** writeRoles */
  writeRoles = [];
}

class MetaEntity {
  /** attributeList */
  attributeList = [];

  /** entityType */
  entityType = '';

  /** ruleList */
  ruleList = [];
}

class MetaRule {
  /** metaAttribute */
  metaAttribute = new MetaAttribute();

  /** metaEntity */
  metaEntity = new MetaEntity();

  /** ruleType */
  ruleType = '';
}

class MitigationStatus {}

class MitigationTemplateStatus {}

class OffsetDateTime {}

class OrgStructureSearchResult {
  /** reportingEntityId */
  reportingEntityId = '';

  /** reportingEntityName */
  reportingEntityName = '';

  /** reportingEntityUUID */
  reportingEntityUUID = '';

  /** subEntityId */
  subEntityId = '';

  /** subEntityName */
  subEntityName = '';

  /** subEntityUUID */
  subEntityUUID = '';
}

class OrgSummarySearchResult {
  /** reportingEntityId */
  reportingEntityId = '';

  /** reportingEntityName */
  reportingEntityName = '';

  /** reportingEntityUUID */
  reportingEntityUUID = '';

  /** subEntityId */
  subEntityId = '';

  /** subEntityName */
  subEntityName = '';

  /** subEntityUUID */
  subEntityUUID = '';
}

class Organisation {
  /** address */
  address = new Address();

  /** children */
  children = [];

  /** entityId */
  entityId = '';

  /** id */
  id = '';

  /** organisationName */
  organisationName = '';

  /** organisationStatus */
  organisationStatus = 'Active';

  /** organisationType */
  organisationType = 'Client';

  /** version */
  version = undefined;
}

class OrganisationAddUserRequest {
  /** username */
  username = '';
}

class OrganisationCode {
  /** code */
  code = '';

  /** createdBy */
  createdBy = '';

  /** createdDateTime */
  createdDateTime = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDateTime */
  lastUpdatedDateTime = '';

  /** organisations */
  organisations = [];

  /** status */
  status = 'Active';
}

class OrganisationPageResponse {
  /** The total number of items */
  itemCount = undefined;

  /** The list of organisations returned. */
  itemList = [];

  /** The index of the last item in the list. (must be less than the total number of items) */
  lastIndex = undefined;

  /** The index of the first item in the list. */
  startIndex = undefined;
}

class OrganisationResponse {
  /** organisation */
  organisation = new Organisation();

  /** Validation results - caller must examine for any error messages */
  validationResults = [];
}

class OtherIdentityDocument {
  /** countryOfIssue */
  countryOfIssue = '';

  /** dateOfExpiry */
  dateOfExpiry = '';

  /** dateOfIssue */
  dateOfIssue = '';

  /** documentIdentifier */
  documentIdentifier = '';

  /** documentStatus */
  documentStatus = '';

  /** otherDocumentType */
  otherDocumentType = '';

  /** status */
  status = '';
}

class OtherSupportDocument {
  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** documentType */
  documentType = 'RealEstateSupportDocument';

  /** entityStatus */
  entityStatus = 'Draft';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** supportDocumentSubType */
  supportDocumentSubType = new SupportDocumentSubType();

  /** version */
  version = undefined;
}

class PEPSanction {
  /** commentText */
  commentText = '';

  /** customerId */
  customerId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDatetime */
  lastUpdatedDatetime = '';

  /** pepStatus */
  pepStatus = false;

  /** sanctionStatus */
  sanctionStatus = false;

  /** version */
  version = undefined;
}

class PEPSanctionPageResponse {
  /** itemCount */
  itemCount = undefined;

  /** itemList */
  itemList = [];

  /** lastIndex */
  lastIndex = undefined;

  /** startIndex */
  startIndex = undefined;
}

class PEPSanctionResponse {
  /** pepSanction */
  pepSanction = new PEPSanction();

  /** validationResults */
  validationResults = [];
}

class PageResultRiskAssessmentSummary {
  /** itemCount */
  itemCount = undefined;

  /** itemList */
  itemList = [];

  /** lastIndex */
  lastIndex = undefined;

  /** startIndex */
  startIndex = undefined;
}

class Partnership {
  /** businessNumber */
  businessNumber = '';

  /** capabilities */
  capabilities = [];

  /** changedByAdmin */
  changedByAdmin = false;

  /** changedByAdminUser */
  changedByAdminUser = '';

  /** changedByCustomer */
  changedByCustomer = false;

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** customerName */
  customerName = '';

  /** email */
  email = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** partners */
  partners = [];

  /** partnershipAgreementFileId */
  partnershipAgreementFileId = '';

  /** partnershipName */
  partnershipName = '';

  /** pepSanction */
  pepSanction = undefined;

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();

  /** userCustomerRole */
  userCustomerRole = '';

  /** version */
  version = undefined;

  /** wholesaleCertificate */
  wholesaleCertificate = undefined;

  /** wholesaleCertificateHolder */
  wholesaleCertificateHolder = undefined;
}

class Passport {
  /** passportCountryOfIssue */
  passportCountryOfIssue = '';

  /** passportExpiry */
  passportExpiry = '';

  /** passportName */
  passportName = '';

  /** passportNumber */
  passportNumber = '';

  /** passportStatus */
  passportStatus = '';

  /** passportType */
  passportType = '';

  /** passportValidationMethod */
  passportValidationMethod = '';

  /** status */
  status = '';
}

class Permission {
  /** addedBy */
  addedBy = '';

  /** addedDate */
  addedDate = '';

  /** controllerId */
  controllerId = '';

  /** controllerName */
  controllerName = '';

  /** customerId */
  customerId = '';

  /** id */
  id = '';

  /** organisationId */
  organisationId = '';

  /** permissionStatus */
  permissionStatus = '';

  /** removedBy */
  removedBy = '';

  /** removedDate */
  removedDate = '';

  /** reportingEntityId */
  reportingEntityId = '';

  /** reportingEntityName */
  reportingEntityName = '';

  /** subEntityId */
  subEntityId = '';

  /** subEntityName */
  subEntityName = '';

  /** version */
  version = undefined;
}

class PermissionCustomerSummary {
  /** addedDate */
  addedDate = '';

  /** currentRiskScore */
  currentRiskScore = undefined;

  /** currentRiskTitle */
  currentRiskTitle = '';

  /** customerId */
  customerId = '';

  /** customerName */
  customerName = '';

  /** entityType */
  entityType = '';

  /** lastAssessment */
  lastAssessment = '';

  /** permissionId */
  permissionId = '';

  /** permissionStatus */
  permissionStatus = '';

  /** removedDate */
  removedDate = '';

  /** wholesaleCertificate */
  wholesaleCertificate = '';

  /** yearsSinceRemoved */
  yearsSinceRemoved = undefined;
}

class PermissionCustomerSummaryPageResponse {
  /** itemCount */
  itemCount = undefined;

  /** itemList */
  itemList = [];

  /** lastIndex */
  lastIndex = undefined;

  /** startIndex */
  startIndex = undefined;
}

class PermissionRequestRequest {
  /** The email of the user to whom the permission request is being sent. */
  email = '';

  /** The list of organisation ids for which the permission request is being sent. */
  organisationIdList = [];

  /** The user entity id of the user to whom the permission request is being sent. */
  userEntityId = '';

  /**     The username of the user to whom the permission request is being sent.
    Note: Current implementation cause this to also look like an UUID, but doesn't have to be.
 */
  username = '';
}

class PermissionRequestResponse {
  /** Validation results - caller must examine for any error messages */
  validationResults = [];
}

class PermissionRequestSummary {
  /** completedDateTime */
  completedDateTime = '';

  /** createdDateTime */
  createdDateTime = '';

  /** id */
  id = '';

  /** organisationId */
  organisationId = '';

  /** organisationName */
  organisationName = '';

  /** parentOrganisationId */
  parentOrganisationId = '';

  /** parentOrganisationName */
  parentOrganisationName = '';

  /** permissionId */
  permissionId = '';

  /** userEntityId */
  userEntityId = '';
}

class PermissionResponse {
  /** permission */
  permission = new Permission();

  /** Validation results - caller must examine for any error messages */
  validationResults = [];
}

class Person {
  /** address */
  address = new Address();

  /** capabilities */
  capabilities = [];

  /** changedByAdmin */
  changedByAdmin = false;

  /** changedByAdminUser */
  changedByAdminUser = '';

  /** changedByCustomer */
  changedByCustomer = false;

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** customerName */
  customerName = '';

  /** dateOfBirth */
  dateOfBirth = '';

  /** driverLicence */
  driverLicence = new DriverLicence();

  /** email */
  email = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** familyName */
  familyName = '';

  /** firstName */
  firstName = '';

  /** gender */
  gender = '';

  /** id */
  id = '';

  /** identityDocument */
  identityDocument = new IIdentityDocument();

  /** identityDocumentTypeList */
  identityDocumentTypeList = [];

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** medicareCard */
  medicareCard = new MedicareCard();

  /** middleName */
  middleName = '';

  /** otherIdentityDocument */
  otherIdentityDocument = new OtherIdentityDocument();

  /** passport */
  passport = new Passport();

  /** pepSanction */
  pepSanction = undefined;

  /** phone */
  phone = new Phone();

  /** userCustomerRole */
  userCustomerRole = '';

  /** version */
  version = undefined;

  /** wholesaleCertificate */
  wholesaleCertificate = new WholesaleCertificate();
}

class PersonReference {
  /** customer */
  customer = new Customer();

  /** id */
  id = '';
}

class Phone {
  /** countryCode */
  countryCode = '';

  /** phoneNumber */
  phoneNumber = '';

  /** phoneStatus */
  phoneStatus = '';

  /** phoneType */
  phoneType = '';
}

class PresignRequest {
  /** documentType */
  documentType = '';

  /** entityId */
  entityId = '';

  /** filenameList */
  filenameList = [];
}

class PresignResponse {
  /** presignUrlList */
  presignUrlList = [];
}

class PresignUrl {
  /** entityId */
  entityId = '';

  /** fileId */
  fileId = '';

  /** filename */
  filename = '';

  /** url */
  url = '';
}

class RatesNotice {
  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** documentType */
  documentType = 'RealEstateSupportDocument';

  /** entityStatus */
  entityStatus = 'Draft';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** supportDocumentSubType */
  supportDocumentSubType = new SupportDocumentSubType();

  /** version */
  version = undefined;
}

class ResultScope {}

class ResultType {}

class RiskAssessment {
  /** assessedRisk */
  assessedRisk = new RiskLevel();

  /** createdDatetime */
  createdDatetime = '';

  /** currentRisk */
  currentRisk = new RiskLevel();

  /** customerId */
  customerId = '';

  /** id */
  id = '';

  /** lastUpdatedDatetime */
  lastUpdatedDatetime = '';

  /** Read-only, must not be uploaded as part of a create, or update. Send empty list or null. */
  mitigations = [];

  /** organisationId */
  organisationId = '';

  /** permissionId */
  permissionId = '';

  /** potentialRisk */
  potentialRisk = new RiskLevel();

  /** Unique set of Rules triggered during this assessment. */
  ruleList = [];

  /** status */
  status = '';

  /** version */
  version = undefined;
}

class RiskAssessmentPageResponse {
  /** itemCount */
  itemCount = undefined;

  /** itemList */
  itemList = [];

  /** lastIndex */
  lastIndex = undefined;

  /** startIndex */
  startIndex = undefined;
}

class RiskAssessmentProcessRequest {
  /** clientOrgId */
  clientOrgId = new UUID();

  /** customerId */
  customerId = new UUID();
}

class RiskAssessmentResponse {
  /** riskAssessment */
  riskAssessment = new RiskAssessment();

  /** validationResults */
  validationResults = [];
}

class RiskAssessmentRule {
  /** criteriaList */
  criteriaList = [];

  /** description */
  description = '';

  /** id */
  id = '';

  /** inherentRisk */
  inherentRisk = new RiskLevel();

  /** mitigationList */
  mitigationList = [];

  /** organisationId */
  organisationId = '';

  /** residualRisk */
  residualRisk = new RiskLevel();

  /** status */
  status = '';

  /** title */
  title = '';

  /** version */
  version = undefined;
}

class RiskAssessmentRulePageResponse {
  /** itemCount */
  itemCount = undefined;

  /** itemList */
  itemList = [];

  /** lastIndex */
  lastIndex = undefined;

  /** startIndex */
  startIndex = undefined;
}

class RiskAssessmentRuleResponse {
  /** riskAssessmentRule */
  riskAssessmentRule = new RiskAssessmentRule();

  /** validationResults */
  validationResults = [];
}

class RiskAssessmentRuleTemplateRequest {
  /** ruleId */
  ruleId = '';

  /** templateIdList */
  templateIdList = [];
}

class RiskAssessmentSummary {
  /** assessedRiskLevel */
  assessedRiskLevel = new RiskLevel();

  /** assessmentStatus */
  assessmentStatus = '';

  /** createdDatetime */
  createdDatetime = new OffsetDateTime();

  /** currentRiskLevel */
  currentRiskLevel = new RiskLevel();

  /** customerId */
  customerId = new UUID();

  /** customerName */
  customerName = '';

  /** entityType */
  entityType = '';

  /** lastUpdatedDatetime */
  lastUpdatedDatetime = new OffsetDateTime();

  /** organisationId */
  organisationId = new UUID();

  /** riskAssessmentId */
  riskAssessmentId = new UUID();
}

class RiskLevel {
  /** score */
  score = undefined;

  /** title */
  title = '';
}

class RiskMitigation {
  /** Read-only, must not be uploaded as part of a create, or update. Send empty list or null.  */
  associatedRuleList = [];

  /** commentList */
  commentList = [];

  /** description */
  description = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDatetime */
  lastUpdatedDatetime = '';

  /** mitigationStatus */
  mitigationStatus = '';

  /** ocddFrequencyInMonths */
  ocddFrequencyInMonths = undefined;

  /** organisationId */
  organisationId = '';

  /** rolesRequired */
  rolesRequired = [];

  /** templateId */
  templateId = '';

  /** title */
  title = '';

  /** version */
  version = undefined;
}

class RiskMitigationComment {
  /** CommentText supports simple Html content. This is the only UI updatable field. */
  commentText = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDatetime */
  lastUpdatedDatetime = '';

  /** Set to null to add a new comment, otherwise must be the index value of the comment you want to update. */
  listIndex = undefined;
}

class RiskMitigationResponse {
  /** riskMitigation */
  riskMitigation = new RiskMitigation();

  /** validationResults */
  validationResults = [];
}

class RiskMitigationTemplate {
  /** description */
  description = '';

  /** id */
  id = '';

  /** Zero means no OCDD will be triggered by this Mitigation. How the UI represents this might be a checkbox or something similar. */
  ocddFrequencyInMonths = undefined;

  /** organisationId */
  organisationId = '';

  /** rolesRequired */
  rolesRequired = [];

  /** status */
  status = '';

  /** title */
  title = '';

  /** version */
  version = undefined;
}

class RiskMitigationTemplatePageResponse {
  /** itemCount */
  itemCount = undefined;

  /** itemList */
  itemList = [];

  /** lastIndex */
  lastIndex = undefined;

  /** startIndex */
  startIndex = undefined;
}

class RiskMitigationTemplateResponse {
  /** riskMitigationTemplate */
  riskMitigationTemplate = new RiskMitigationTemplate();

  /** validationResults */
  validationResults = [];
}

class RuleCriteria {
  /** attributeName */
  attributeName = '';

  /** comparison */
  comparison = '';

  /** valueList */
  valueList = [];
}

class RuleStatus {}

class SelfSignUpRequest {
  /** code */
  code = '';

  /** email */
  email = '';
}

class SelfSignUpResponse {
  /** invitationSent */
  invitationSent = false;

  /** trackingReference */
  trackingReference = '';
}

class SignupEmailRequest {
  /** email */
  email = '';

  /** organisationId */
  organisationId = '';

  /** preferredName */
  preferredName = '';
}

class SoleTrader {
  /** businessNumber */
  businessNumber = '';

  /** capabilities */
  capabilities = [];

  /** changedByAdmin */
  changedByAdmin = false;

  /** changedByAdminUser */
  changedByAdminUser = '';

  /** changedByCustomer */
  changedByCustomer = false;

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** customerName */
  customerName = '';

  /** email */
  email = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** individual */
  individual = new PersonReference();

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** pepSanction */
  pepSanction = undefined;

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();

  /** soleTraderName */
  soleTraderName = '';

  /** userCustomerRole */
  userCustomerRole = '';

  /** version */
  version = undefined;

  /** wholesaleCertificate */
  wholesaleCertificate = undefined;

  /** wholesaleCertificateHolder */
  wholesaleCertificateHolder = undefined;
}

class SupportDocument {
  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** documentType */
  documentType = 'RealEstateSupportDocument';

  /** entityStatus */
  entityStatus = 'Draft';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** supportDocumentSubType */
  supportDocumentSubType = new SupportDocumentSubType();

  /** version */
  version = undefined;
}

class SupportDocumentSubType {}

class SupportDocumentSubTypeWithAllowedStatus {
  /** Whether this sub type is allowed to be created */
  allowed = false;

  /** The sub type of a support document. */
  supportDocumentSubType = 'CertificateOfTitle';
}

class Trust {
  /** address */
  address = new Address();

  /** businessNameOfTrustee */
  businessNameOfTrustee = '';

  /** businessNumber */
  businessNumber = '';

  /** capabilities */
  capabilities = [];

  /** changedByAdmin */
  changedByAdmin = false;

  /** changedByAdminUser */
  changedByAdminUser = '';

  /** changedByCustomer */
  changedByCustomer = false;

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** customerName */
  customerName = '';

  /** deedFileId */
  deedFileId = '';

  /** email */
  email = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** pepSanction */
  pepSanction = undefined;

  /** phone */
  phone = new Phone();

  /** settlors */
  settlors = [];

  /** trustName */
  trustName = '';

  /** trustType */
  trustType = '';

  /** trustees */
  trustees = [];

  /** ultimateBeneficialOwners */
  ultimateBeneficialOwners = [];

  /** userCustomerRole */
  userCustomerRole = '';

  /** version */
  version = undefined;

  /** wholesaleCertificate */
  wholesaleCertificate = undefined;

  /** wholesaleCertificateHolder */
  wholesaleCertificateHolder = undefined;
}

class UUID {}

class UpdateCompanyRequest {
  /** businessNumber */
  businessNumber = '';

  /** companyExtractFileId */
  companyExtractFileId = '';

  /** companyName */
  companyName = '';

  /** companyNumber */
  companyNumber = '';

  /** directorIds */
  directorIds = [];

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** listedExchange */
  listedExchange = '';

  /** phone */
  phone = new Phone();

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();

  /** registeredOffice */
  registeredOffice = new Address();

  /** ticker */
  ticker = '';

  /** ultimateBeneficialOwnerIds */
  ultimateBeneficialOwnerIds = [];

  /** version */
  version = undefined;
}

class UpdateEntityStatusRequest {
  /** customerId */
  customerId = '';

  /** entityStatus */
  entityStatus = '';

  /** reason */
  reason = '';
}

class UpdateIdentityDocumentStatusRequest {
  /** identityDocumentStatus */
  identityDocumentStatus = '';

  /** identityDocumentType */
  identityDocumentType = '';

  /** reason */
  reason = '';
}

class UpdatePartnershipRequest {
  /** businessNumber */
  businessNumber = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** partnerIds */
  partnerIds = [];

  /** partnershipName */
  partnershipName = '';

  /** principalPlaceOfBusiness */
  principalPlaceOfBusiness = new Address();

  /** version */
  version = undefined;
}

class UpdatePersonRequest {
  /** entityStatus */
  entityStatus = '';

  /** person */
  person = new Person();
}

class UpdateTrustRequest {
  /** address */
  address = new Address();

  /** businessNameOfTrustee */
  businessNameOfTrustee = '';

  /** businessNumber */
  businessNumber = '';

  /** entityStatus */
  entityStatus = '';

  /** entityType */
  entityType = '';

  /** id */
  id = '';

  /** phone */
  phone = new Phone();

  /** settlorIds */
  settlorIds = [];

  /** trustName */
  trustName = '';

  /** trustType */
  trustType = '';

  /** trusteeIds */
  trusteeIds = [];

  /** ultimateBeneficialOwnerIds */
  ultimateBeneficialOwnerIds = [];

  /** version */
  version = undefined;
}

class UserCredentialSetupStatusUpdateRequest {
  /** withCredentials */
  withCredentials = false;
}

class UserResponse {
  /** roles */
  roles = [];

  /** userName */
  userName = '';
}

class ValidationResult {
  /** User friendly message with parameter values already embedded in the message */
  message = '';

  /** The field name of where the validation message came from or null for a root level message that has no field */
  name = '';

  /** The nested path (including list index position) of where the field is located if it is not a root level field. Or null for root level fields. */
  path = '';

  /** The name of this result - like an error code */
  resultName = '';

  /** Defines if this result is a field level or form level result */
  resultScope = 'Field';

  /** resultType */
  resultType = new ResultType();
}

class ValuationNotice {
  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** documentType */
  documentType = 'RealEstateSupportDocument';

  /** entityStatus */
  entityStatus = 'Draft';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** supportDocumentSubType */
  supportDocumentSubType = new SupportDocumentSubType();

  /** version */
  version = undefined;
}

class WholesaleCertificate {
  /** accountantNumber */
  accountantNumber = '';

  /** certificateStatus */
  certificateStatus = '';

  /** certificateType */
  certificateType = '';

  /** dateIssued */
  dateIssued = '';

  /** dateOfExpiry */
  dateOfExpiry = '';

  /** fileId */
  fileId = '';
}

class WholesaleCertificateCreateRequest {
  /** fileId */
  fileId = '';
}

class WholesaleCertificateStatusRequest {
  /** certificateStatus */
  certificateStatus = '';

  /** reason */
  reason = '';
}

class WholesaleCertificateSupportDocument {
  /** accountantNumber */
  accountantNumber = '';

  /** createdBy */
  createdBy = '';

  /** createdDate */
  createdDate = '';

  /** dateIssued */
  dateIssued = new LocalDate();

  /** dateOfExpiry */
  dateOfExpiry = new LocalDate();

  /** documentType */
  documentType = 'RealEstateSupportDocument';

  /** entityStatus */
  entityStatus = 'Draft';

  /** fileId */
  fileId = '';

  /** id */
  id = '';

  /** lastUpdatedBy */
  lastUpdatedBy = '';

  /** lastUpdatedDate */
  lastUpdatedDate = '';

  /** supportDocumentSubType */
  supportDocumentSubType = new SupportDocumentSubType();

  /** version */
  version = undefined;
}

class WholesaleCertificateUpdateRequest {
  /** accountantNumber */
  accountantNumber = '';

  /** dateIssued */
  dateIssued = '';

  /** dateOfExpiry */
  dateOfExpiry = '';
}

export const person = {
  AccessLevel,
  Address,
  AssessmentStatus,
  CertificateOfTitle,
  Company,
  CreateCompanyRequest,
  CreateOrganisationCodeRequest,
  CreatePartnershipRequest,
  CreatePermissionRequest,
  CreatePersonRequest,
  CreateSupportDocumentRequest,
  CreateTrustRequest,
  CreateUserRequest,
  Customer,
  CustomerAddUserRequest,
  CustomerListResponse,
  CustomerReference,
  CustomerRequest,
  CustomerResponse,
  DocumentType,
  DriverLicence,
  EntityType,
  FileItem,
  FileItemPageResponse,
  FileUploadRequest,
  FileUploadResponse,
  IIdentityDocument,
  IdentityDocumentRequest,
  IdentityDocumentType,
  LocalDate,
  MedicareCard,
  MetaAttribute,
  MetaEntity,
  MetaRule,
  MitigationStatus,
  MitigationTemplateStatus,
  OffsetDateTime,
  OrgStructureSearchResult,
  OrgSummarySearchResult,
  Organisation,
  OrganisationAddUserRequest,
  OrganisationCode,
  OrganisationPageResponse,
  OrganisationResponse,
  OtherIdentityDocument,
  OtherSupportDocument,
  PEPSanction,
  PEPSanctionPageResponse,
  PEPSanctionResponse,
  PageResultRiskAssessmentSummary,
  Partnership,
  Passport,
  Permission,
  PermissionCustomerSummary,
  PermissionCustomerSummaryPageResponse,
  PermissionRequestRequest,
  PermissionRequestResponse,
  PermissionRequestSummary,
  PermissionResponse,
  Person,
  PersonReference,
  Phone,
  PresignRequest,
  PresignResponse,
  PresignUrl,
  RatesNotice,
  ResultScope,
  ResultType,
  RiskAssessment,
  RiskAssessmentPageResponse,
  RiskAssessmentProcessRequest,
  RiskAssessmentResponse,
  RiskAssessmentRule,
  RiskAssessmentRulePageResponse,
  RiskAssessmentRuleResponse,
  RiskAssessmentRuleTemplateRequest,
  RiskAssessmentSummary,
  RiskLevel,
  RiskMitigation,
  RiskMitigationComment,
  RiskMitigationResponse,
  RiskMitigationTemplate,
  RiskMitigationTemplatePageResponse,
  RiskMitigationTemplateResponse,
  RuleCriteria,
  RuleStatus,
  SelfSignUpRequest,
  SelfSignUpResponse,
  SignupEmailRequest,
  SoleTrader,
  SupportDocument,
  SupportDocumentSubType,
  SupportDocumentSubTypeWithAllowedStatus,
  Trust,
  UUID,
  UpdateCompanyRequest,
  UpdateEntityStatusRequest,
  UpdateIdentityDocumentStatusRequest,
  UpdatePartnershipRequest,
  UpdatePersonRequest,
  UpdateTrustRequest,
  UserCredentialSetupStatusUpdateRequest,
  UserResponse,
  ValidationResult,
  ValuationNotice,
  WholesaleCertificate,
  WholesaleCertificateCreateRequest,
  WholesaleCertificateStatusRequest,
  WholesaleCertificateSupportDocument,
  WholesaleCertificateUpdateRequest,
};
