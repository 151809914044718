'use client';

import { useEffect } from 'react';

export function useHideDocumentScrollbar(isOpen: boolean) {
  useEffect(() => {
    if (typeof window != 'undefined' && window.document) {
      if (isOpen) {
        // Disable scrollbar on the document level to avoid showing double scrollbar when content overflows on both the parent and the drawer.
        window.document.documentElement.style.overflow = 'hidden';
      } else {
        // Restore scrollbar on document level
        window.document.documentElement.style.overflow = '';
      }
    }

    return () => {
      if (typeof window != 'undefined' && window.document) {
        // Restore scrollbar on document level
        window.document.documentElement.style.overflow = '';
      }
    };
  }, [isOpen]);
}
