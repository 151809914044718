import { alpha } from '@mui/material';

import * as tokens from '../dist/tokens';

export default tokens;

export type TColorGradation =
  | '050'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

type TObject = Record<string, any>;

/**
 * To reduce generated tokens size,
 * we'd like to generate color (such as branding color) on the fly,
 * instead of generate them in static way
 *
 * @param colorName The name of color such as Eggplant, red, etc.
 * @param colorGradation such as 50, 100, 900, etc.
 * @returns color value, such as #efedff
 */
export function generateColor(colorName: string, colorGradation: TColorGradation): string {
  if (!colorName) {
    throw new Error('Please given a valid color name.');
  }

  const capitalizedColorName = colorName
    .split('')
    .map((char, index) => (index === 0 ? char.toUpperCase() : char))
    .join('');

  const color: string = `color${capitalizedColorName}${colorGradation}`;

  if (!(color in tokens)) {
    throw new Error(
      `Do not support color [${color}], please check out tokens located in [packages/design-tokens] to find proper one.`,
    );
  }
  return (tokens as TObject)[color];
}

/**
 *
 * To generate opacity color
 *
 * @param hexColor hex color
 * @param opacity such as 0.1, 0,9
 * @returns opacityColor
 */
export function generateOpacityColor(hexColor: string, opacity: number) {
  return alpha(hexColor, opacity);
}
