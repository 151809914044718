import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ConfirmOptions } from '.';

const ConfirmationDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}: {
  open: DialogProps['open'];
  options: ConfirmOptions;
  onCancel: () => void;
  onConfirm: (inputboxValue?: string) => void;
  onClose: DialogProps['onClose'];
}) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    confirmationKeyword,
    confirmationKeywordTextFieldProps,
    hideCancelButton,
    buttonOrder,
    inputbox,
    defaultInputboxValue,
    inputboxTextFieldProps,
    inputboxValueRequired,
  } = options;

  const [confirmationKeywordValue, setConfirmationKeywordValue] = useState<string>("");
  const [inputboxValue, setInputboxValue] = useState<string | undefined>(
    inputbox ? defaultInputboxValue : undefined,
  );

  const confirmationButtonDisabled = Boolean(
    (confirmationKeyword && confirmationKeywordValue !== confirmationKeyword) ||
      (inputbox &&  inputboxValueRequired &&
        (inputboxValue ?? defaultInputboxValue).trim().length === 0),
  );

  const inputboxContent = (
    <>
      {inputbox && (
        <TextField
          onChange={(e) => setInputboxValue(e.target.value)}
          defaultValue={defaultInputboxValue}
          fullWidth
          {...inputboxTextFieldProps}
        />
      )}
    </>
  );

  const confirmationContent = (
    <>
      {confirmationKeyword && (
        <TextField
          onChange={(e) => setConfirmationKeywordValue(e.target.value)}
          value={confirmationKeywordValue}
          fullWidth
          {...confirmationKeywordTextFieldProps}
        />
      )}
    </>
  );


  const dialogActions = buttonOrder.map((buttonType) => {
    if (buttonType === 'cancel') {
      const handleConfirmClicked = () => {
        onCancel();
        setInputboxValue(undefined);
      };

      return (
        !hideCancelButton && (
          <Button key="cancel" {...cancellationButtonProps} onClick={handleConfirmClicked}>
            {cancellationText}
          </Button>
        )
      );
    }

    if (buttonType === 'confirm') {
      const handleConfirmClicked = () => {
        onConfirm(inputbox ? inputboxValue : undefined);
        setInputboxValue(undefined);
      };

      return (
        <Button
          key="confirmInput"
          color="primary"
          disabled={confirmationButtonDisabled}
          {...confirmationButtonProps}
          onClick={handleConfirmClicked}
        >
          {confirmationText}
        </Button>
      );
    }

    throw new Error(`Supported button types are only "confirm" and "cancel", got: ${buttonType}`);
  });

  const handleOnClose: DialogProps['onClose'] = (event, reason) => {
    setInputboxValue(undefined);
    onClose(event, reason);
  };

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? handleOnClose : null}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>
          {content}
          {inputboxContent}
        </DialogContent>
      ) : description ? (
        <DialogContent {...contentProps}>
          <DialogContentText>{description}</DialogContentText>
          {inputboxContent}
          {confirmationContent}
        </DialogContent>
      ) : (
        inputbox && <DialogContent {...contentProps}>{inputboxContent}</DialogContent>
      )}
      <DialogActions {...dialogActionsProps}>{dialogActions}</DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
