import PasswordlessLogin from './PasswordlessLogin';

export { type TMainLoginProps as TLoginProps } from './MainLogin';
export { SignInMethodUsed, SignInState } from './constants';
export {
  SetupYourAccount,
  SetupAuthenticatorApp,
  default as InitialCredentialSetup,
  type TInitialCredentialSetupProps,
  type TSetupAuthenticatorAppProps,
} from './InitialCredentialSetup';
export { default as PasskeySettings } from './PasskeySettings';
export { default as OTPSettings } from './OTPSettings';

export default PasswordlessLogin;
