import IconButton from '@mui/material/IconButton';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import React from 'react';

export type TAction = () => Promise<void>;
export type TOperationProps = {
  label: React.ReactNode;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  operation: TAction;
};

export default function AdditionalOperations({ operations }: { operations: TOperationProps[] }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertRoundedIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {operations.map(({ label, buttonProps, operation }, index) => (
          <MenuItem key={index}>
            <Button
              {...buttonProps}
              onClick={() => {
                const operationResult = operation?.();
                if (!!operationResult) {
                  operationResult.finally(handleClose);
                } else {
                  handleClose();
                }
              }}
            >
              {label}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
