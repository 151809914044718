import { enqueueSnackbar } from 'notistack';

const defaultSnackbarProps: any = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  TransitionProps: {
    direction: 'down',
  },
};

export const enqueSuccessNotification = (message: string) => {
  enqueueSnackbar(message, {
    variant: 'successNotification',
    ...defaultSnackbarProps,
  });
};

export const enqueErrorNotification = (message: string) => {
  enqueueSnackbar(message, {
    variant: 'errorNotification',
    ...defaultSnackbarProps,
  });
};
