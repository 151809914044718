/**
 * @description
 */
import * as postAssessment from './postAssessment';
import * as getAssessmentOrganisationByOrgId from './getAssessmentOrganisationByOrgId';
import * as postAssessmentRun from './postAssessmentRun';
import * as postAssessmentRunCheck from './postAssessmentRunCheck';
import * as getAssessmentSummaryOrganisationByOrgId from './getAssessmentSummaryOrganisationByOrgId';
import * as getAssessmentById from './getAssessmentById';
import * as putAssessmentById from './putAssessmentById';
import * as deleteAssessmentById from './deleteAssessmentById';
import * as putAssessmentByRiskAssessmentIdMitigationByRiskMitigationIdStatusByStatus from './putAssessmentByRiskAssessmentIdMitigationByRiskMitigationIdStatusByStatus';

export {
  postAssessment,
  getAssessmentOrganisationByOrgId,
  postAssessmentRun,
  postAssessmentRunCheck,
  getAssessmentSummaryOrganisationByOrgId,
  getAssessmentById,
  putAssessmentById,
  deleteAssessmentById,
  putAssessmentByRiskAssessmentIdMitigationByRiskMitigationIdStatusByStatus,
};
