import { useEffect, useState } from 'react';
import PortraitDisplay, { TPortraitDisplayProps } from './PortraitDisplay';
import tokens from '@verifime/design-tokens';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export type TIdentityDocumentPortraitProps = {
  fetchIdentityDocumentPortrait?: () => Promise<TPortraitDisplayProps>;
};

export default function IdentityDocumentPortrait({
  fetchIdentityDocumentPortrait,
}: Readonly<TIdentityDocumentPortraitProps>) {
  const [portraitProps, setPortraitProps] = useState<TPortraitDisplayProps | undefined>(undefined);

  useEffect(() => {
    fetchIdentityDocumentPortrait?.().then(setPortraitProps);
  }, [fetchIdentityDocumentPortrait]);

  return (
    <Stack gap={tokens.spacingXs} padding={tokens.spacingXs}>
      {portraitProps ? <PortraitDisplay {...portraitProps} /> : <CircularProgress />}
    </Stack>
  );
}
