import { MagicLinkConfirmationPromptMessage } from './common/LoginAlert';
import { LoginMessageContainer } from './common/LoginContainer';
import { LoginStatusMessageDisplay } from './common/LoginStatusMessageDisplay';

export default function SignInLinkRequested() {
  return (
    <>
      <LoginMessageContainer>
        <LoginStatusMessageDisplay>Checking your sign-in status...</LoginStatusMessageDisplay>
      </LoginMessageContainer>
      <MagicLinkConfirmationPromptMessage />
    </>
  );
}
