/**
 * @desc undefined
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** orgId */
  orgId;
  /** assessedRiskLevel */
  assessedRiskLevel;
  /** assessmentStatus */
  assessmentStatus;
  /** currentRiskLevel */
  currentRiskLevel;
  /** customerId */
  customerId;
  /** customerName */
  customerName;
  /** entityType */
  entityType;
  /** fromDateCreated */
  fromDateCreated;
  /** fromLastUpdated */
  fromLastUpdated;
  /** lastIndex */
  lastIndex;
  /** orderBy */
  orderBy;
  /** orderDirection */
  orderDirection;
  /** startIndex */
  startIndex;
  /** toDateCreated */
  toDateCreated;
  /** toLastUpdated */
  toLastUpdated;
}

export const method = 'GET';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey(
      '/v1/risk/assessment/summary/organisation/{orgId}',
      params,
      'GET',
    ),
    newValue,
    shouldRevalidate,
  );
}

export function useRequest(params = {}, swrOptions = {}) {
  return Hooks.useRequest(
    '/v1/risk/assessment/summary/organisation/{orgId}',
    params,
    swrOptions,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl(
    '/v1/risk/assessment/summary/organisation/{orgId}',
    params,
    'GET',
    option,
  );
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'GET',
  });
}
