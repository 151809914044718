import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ColoredIcon } from '../ColoredIconAndChip';
import tokens from '@verifime/design-tokens';

export default function CreateNewidentity({ onAdd }: { onAdd: () => void }) {
  return (
    <Paper
      sx={{
        width: tokens.sizeXs,
        borderRadius: tokens.borderRadiusLg,
        cursor: 'pointer',
      }}
      elevation={4}
      onClick={onAdd}
    >
      <Stack
        gap={tokens.spacingBase}
        padding={`${tokens.spacingXl} ${tokens.spacingBase}`}
        alignItems="center"
      >
        <Typography variant="body2" textAlign="center" color="textSecondary">
          Easily verify an SMSF, trust or company you control, when you set it up as an identity.
        </Typography>
        <Button startIcon={<ColoredIcon iconName="addRounded" />} onClick={onAdd}>
          Setup new identity
        </Button>
      </Stack>
    </Paper>
  );
}
