import React, { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import tokens from '@verifime/design-tokens';
import * as otpauth from 'otpauth';
import { IconLink } from '../../IconLink';
import QRCode from '../../QRCode';
import CopyableText from '../../CopyableText';


export const AuthenticatorQRCode = ({ totp }: { totp: otpauth.TOTP }) => {
  const theme = useTheme();
  const [showQRCode, setShowQRCode] = useState<boolean>(
    useMediaQuery(theme.breakpoints.up('sm')), // mobile default to not show qr code.
  );
  const toggleShowQRCode = () => setShowQRCode(!showQRCode);

  const copyKey = async () => {
    const text = totp.secret.base32;
    if (text && navigator.clipboard) {
      await navigator.clipboard.writeText(text.toString());
    }
  }

  return (
    <>
      {showQRCode ? (
        <>
          <Typography variant="body1" align="left">
            Step2. Scan this QR code using the authenticator app
          </Typography>
          <Stack alignItems="center" gap={tokens.spacingSm}>
            <QRCode
              value={otpauth.URI.stringify(totp)}
              size='medium'
              margin={5}
              errorCorrectionLevel="L"
            />
            <IconLink onClick={toggleShowQRCode}>Can’t scan code? Copy key</IconLink>
          </Stack>
        </>
      ) : (
        <Stack gap={tokens.spacing2xs}>
          <Typography variant="body1" align="left">
            Step2. Copy the key below
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">enter this code into your authenticator app</Typography>
          <CopyableText text={totp.secret.base32} textVariant="h6" tooltipPlacement="bottom" />
          <Stack direction="row" gap={tokens.spacing2xs}>
            <IconLink onClick={toggleShowQRCode}>Show QR code</IconLink>
            •
            <IconLink onClick={copyKey}>Copy key</IconLink>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default AuthenticatorQRCode;
