import { Button, Divider, Stack, Theme, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import * as otpauth from 'otpauth';
import { IconLinkOpenNew } from '../../IconLinkOpenNew';
import { AuthenticatorQRCode } from './AuthenticatorQRCode';
export type TAuthenticatorInfo = {
  displayAddPasskey: boolean;
  totp: otpauth.TOTP;
  onContinue: () => void;
  addPasskey?: () => void;
};

export const AuthenticatorInfo = ({
  displayAddPasskey,
  totp,
  onContinue,
  addPasskey,
}: TAuthenticatorInfo) => {
  const renderDownloadInfo = () => {
    return (
      <Stack gap={tokens.spacingBase}>
        <Stack gap={tokens.spacing2xs}>
          <Typography variant="body1">Step1. Download an authenticator app </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme: Theme) => theme.palette.text.secondary,
            }}
          >
            eg. Google Authenticator, Microsoft Authenticator, Yubico Authenticator etc.
          </Typography>
          <IconLinkOpenNew
            href={
              'https://www.keepersecurity.com/blog/2023/07/20/what-are-authenticator-apps-and-how-do-they-work/'
            }
          >
            Learn more about Authenticator apps
          </IconLinkOpenNew>
        </Stack>
        <Divider />
      </Stack>
    );
  };

  return (
    <>
      {renderDownloadInfo()}
      {totp && (
        <>
          <Stack gap={tokens.spacingSm}>
            <AuthenticatorQRCode totp={totp} />
          </Stack>
          <Button variant="contained" onClick={onContinue}>
            Continue
          </Button>
        </>
      )}
      {displayAddPasskey && <Button onClick={addPasskey}>Use a Passkey instead</Button>}
    </>
  );
};

export default AuthenticatorInfo;
