import {
  Typography,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { TFileUploadStatus, UploadStatus } from '.';
import tokens from '@verifime/design-tokens';

export type TListFileItemProps = {
  fileUploadStatus: TFileUploadStatus;
  onDeleteFile?: (fileUploadStatus: TFileUploadStatus) => void;
  isReadonly?: boolean;
};
export const ListFileItem = (props: TListFileItemProps) => {
  const {
    fileUploadStatus: { uploadStatus, fileName, uploadInfo, error },
    onDeleteFile,
    isReadonly,
  } = props;

  return (
    <ListItem sx={{ pt: tokens.spacingBase }}>
      <ListItemIcon>
        <UploadFileOutlinedIcon
          color={uploadStatus === UploadStatus.FAILED ? 'error' : 'primary'}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Box>
            <Typography
              variant="body1"
              color={uploadStatus === UploadStatus.FAILED ? 'error' : 'textPrimary'}
            >
              {fileName}
            </Typography>
            <Typography
              variant="subtitle1"
              color={uploadStatus === UploadStatus.FAILED ? 'error' : 'textSecondary'}
            >
              {uploadInfo}
            </Typography>
            {uploadStatus !== UploadStatus.COMPLETE && (
              <LinearProgress
                variant="indeterminate"
                color={uploadStatus === UploadStatus.FAILED ? 'error' : 'primary'}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    animationDuration: uploadStatus === UploadStatus.FAILED && '0s',
                  },
                }}
              />
            )}
          </Box>
        }
        secondary={
          error && (
            <Typography variant="body1" color={'error'}>
              {props.fileUploadStatus.error}
            </Typography>
          )
        }
      />
      {(uploadStatus === UploadStatus.COMPLETE || uploadStatus === UploadStatus.FAILED) &&
        typeof onDeleteFile === 'function' && (
          <IconButton
            sx={{ ml: tokens.spacingBase }}
            onClick={() => onDeleteFile(props.fileUploadStatus)}
            disabled={isReadonly}
          >
            <ClearIcon />
          </IconButton>
        )}
    </ListItem>
  );
};
