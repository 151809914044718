/**
 * @desc undefined
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {}

export const method = 'POST';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/trust/{id}/deed', params, 'POST'),
    newValue,
    shouldRevalidate,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl('/v1/trust/{id}/deed', params, 'POST', option);
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'POST',
  });
}
