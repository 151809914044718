'use client';

import React from 'react';
import { TPasswordlessLoginContext } from './types';
import { TContextStore, createContextStore } from './util';

export const PasswordlessLoginContext =
  React.createContext<TContextStore<TPasswordlessLoginContext>>(undefined);

export default function PasswordlessLoginContextProvider({
  children,
  lastSignedInEmail,
}: {
  children: React.ReactNode;
  lastSignedInEmail: string;
}) {
  const passwordlessLoginContextStore = createContextStore<TPasswordlessLoginContext>({
    email: lastSignedInEmail,
  });

  // default to lastSignedInEmail if we haven't initialised main login email yet,
  // as we don't want to overwrite user's input with last signed in email,
  // but it is fine to use as a default value.
  // Also lastSignedInEmail is '' initially, and as it is only set after we have read
  // from local storage.
  React.useEffect(() => {
    if (!passwordlessLoginContextStore.context.email) {
      passwordlessLoginContextStore.setContext({ email: lastSignedInEmail });
    }
  }, [lastSignedInEmail]);

  return (
    <PasswordlessLoginContext.Provider value={passwordlessLoginContextStore}>
      {children}
    </PasswordlessLoginContext.Provider>
  );
}
