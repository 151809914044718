import { SUPPORTED_COUNTRY_MAP } from './constants';
import { TAddress, TCountryCode } from './types';

export const ADDRESS_PREFIX_SEPARATOR = 'Sepa';

// Ideally, this method should cover all of address format,
// let's start with a simple one.
// TODO: to support all countries address format
export const getAddressString = (address: TAddress) => {
  if (!address) {
    return '';
  }

  if (Object.keys(address).length < 1) {
    return '';
  }

  const street = `${address.streetNumber ?? ''} ${address.streetName ?? ''} ${
    address.streetType ?? ''
  }`;
  return `${address.unitNumber ? address.unitNumber + '/' : ''} ${street ? street + ',' : ''} ${
    address.suburb ? address.suburb + ',' : ''
  } ${address.state ?? ''} ${address.postcode ?? ''}, ${
    SUPPORTED_COUNTRY_MAP[address.countryCode as TCountryCode].label
  }`;
};
