import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Box, Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';

export default function ClientLogo({
  url,
  organisationName,
}: Readonly<{
  url: string;
  organisationName: string;
}>) {
  if (!url && !organisationName) {
    return;
  }

  return (
    <Paper sx={{ width: '100%', height: '100%', minHeight: tokens.size3xs }}>
      <Stack padding={tokens.spacingBase} gap={tokens.spacingXs} height="100%">
        <Typography variant="subtitle2">Verification requested by</Typography>
        <Stack flexDirection="row" alignItems="center" gap={tokens.spacingBase} height="100%">
          <Box
            sx={{
              height: tokens.spacing5xl,
              overflow: 'hidden',
              borderRadius: '20%',
              borderWidth: tokens.spacing4xs,
              borderColor: (theme) => theme.palette.action.disabledBackground,
              borderStyle: 'solid',
              flex: 1,
            }}
          >
            {url ? (
              <img
                style={{ objectFit: 'cover' }}
                src={url}
                width="100%"
                height="100%"
                alt={organisationName}
              />
            ) : (
              <BusinessRoundedIcon sx={{ width: '100%', height: '100%' }} />
            )}
          </Box>
          <Typography variant="h5" sx={{ flex: 2 }}>
            {organisationName}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
