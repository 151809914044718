/**
 * To put rest kinds of utils
 */

import { TOption } from './types';

export const enumToOptions = (sourceEnum: any): TOption[] => {
  return Object.entries(sourceEnum).map(
    ([k, v]) =>
      ({
        code: k,
        label: v,
      } as TOption),
  );
};

export const isServer = typeof window === 'undefined';
export const isClient = typeof window !== 'undefined';
