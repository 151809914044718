import { TColorName } from '../ColoredIconAndChip';
import { TRiskLevels } from './types';

export const RISK_LEVEL_CHIP_COLOR_MAPPING: {
  [riskLevel in TRiskLevels]: TColorName;
} = {
  Pending: 'verificationPending',
  Low: 'successMain',
  Medium: 'warningDark',
  High: 'errorMain',
  Extreme: 'extreme',
};
