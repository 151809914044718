import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { stringUtils } from '@verifime/utils';
import { ColoredIcon } from '../ColoredIconAndChip';
import DocumentNotFound from '../DocumentNotFound';
import StepsWizard, { TStepContent, TStepNumber, TSteps } from '../StepsWizard';
import ImageThumbnail from './ImageThumbnail';
import PdfThumbnail from './PdfThumbnail';
import { TDocumentUrl } from './types';

const SUPPORTED_IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg'];
const SUPPORTED_PDF_EXTENSION = ['pdf'];

const StepContent = ({
  onBack,
  onNext,
  step,
  numSteps,
  url,
  filename,
  sx,
}: TDocumentUrl & TStepContent & { sx?: SxProps }) => {
  const extension = stringUtils.getFileExtensionFromUrl(filename);
  let content = (
    <Box style={{ marginBottom: tokens.spacing2xl }}>
      <DocumentNotFound />
    </Box>
  );
  if (SUPPORTED_IMAGE_EXTENSIONS.includes(extension)) {
    content = <ImageThumbnail url={url} filename={filename} />;
  }
  if (SUPPORTED_PDF_EXTENSION.includes(extension)) {
    content = <PdfThumbnail url={url} filename={filename} />;
  }

  return (
    <Stack gap={tokens.spacingBase} direction="column" sx={sx}>
      {content}
      {getFooter(step, numSteps, onBack, onNext)}
    </Stack>
  );
};

export default function DisplayDocumentsThumbnail({
  documentUrls = [],
  sx,
}: Readonly<{ documentUrls: TDocumentUrl[]; sx?: SxProps }>) {
  if (documentUrls.length < 1) {
    return <DocumentNotFound />;
  }

  const steps: TSteps = documentUrls.map(({ url, filename }) => {
    return {
      content: (props: TStepContent) => (
        <StepContent key={url} {...props} url={url} filename={filename} sx={sx} />
      ),
    };
  });

  return <StepsWizard steps={steps} isShowStepConnector={false} />;
}

function getFooter(step: TStepNumber, numSteps: number, onBack: () => void, onNext: () => void) {
  if (step === 'both') {
    return <></>;
  }
  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Button
        onClick={onBack}
        startIcon={<ColoredIcon iconName="arrowLeftRounded" />}
        size="small"
        disabled={step === 'first'}
      >
        Back
      </Button>
      <Typography variant="body2">{`${
        getCurrentStepNum(step, numSteps) + 1
      }/${numSteps}`}</Typography>
      <Button
        endIcon={<ColoredIcon iconName="arrowRightRounded" />}
        onClick={onNext}
        size="small"
        disabled={step === 'last'}
      >
        Next
      </Button>
    </Stack>
  );
}

function getCurrentStepNum(step: TStepNumber, numSteps: number) {
  if (step === 'first' || step === 'both') {
    return 0;
  }
  if (step == 'last') {
    return numSteps - 1;
  }
  return step;
}
