import React from 'react';
import * as qrcode from 'qrcode';
import { convertRemToPixels } from '@verifime/styles';
import tokens from '@verifime/design-tokens';

type TQRCodeSize = 'small' | 'medium' | 'large';

type TQRCodeImageProps = {
  width: number;
};

export type TQRProps = {
  value: string;
  size: TQRCodeSize;
} & Omit<qrcode.QRCodeToDataURLOptionsJpegWebp, 'type'>;

const SIZE_MAPPINGS = {
  small: tokens.size3xs,
  medium: tokens.size2xs,
  large: tokens.sizeXs,
}

const QRCodeImage = React.forwardRef<HTMLImageElement, TQRCodeImageProps>((props, ref) => {
  return <img ref={ref} width={props.width} height={props.width} />;
});

export default function QRCode({ ...props }: TQRProps) {
  const imgRef = React.useRef<HTMLImageElement>();

  React.useEffect(() => {
    if (props.value) {
      qrcode
        .toDataURL(props.value, {
          type: 'image/jpeg',
          width: convertRemToPixels(SIZE_MAPPINGS[props.size]),
          ...props,
        })
        .then((dataUrl) => {
          imgRef.current.src = dataUrl;
        });
    }
  }, [props]);

  return <QRCodeImage ref={imgRef} width={props.width} />;
}
