import Box from '@mui/material/Box';
import {
  OrganisationSwitcher,
  SideNav,
  TBasicSideNavProps,
  TOrganisationSwitcherProps,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';

export default function ClientPortalSideNav(
  props: Omit<TBasicSideNavProps, 'toggleColorMode'> & TOrganisationSwitcherProps,
) {
  const additionalNavHeaderComponents = () => (
    <>
      {props.potentialOrgs?.length > 0 && (
        <Box paddingX={tokens.spacingXs} paddingY={tokens.spacingBase} maxWidth={tokens.size2xs}>
          <OrganisationSwitcher {...props} />
        </Box>
      )}
    </>
  );

  return <SideNav additionalNavHeaderComponents={additionalNavHeaderComponents} {...props} />;
}
