/**
 * @desc undefined
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** id */
  id;
  /** withPepSanction */
  withPepSanction;
}

export const method = 'GET';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/customer/{id}', params, 'GET'),
    newValue,
    shouldRevalidate,
  );
}

export function useRequest(params = {}, swrOptions = {}) {
  return Hooks.useRequest('/v1/customer/{id}', params, swrOptions);
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl('/v1/customer/{id}', params, 'GET', option);
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'GET',
  });
}
