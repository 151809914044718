import { EntityStatus, UserCustomerRole } from './constants';
import { TCustomer } from './types';

export function hasControlRole(customer: TCustomer) {
  return customer?.userCustomerRole === UserCustomerRole.Controller;
}

/**
 * Filter out identities that are not eligible to grant permissions. Only Draft identities are not eligible. Yes, even
 * rejected identities can grant permissions, however they are not executed until the identity is fully verified.
 *
 * @param identities list of identities
 * @returns list of identities that are eligible to grant permissions
 */
export function filterToPermissionEligibleIdentities(identities: TCustomer[]) {
  return identities?.filter((identity) => identity.entityStatus !== EntityStatus.Draft);
}
