import { format, formatDistance } from 'date-fns';

export default function DateDisplay({
  dateStr,
  dateFormat = 'dd-MMM-yyyy',
  isShowDistance = false,
}: {
  dateStr: string;
  dateFormat?: string;
  isShowDistance?: boolean;
}) {
  const distance = isShowDistance ? generateDistance(dateStr) : '';
  return (
    <>
      {format(Date.parse(dateStr), dateFormat)}
      {distance}
    </>
  );
}

function generateDistance(dateStr: string) {
  const distance = formatDistance(Date.parse(dateStr), new Date(), {
    addSuffix: true,
  });
  return ' (' + distance.replace('about ', '') + ')';
}
