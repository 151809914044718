import { useEffect, useState } from 'react';
import { TUserResponse } from '@verifime/utils';

export default function useAPIUserMe() {
  const [data, setData] = useState<TUserResponse | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const getMe = async() => {
      setLoading(true);
      try {
        const res = await API.person.userResource.getMe.request();
        setData(res);
      } catch(e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    getMe();
  }, []); 

  return {
    currentUserRoles: data?.roles,
    currentUsername: data?.userName,
    currentUserIsAdmin: data?.roles?.includes('admin'),
    isLoadingUser: isLoading,
    loadUserError: error,
  };
}