/**
     * @desc Create of a Customer by an Admin
The admin can create all types of customer, but must supply the email address of an existing user.
At the moment we do not support Admins creating customers for users that don't exist. The admin
can also choose to send an entityStatus change at the same time, or they can choose to update
just the entity status at a later time using the updateEntityStatus() method below.

     */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {}

export const method = 'POST';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/customer/byAdmin', params, 'POST'),
    newValue,
    shouldRevalidate,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl('/v1/customer/byAdmin', params, 'POST', option);
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'POST',
  });
}
