import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { Control, Controller, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import { TFromFieldInfo } from '@verifime/components';

const consentFieldAndRule: TFromFieldInfo = {
  label: '',
  fieldName: 'consent',
  rules: yup.bool().oneOf([true], 'Required'),
};
export const consentSchema = consentFieldAndRule.rules;

export default function Consent({
  control,
  errors,
  label,
}: {
  control: Control<FieldValues, any>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  label: string;
}) {
  const theme = useTheme();

  return (
    <FormControlLabel
      sx={{
        opacity: '0.8',
        color: errors[consentFieldAndRule.fieldName] ? theme.palette.error.main : '',
      }}
      control={
        <Controller
          defaultValue={false}
          name={consentFieldAndRule.fieldName}
          control={control}
          render={({ field }) => (
            <Checkbox
              sx={{
                color: errors[consentFieldAndRule.fieldName] ? theme.palette.error.main : '',
              }}
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      }
      label={label}
      data-cy="checkbox-consent"
    />
  );
}
