/**
 * @description
 */
import * as postV1Person from './postV1Person';
import * as getV1PersonById from './getV1PersonById';
import * as putV1PersonById from './putV1PersonById';
import * as putV1PersonByIdIdentityDocument from './putV1PersonByIdIdentityDocument';
import * as getV1PersonByIdVerificationDocuments from './getV1PersonByIdVerificationDocuments';
import * as putV1PersonByIdVerificationDocuments from './putV1PersonByIdVerificationDocuments';
import * as putV1PersonByIdWholesaleCertificate from './putV1PersonByIdWholesaleCertificate';
import * as postV1PersonByIdWholesaleCertificate from './postV1PersonByIdWholesaleCertificate';
import * as putV1PersonByIdWholesaleCertificateCertificateStatus from './putV1PersonByIdWholesaleCertificateCertificateStatus';
import * as postV2Person from './postV2Person';
import * as putV2PersonById from './putV2PersonById';

export {
  postV1Person,
  getV1PersonById,
  putV1PersonById,
  putV1PersonByIdIdentityDocument,
  getV1PersonByIdVerificationDocuments,
  putV1PersonByIdVerificationDocuments,
  putV1PersonByIdWholesaleCertificate,
  postV1PersonByIdWholesaleCertificate,
  putV1PersonByIdWholesaleCertificateCertificateStatus,
  postV2Person,
  putV2PersonById,
};
