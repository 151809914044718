/**
 * @description
 */
import * as postTemplate from './postTemplate';
import * as getTemplateOrganisationByOrgId from './getTemplateOrganisationByOrgId';
import * as getTemplateById from './getTemplateById';
import * as putTemplateById from './putTemplateById';
import * as deleteTemplateById from './deleteTemplateById';
import * as deleteTemplateByIdCheck from './deleteTemplateByIdCheck';
import * as putTemplateByIdStatusByStatus from './putTemplateByIdStatusByStatus';

export {
  postTemplate,
  getTemplateOrganisationByOrgId,
  getTemplateById,
  putTemplateById,
  deleteTemplateById,
  deleteTemplateByIdCheck,
  putTemplateByIdStatusByStatus,
};
