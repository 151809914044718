import { TableRow, styled } from '@mui/material';

export default styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
