/**
 * @description
 */
import * as getCustomerByEntityId from './getCustomerByEntityId';
import * as postCustomerByEntityId from './postCustomerByEntityId';
import * as getCustomerByEntityIdSub_types from './getCustomerByEntityIdSub_types';
import * as getBySupportDocumentId from './getBySupportDocumentId';
import * as putBySupportDocumentId from './putBySupportDocumentId';
import * as deleteBySupportDocumentId from './deleteBySupportDocumentId';

export {
  getCustomerByEntityId,
  postCustomerByEntityId,
  getCustomerByEntityIdSub_types,
  getBySupportDocumentId,
  putBySupportDocumentId,
  deleteBySupportDocumentId,
};
