/**
     * @desc Find all organisations
Find all organisations based on the query parameters. The query parameters are used to filter, sort and paginate the results.
     */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** The index of the last result to return */
  lastIndex;
  /** The level of the organisation to search for */
  level;
  /** The field to order by */
  orderBy;
  /** The direction to order by */
  orderDirection;
  /** The index of the first result to return */
  startIndex;
}

export const method = 'GET';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/organisation', params, 'GET'),
    newValue,
    shouldRevalidate,
  );
}

export function useRequest(params = {}, swrOptions = {}) {
  return Hooks.useRequest('/v1/organisation', params, swrOptions);
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl('/v1/organisation', params, 'GET', option);
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'GET',
  });
}
