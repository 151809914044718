import React from 'react';
import Typography from '@mui/material/Typography';
import QRCodeIcon from '@mui/icons-material/QrCodeRounded';
import tokens from '@verifime/design-tokens';

import { ErrorMessage } from '../common/LoginAlert';
import LoginButton from '../common/LoginButton';
import { LoginCenterContainer } from '../common/LoginContainer';
import { SignInMethodUsed } from '../constants';
import MainLoginFooter from './MainLoginFooter';
import { PasswordlessLoginContext } from '../PasswordlessLoginContextProvider';
import { MainLoginContext } from './MainLoginContextProvider';
import PasskeyRoundedIcon from '../images/passkeyRounded.svg';
import SignInWithMagicLink from './SignInWithMagicLink';

function LoginMethodButton({
  busy,
  signInMethod,
  showInProgress,
  onClick,
}: {
  busy: boolean;
  signInMethod: SignInMethodUsed;
  showInProgress: boolean;
  onClick: () => void;
}) {

  const signInMethodButtonProp: {
    [key in SignInMethodUsed]: {
      icon: React.ReactNode;
      label: string;
    };
  } = {
    [SignInMethodUsed.FIDO]: {
      icon: <PasskeyRoundedIcon color="inherit" />,
      label: 'Login with a Passkey',
    },
    [SignInMethodUsed.MAGIC_LINK]: {
      icon: <QRCodeIcon color="inherit" />,
      label: 'Login using Authenticator',
    },
  };

  return (<LoginButton
    variant='outlined'
    disabled={busy}
    isShowProgress={showInProgress}
    startIcon={signInMethodButtonProp[signInMethod].icon}
    onClick={onClick}
    sx={{
      px: tokens.spacingNone,
    }}
  >
    {signInMethodButtonProp[signInMethod].label}
  </LoginButton>)
}

export default function LoginMethodSelection({
  signingInWithFido,
  signingInWithMagicLink,
  onSignInAsDifferentUser,
}: {
  signingInWithFido: () => void;
  signingInWithMagicLink: (totp: string) => void;
  onSignInAsDifferentUser?: () => void;
}) {
  const { context: { busy } } = React.useContext(MainLoginContext);
  const {
    setContext: setPasswordlessLoginContext,
    context: { email, errorToShow },
  } = React.useContext(PasswordlessLoginContext);
  const [loginMethodSelected, setLoginMethodSelected] = React.useState<SignInMethodUsed>();

  React.useEffect(() => {
    switch (loginMethodSelected) {
      case undefined:
        break;
      case SignInMethodUsed.FIDO:
        signingInWithFido();
        break;
      case SignInMethodUsed.MAGIC_LINK:
        setPasswordlessLoginContext({ errorToShow: undefined });
        break;
      default:
        throw Error("The type of SignInMethodUsed is not implemented.");
    }
  }, [loginMethodSelected]);

  return (
    <LoginCenterContainer>
      {errorToShow && <ErrorMessage error={errorToShow} />}
      <Typography variant="body1">{email}</Typography>
      {loginMethodSelected !== SignInMethodUsed.MAGIC_LINK ?
        <LoginMethodButton
          busy={busy}
          showInProgress={false}
          signInMethod={SignInMethodUsed.MAGIC_LINK}
          onClick={() => setLoginMethodSelected(SignInMethodUsed.MAGIC_LINK)}
        /> :
        <SignInWithMagicLink signingInWithMagicLink={signingInWithMagicLink} />}
      <Typography variant="body1">OR</Typography>
      <LoginMethodButton
        busy={busy}
        showInProgress={loginMethodSelected === SignInMethodUsed.FIDO && busy}
        signInMethod={SignInMethodUsed.FIDO}
        onClick={() => setLoginMethodSelected(SignInMethodUsed.FIDO)}
      />
      <MainLoginFooter
        onSignInAsDifferentUser={onSignInAsDifferentUser}
      />
    </LoginCenterContainer>
  );
}
