import { TAddress, TTrust } from '@verifime/utils';
import DisplayAddress from './DisplayAddress';
import InformationDisplay, { TInformationDisplayRows } from './InformationDisplay';

const smsfInformationRows: TInformationDisplayRows<keyof TTrust> = {
  row1: [{ label: 'SMSF Name', fieldName: 'trustName' }],
  row2: [{ label: 'Australian Business Number (ABN)', fieldName: 'businessNumber' }],
  row3: [
    {
      label: 'Address',
      fieldName: 'address',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

export default function SmsfContact({ identity }: { identity: TTrust }) {
  return (
    <InformationDisplay<TTrust> informationDisplaysRows={smsfInformationRows} data={identity} />
  );
}
