import {
  TSupportDocument,
  TSupportDocumentSubType,
} from '@verifime/api-definition';

//apiSchemas.SupportDocumentSubType.Enum can be used for the following once VME-2124 is resolved
enum SupportDocumentSubType {
  WholesaleCertificate = "WholesaleCertificate",
  CertificateOfTitle = "CertificateOfTitle",
  ValuationNotice = "ValuationNotice",
  RatesNotice = "RatesNotice",
  NationalVendorDeclarationForLivestock = "NationalVendorDeclarationForLivestock",
  Other = "Other",
}

export const SupportDocumentTypeOptions:
  { [subtype in TSupportDocumentSubType]: { type: string; description: string } } = {
  [SupportDocumentSubType.WholesaleCertificate]: {
    type: "Wholesale Certificate",
    description: "Invest in financial products as a certified wholesale investor",
  },
  [SupportDocumentSubType.CertificateOfTitle]: {
    type: "Certificate of Title",
    description: "Prove ownership of your property",
  },
  [SupportDocumentSubType.ValuationNotice]: {
    type: "Valuation Notice",
    description: "Prove ownership of your property",
  },
  [SupportDocumentSubType.RatesNotice]: {
    type: "Rates Notice",
    description: "Prove ownership of your property",
  },
  [SupportDocumentSubType.NationalVendorDeclarationForLivestock]: {
    type: "National Vendor Declaration for Livestock",
    description: "",
  },
  [SupportDocumentSubType.Other]: {
    type: "Other Documents",
    description: "",
  },
};

export const IndustryDetails: Record<string, { title: string; description: string }> = {
  ["WholesaleCertificate"]: {
    title: "Investment",
    description: "Share documents like wholesale investor certificates and more securely with your fund manager.",
  },
  ["RealEstateSupportDocument"]: {
    title: "Real Estate",
    description: "Share documents like property titles, and more securely with your provider.",
  },
  ["OtherSupportDocument"]: {
    title: "Other Documents",
    description: "",
  },
}

export function toDocumentType(supportDocumentSubType: TSupportDocumentSubType) {
  switch (supportDocumentSubType) {
    case SupportDocumentSubType.CertificateOfTitle:
    case SupportDocumentSubType.RatesNotice:
    case SupportDocumentSubType.ValuationNotice:
    case SupportDocumentSubType.NationalVendorDeclarationForLivestock:
      return "RealEstateSupportDocument";
    case SupportDocumentSubType.WholesaleCertificate:
      return "WholesaleCertificate";
    case SupportDocumentSubType.Other:
      return "OtherSupportDocument";
    default:
      throw Error("Unsupported document sub type");
  }
}

interface GroupedDocuments {
  [documentType: string]: {
    [supportDocumentSubType: string]: TSupportDocument[];
  };
}

function isSupportDocumentDataValid(data: TSupportDocument): boolean {
  if(!data.status || !data.supportDocumentSubType) {
    return false;
  }

  if(data.status === "Verified") {
    const attributeMapping = getSupportDocumentAttributeMapping(data.supportDocumentSubType);
  
    return Object.entries(attributeMapping).every(([key, value]) => {
      if (value.optional) {
        return true;
      }
      return data[key] !== undefined;
    });
  }

  return true;
}

export function groupByDocumentTypeAndSubType(data: TSupportDocument[]) {
  return data.reduce((acc: GroupedDocuments, item: TSupportDocument) => {
    if (isSupportDocumentDataValid(item)) {
      let { documentType, supportDocumentSubType } = item;
      supportDocumentSubType = supportDocumentSubType as TSupportDocumentSubType;

      if (!documentType) {
        documentType = toDocumentType(supportDocumentSubType);
      }

      if (!acc[documentType]) {
        acc[documentType] = {};
      }

      if (!acc[documentType][supportDocumentSubType]) {
        acc[documentType][supportDocumentSubType] = [];
      }

      acc[documentType][supportDocumentSubType].push(item);
    }
    return acc;
  }, {} as GroupedDocuments);
}

enum FormatType {
  Date = 'Date',
  Array = 'Array',
  Money = 'Money',
  TextArea = 'TextArea',
}

export interface AttributeMapping {
  label: string;
  formatType?: FormatType;
  optional?: boolean;
}

export const wholesaleCertificateAttributesMapping: { [key: string]: AttributeMapping } = {
  dateIssued: { label: "Date of Issue", formatType: FormatType.Date },
  dateOfExpiry: { label: "Date of Expiry", formatType: FormatType.Date },
  accountantNumber: { label: "Accountant Number" },
};

export const certificateOfTitleAttributesMapping: { [key: string]: AttributeMapping } = {
  dateOfIssue: { label: "Date of Issue", formatType: FormatType.Date },
  propertyOwners: { label: "Property Owner(s)", formatType: FormatType.Array },
  volume: { label: "Volume" },
  folio: { label: "Folio" },
  descriptionOfLand: { label: "Description of Land", formatType: FormatType.TextArea },
};

export const valuationNoticeAttributeMapping: { [key: string]: AttributeMapping } = {
  landValueAsAt: { label: "Land value as at", formatType: FormatType.Date },
  landValue: { label: "Land value", formatType: FormatType.Money },
  ownersOrLessees: { label: "Owner(s) or Lessee(s)", formatType: FormatType.Array },
  addressOfProperty: { label: "Address of Property" },
  descriptionOfLand: { label: "Description of Land", formatType: FormatType.TextArea },
};

export const ratesNoticeAttributeMapping: { [key: string]: AttributeMapping } = {
  dateOfIssue: { label: "Date of Issue", formatType: FormatType.Date },
  addressOfProperty: { label: "Address of Property" },
  propertyOwners: { label: "Property Owner(s)", formatType: FormatType.Array },
  valuationDate: { label: "Valuation Date", formatType: FormatType.Date, optional: true },
  valuation: { label: "Valuation", formatType: FormatType.Money, optional: true },
  descriptionOfLand: { label: "Description of Land", formatType: FormatType.TextArea },
};

export const nationalVendorDeclarationForLivestockAttributeMapping: { [key: string]: AttributeMapping } = {
  declarationDate: { label: "Declaration Date", formatType: FormatType.Date },
  propertyIdentificationCode: { label: "Property Identification Code" },
};

export const otherSupportDocumentAttributeMapping: { [key: string]: AttributeMapping } = {  
  otherSupportDocumentType: { label: "Document Type" },
  comment: { label: "Comment" }
};

function createInitialData(attributeMapping: { [key: string]: AttributeMapping }): { [key: string]: any } {
  const initialData: { [key: string]: any } = {};
  Object.keys(attributeMapping).forEach(key => {
    initialData[key] = null;
  });
  return initialData;
}

export function getSupportDocumentAttributeMapping(subType: string): { [key: string]: AttributeMapping } {
  switch (subType) {
    case SupportDocumentSubType.CertificateOfTitle:
      return certificateOfTitleAttributesMapping;
    case SupportDocumentSubType.ValuationNotice:
      return valuationNoticeAttributeMapping;
    case SupportDocumentSubType.RatesNotice:
      return ratesNoticeAttributeMapping;
    case SupportDocumentSubType.NationalVendorDeclarationForLivestock:
      return nationalVendorDeclarationForLivestockAttributeMapping;
    case SupportDocumentSubType.Other:
      return otherSupportDocumentAttributeMapping;
    case SupportDocumentSubType.WholesaleCertificate:
      return wholesaleCertificateAttributesMapping;
    default:
      throw new Error('Unsupported document subtype');
  }
}

export function getInitialDataBySubType(subType: SupportDocumentSubType): { [key: string]: any } {
  switch (subType) {
    case SupportDocumentSubType.CertificateOfTitle:
      return createInitialData(certificateOfTitleAttributesMapping);
    case SupportDocumentSubType.ValuationNotice:
      return createInitialData(valuationNoticeAttributeMapping);
    case SupportDocumentSubType.RatesNotice:
      return createInitialData(ratesNoticeAttributeMapping);
    case SupportDocumentSubType.NationalVendorDeclarationForLivestock:
      return createInitialData(nationalVendorDeclarationForLivestockAttributeMapping);
    case SupportDocumentSubType.Other:
      return createInitialData(otherSupportDocumentAttributeMapping);
    case SupportDocumentSubType.WholesaleCertificate:
      return createInitialData(wholesaleCertificateAttributesMapping);
    default:
      throw new Error('Unsupported document subtype');
  }
}


