export const enum SignInState {
  NOT_SIGNED_IN,
  VALIDATE_SIGN_IN_STATUS,
  SIGNIN_LINK_REQUESTED,
  SIGNING_YOU_IN,
  SIGNED_IN,
}

export const enum SignInMethodUsed {
  MAGIC_LINK,
  FIDO,
}
