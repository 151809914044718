/**
     * @desc List of Customers with a specific status
List of Customers with a specific status
     */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** The entity status to filter by */
  entityStatus;
  /** The entity type to filter by */
  entityType;
  /**     When true, the references will be resolved and customer data will be included on
    `customer` of the reference. False otherwise. Default is true.
 */
  resolveReferences;
}

export const method = 'GET';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/customer', params, 'GET'),
    newValue,
    shouldRevalidate,
  );
}

export function useRequest(params = {}, swrOptions = {}) {
  return Hooks.useRequest('/v1/customer', params, swrOptions);
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl('/v1/customer', params, 'GET', option);
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'GET',
  });
}
