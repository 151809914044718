'use client';

import React from 'react';
import { TMainLoginContext } from './types';
import { MainLoginState } from './constants';
import { TContextStore, createContextStore } from '../util';

export const MainLoginContext = React.createContext<TContextStore<TMainLoginContext>>(undefined);

export default function MainLoginContextProvider({
  children,
  defaultState,
}: {
  children: React.ReactNode;
  defaultState: MainLoginState;
}) {
  const mainLoginContextStore = createContextStore<TMainLoginContext>({
    state: defaultState,
    busy: false,
  });
  return (
    <MainLoginContext.Provider value={mainLoginContextStore}>{children}</MainLoginContext.Provider>
  );
}
