'use client';

import { ComponentType, useEffect, useState } from 'react';
import { otpHasUserRegistered } from '../otp';
import { SignInMethodUsed } from '@verifime/components';
import { usePasswordless } from './hooks/usePasswordless';

export default function DisplayCredentialSetupReminder({
  ReminderComponent,
}: {
  ReminderComponent: ComponentType<{ reminderType: SignInMethodUsed }>;
}) {
  const { fido2Credentials } = usePasswordless();
  const [loading, setLoading] = useState(true);
  const [fidoRegistered, setFidoRegistered] = useState(false);
  const [totpRegistered, setTotpRegistered] = useState(false);
  const [reminderType, setReminderType] = useState<SignInMethodUsed>();

  useEffect(() => {
    if (fido2Credentials?.length) {
      setFidoRegistered(true);
    }
    otpHasUserRegistered()
      .then((otpRegistered) => {
        setTotpRegistered(otpRegistered);
      })
      .catch() // ignore errors
      .finally(() => setLoading(false));
  }, [fido2Credentials?.length]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!totpRegistered && fidoRegistered) {
      setReminderType(SignInMethodUsed.MAGIC_LINK);
    } else if (totpRegistered && !fidoRegistered) {
      setReminderType(SignInMethodUsed.FIDO);
    }
  }, [fidoRegistered, totpRegistered, loading]);

  if (loading || !ReminderComponent || reminderType === undefined) {
    return;
  }

  return <ReminderComponent reminderType={reminderType} />;
}
