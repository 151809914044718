/**
 * @description
 */
import * as postFile from './postFile';
import * as postFilePresign from './postFilePresign';
import * as getFileByEntityIdByDocType from './getFileByEntityIdByDocType';
import * as deleteFileByEntityIdByDocTypeByFileId from './deleteFileByEntityIdByDocTypeByFileId';
import * as getFileByEntityIdByDocTypeByFileIdPresignDownload from './getFileByEntityIdByDocTypeByFileIdPresignDownload';

export {
  postFile,
  postFilePresign,
  getFileByEntityIdByDocType,
  deleteFileByEntityIdByDocTypeByFileId,
  getFileByEntityIdByDocTypeByFileIdPresignDownload,
};
