import React from 'react';
import { SignInMethodUsed } from '../constants';
import { MainLoginState } from './constants';
import EnterYourEmail from './EnterYourEmail';
import LoginMethodSelection from './LoginMethodSelection';
import { TMainLoginProps } from './types';
import { PasswordlessLoginContext } from '../PasswordlessLoginContextProvider';
import MainLoginContextProvider, { MainLoginContext } from './MainLoginContextProvider';

function MainLoginContainer({ ...props }: TMainLoginProps) {
  const passwordlessLoginContextStore = React.useContext(PasswordlessLoginContext);
  const { email } = passwordlessLoginContextStore.context;
  const setPasswordlessLoginContext = passwordlessLoginContextStore.setContext;

  const mainLoginStateContextStore = React.useContext(MainLoginContext);
  const setMainLoginStateContextStore = mainLoginStateContextStore.setContext;
  const mainLoginState = mainLoginStateContextStore.context.state;

  React.useEffect(() => {
    setPasswordlessLoginContext({ errorToShow: props.errorToShow });
  }, [props.errorToShow]);

  const signingIn = (signInMethod: SignInMethodUsed, oneTimePassword?: string) => {
    setMainLoginStateContextStore({ busy: true });
    if (typeof props.performSignIn === 'function') {
      props.performSignIn(email, signInMethod, oneTimePassword).finally(() => {
        setMainLoginStateContextStore({ busy: false });
      });
    }
  };
  const signingInWithFido = () => {
    signingIn(SignInMethodUsed.FIDO);
  };
  const signingInWithMagicLink = (oneTimePassword: string) => {
    signingIn(SignInMethodUsed.MAGIC_LINK, oneTimePassword);
  };

  return (
    <>
      {mainLoginState === MainLoginState.ENTER_YOUR_EMAIL && <EnterYourEmail />}
      {mainLoginState === MainLoginState.LOGIN_METHOD_SELECTION && (
        <LoginMethodSelection
          signingInWithFido={signingInWithFido}
          signingInWithMagicLink={signingInWithMagicLink}
          onSignInAsDifferentUser={props.onSignInAsDifferentUser}
        />
      )}
    </>
  );
}

export default function MainLogin({ ...props }: TMainLoginProps) {
  const defaultState =
    props.lastSignedInEmail?.length > 0
      ? MainLoginState.LOGIN_METHOD_SELECTION
      : MainLoginState.ENTER_YOUR_EMAIL;

  return (
    <MainLoginContextProvider defaultState={defaultState}>
      <MainLoginContainer {...props} />
    </MainLoginContextProvider>
  );
}
