import { Box, MenuItem } from '@mui/material';
import { TOption } from '@verifime/utils';
import React from 'react';
import { Control, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { FormCheckbox, FormDate, FormFileInput, FormSelect, FormTextInput } from '.';
import ResponsiveBox from '../ResponsiveBox';
import { RenderType, TFormFieldsAndRules, TFromFieldInfo, TRenderType } from './utils';

const fieldComponentMapping: {
  [renderType in TRenderType]: (...props: any) => JSX.Element;
} = {
  [RenderType.Text]: ({
    fieldInfo,
    control,
    errors,
    onTextChange,
  }: {
    fieldInfo: TFromFieldInfo;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    onTextChange?: (text: string) => void;
  }) => (
    <FormTextInput
      required={fieldInfo.showRequiredAsterisk} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      type="text"
      margin="normal"
      fullWidth
      control={control}
      error={errors[fieldInfo.fieldName] as any}
      data-cy={fieldInfo.dataCy}
      isValueUpperCase={fieldInfo.isValueUpperCase}
      onTextChange={onTextChange}
      disabled={fieldInfo.disabled}
    />
  ),
  [RenderType.Number]: () => { throw Error("Not implemented") },
  [RenderType.Select]: ({
    fieldInfo,
    control,
    formFieldsWithDefaultValues,
    initValue,
    onOptionChange,
    disabled,
  }: {
    fieldInfo: TFromFieldInfo;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue: any;
    onOptionChange?: (value: any) => void;
    disabled?: () => boolean;
  }) => (
    <FormSelect
      required={fieldInfo.showRequiredAsterisk} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      control={control}
      defaultValue={formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || ''}
      onOptionChange={(e) => {
        if (typeof onOptionChange === 'function') {
          onOptionChange(e.target.value);
        }
      }}
      dataCy={fieldInfo.dataCy}
      disabled={disabled?.() || fieldInfo.disabled}
    >
      {typeof fieldInfo.renderItems === 'function'
        ? fieldInfo.renderItems()
        : fieldInfo.items?.map((option: TOption) => (
            <MenuItem
              key={option.code}
              value={option.code}
              data-cy={`${fieldInfo.dataCy}-option-${option.code}`}
            >
              {option.label}
            </MenuItem>
          ))}
    </FormSelect>
  ),
  [RenderType.Date]: ({
    fieldInfo,
    control,
    formFieldsWithDefaultValues,
    initValue,
  }: {
    fieldInfo: TFromFieldInfo;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    formFieldsWithDefaultValues?: Record<string, any>;
    initValue: any;
  }) => (
    <FormDate
      required={fieldInfo.showRequiredAsterisk} // To control whether show * or not in screen
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      control={control}
      maxDate={fieldInfo.maxDate}
      minDate={fieldInfo.minDate}
      defaultValue={formFieldsWithDefaultValues?.[fieldInfo.fieldName] || initValue || ''}
      data-cy={fieldInfo.dataCy}
      disabled={fieldInfo.disabled}
    />
  ),
  // TODO Need to figure out how to combine GenerateFormFields_New and GenerateFormFields
  // Do not implement DateRange for old one.
  [RenderType.DateRange]: () => <></>,
  [RenderType.Checkbox]: ({
    fieldInfo,
    control,
    errors,
  }: {
    fieldInfo: TFromFieldInfo;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
  }) => (
    <FormCheckbox
      required={isShowRequiredAsterisk(fieldInfo)}
      name={fieldInfo.fieldName}
      label={fieldInfo.label}
      control={control}
      error={errors[fieldInfo.fieldName] as any}
      data-cy={generareDataCy(fieldInfo)}
      disabled={fieldInfo.disabled}
    />
  ),
  [RenderType.File]: ({
    fieldInfo,
    control,
    onFileChange,
  }: {
    fieldInfo: TFromFieldInfo;
    control: Control<FieldValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        [x: string]: any;
      }>
    >;
    onFileChange: (file: File) => void;
  }) => (
    <Box sx={{ width: '100%', my: '1rem' }}>
      <FormFileInput
        name={fieldInfo.fieldName}
        label={fieldInfo.label}
        control={control}
        helpText={fieldInfo?.helpText}
        onChange={onFileChange}
        required={fieldInfo.showRequiredAsterisk} // To control whether show * or not in screen
        data-cy={fieldInfo.dataCy}
        disabled={fieldInfo.disabled}
      />
    </Box>
  ),
  // TODO Need to figure out how to combine GenerateFormFields_New and GenerateFormFields
  // Do not implement TextEditor for old one.
  [RenderType.TextEditor]: () => <></>,
  // Do not implement this for old one
  [RenderType.RadioGroup]: () => <></>,
  [RenderType.ComboBox]: () => <></>,
};

export default function GenerateFormFields({
  formFieldsAndRules,
  control,
  errors,
  formFieldsWithDefaultValues,
  fieldsOperations = {},
  ContainerComponent = React.useCallback(ResponsiveBox, [ResponsiveBox]),
}: {
  formFieldsAndRules: TFormFieldsAndRules;
  control: Control<FieldValues, any>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  formFieldsWithDefaultValues?: Record<string, any>;
  /**
   * There are maybe several Select to be rendered in the `formFieldsAndRules`,
   * each of them may have different `initial value`, `onOptionChange` operations,
   * and those `onOptionChange` even `initial value` cannot be predefined in the `formFieldsAndRules`,
   * thus expose this to accept different operations of fields
   */
  fieldsOperations?: {
    [fieldName: string]: {
      initValue?: any;
      onOptionChange?: (value: any) => void;
      onFileChange?: (file: File) => void;
      onTextChange?: (text: string) => void;
      disabled?: () => boolean;
    };
  };
  ContainerComponent?: React.FunctionComponent<any>;
}) {
  // TODO: Reorganise formFixeldsAndRules by the ticket https://greengatefintech.atlassian.net/browse/VME-501
  const toHaveRenderTypeFields = Object.entries(formFieldsAndRules || {}).filter(
    ([_, v]) => v.renderType != null,
  );

  let lineNumber = 0;
  type TFields = { [line: string]: [fieldName: string, fieldInfo: TFromFieldInfo][] };
  const toBeRenderedFields: TFields = toHaveRenderTypeFields.reduce(
    (tally: TFields, [fieldName, fieldInfo]) => {
      if (fieldInfo.isNewLine) {
        lineNumber += 1;
      }

      let key = `line${lineNumber}`;
      if (!tally[key]) {
        tally[key] = [];
      }
      tally[key].push([fieldName, fieldInfo]);
      return tally;
    },
    {},
  );

  if (Object.keys(toBeRenderedFields).length < 1) {
    return null;
  }

  return (
    <>
      {Object.entries(toBeRenderedFields).map(([line, fields]) => {
        return (
          <ContainerComponent key={line}>
            {fields.map(([fieldName, fieldInfo]) => {
              const { initValue, onOptionChange, onFileChange, onTextChange, disabled } =
                fieldsOperations?.[fieldName] || {};
              return (
                <React.Fragment key={fieldName}>
                  {fieldComponentMapping[fieldInfo.renderType]?.({
                    control,
                    errors,
                    fieldInfo,
                    formFieldsWithDefaultValues,
                    onOptionChange,
                    initValue,
                    onFileChange,
                    onTextChange,
                    disabled,
                  })}
                </React.Fragment>
              );
            })}
          </ContainerComponent>
        );
      })}
    </>
  );
}

export function isShowRequiredAsterisk(fieldInfo: TFromFieldInfo): boolean {
  return !fieldInfo.rules.describe().optional;
}

export function generareDataCy(fieldInfo: TFromFieldInfo): string {
  const fieldName = fieldInfo.fieldName.replace(/([A-Z])/g, '-$1'); // Split camel case
  return `${fieldInfo.renderType}-${fieldName}`.toLowerCase();
}
