import { Button, ButtonProps, CircularProgress } from '@mui/material';
import tokens from '@verifime/design-tokens';

export default function CircularProgressButton({
  children,
  isShowProgress,
  disabled,
  ...props
}: { isShowProgress?: boolean } & ButtonProps) {
  return (
    <Button {...props} disabled={disabled || isShowProgress}>
      {children}
      {isShowProgress && (
        <CircularProgress sx={{ marginLeft: tokens.spacingXs }} color="inherit" size={20} />
      )}
    </Button>
  );
}
