import { createTheme } from '@mui/material/styles';
import tokens, { generateColor, generateOpacityColor } from '@verifime/design-tokens';
import { commonComponentsOverride, paletteGrey, typographyTheme } from './common';

const errorContent = generateColor(tokens.brandColorError, '700');
const errorFill = generateColor(tokens.brandColorError, '050');
const warningContent = generateColor(tokens.brandColorWarning, '700');
const warningFill = generateColor(tokens.brandColorWarning, '050');
const infoContent = generateColor(tokens.brandColorInfo, '700');
const infoFill = generateColor(tokens.brandColorInfo, '050');
const successContent = generateColor(tokens.brandColorSuccess, '700');
const successFill = generateColor(tokens.brandColorSuccess, '050');

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: generateColor(tokens.brandColorPrimary, '500'),
      dark: generateColor(tokens.brandColorPrimary, '600'),
      light: generateColor(tokens.brandColorPrimary, '400'),
      contrastText: tokens.colorWhite,
    },
    secondary: {
      main: generateColor(tokens.brandColorSecondary, '500'),
      dark: generateColor(tokens.brandColorSecondary, '600'),
      light: generateColor(tokens.brandColorSecondary, '400'),
      contrastText: tokens.colorWhite,
    },
    error: {
      main: generateColor(tokens.brandColorError, '500'),
      dark: generateColor(tokens.brandColorError, '600'),
      light: generateColor(tokens.brandColorError, '400'),
      contrastText: tokens.colorWhite,
    },
    warning: {
      main: generateColor(tokens.brandColorWarning, '500'),
      dark: generateColor(tokens.brandColorWarning, '600'),
      light: generateColor(tokens.brandColorWarning, '400'),
      contrastText: tokens.colorWhite,
    },
    info: {
      main: generateColor(tokens.brandColorInfo, '500'),
      dark: generateColor(tokens.brandColorInfo, '600'),
      light: generateColor(tokens.brandColorInfo, '400'),
      contrastText: tokens.colorWhite,
    },
    success: {
      main: generateColor(tokens.brandColorSuccess, '500'),
      dark: generateColor(tokens.brandColorSuccess, '600'),
      light: generateColor(tokens.brandColorSuccess, '400'),
      contrastText: tokens.colorWhite,
    },
    text: {
      primary: generateOpacityColor(tokens.colorBlack, 0.9),
      secondary: generateOpacityColor(tokens.colorBlack, 0.6),
      disabled: generateOpacityColor(tokens.colorBlack, 0.4),
    },
    background: {
      default: tokens.colorGrey020,
      paper: tokens.colorWhite,
    },
    action: {
      disabled: generateOpacityColor(tokens.colorBlack, 0.4),
      disabledBackground: generateOpacityColor(tokens.colorBlack, 0.1),
      disabledOpacity: 0.4,
      focus: generateOpacityColor(tokens.colorBlack, 0.1),
      focusOpacity: 0.1,
      selected: generateColor(tokens.brandColorPrimary, '200'),
      selectedOpacity: 0.2,
      hover: generateOpacityColor(tokens.colorBlack, 0.05),
      hoverOpacity: 0.05,
      active: generateOpacityColor(tokens.colorBlack, 0.5),
      activatedOpacity: 0.1,
    },
    grey: paletteGrey,
  },
  typography: typographyTheme,
  components: {
    MuiButton: {
      variants: [
        ...commonComponentsOverride.MuiButton!.variants!,
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: generateColor(tokens.brandColorPrimary, '500'),
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            color: generateColor(tokens.brandColorPrimary, '500'),
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        color: generateColor(tokens.brandColorPrimary, '500'),
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: errorFill,
          '& .MuiAlertTitle-root': {
            color: errorContent,
          },
          '& .MuiTypography-root': {
            color: errorContent,
          },
          '& button': {
            color: errorContent,
          },
        },
        standardWarning: {
          backgroundColor: warningFill,
          '& .MuiAlertTitle-root': {
            color: warningContent,
          },
          '& .MuiTypography-root': {
            color: warningContent,
          },
          '& button': {
            color: warningContent,
          },
        },
        standardInfo: {
          backgroundColor: infoFill,
          '& .MuiAlertTitle-root': {
            color: infoContent,
          },
          '& .MuiTypography-root': {
            color: infoContent,
          },
          '& button': {
            color: infoContent,
          },
        },
        standardSuccess: {
          backgroundColor: successFill,
          '& .MuiAlertTitle-root': {
            color: successContent,
          },
          '& .MuiTypography-root': {
            color: successContent,
          },
          '& button': {
            color: successContent,
          },
        },
      },
    },
  },
});

export default theme;
