import tokens from '@verifime/design-tokens';
import React, { FormEvent } from 'react';
import LoginButton from '../common/LoginButton';
import { LoginCenterContainer } from '../common/LoginContainer';
import TextField from '@mui/material/TextField';
import { MainLoginContext } from './MainLoginContextProvider';

export default function SignInWithMagicLink({
  signingInWithMagicLink,
}: {
  signingInWithMagicLink: (totp: string) => void;
}) {
  const [totp, setTotp] = React.useState('');
  const { context: { busy } } = React.useContext(MainLoginContext);

  const handleSendASecureLink = (event: FormEvent) => {
    event.preventDefault();
    signingInWithMagicLink(totp);
  };

  return (
    <LoginCenterContainer component="form" gap={tokens.spacingSm} onSubmit={handleSendASecureLink}>
      <TextField
        value={totp}
        onChange={(e) => setTotp(e.target.value)}
        fullWidth
        variant="outlined"
        size="medium"
        type="number"
        autoFocus
        label="code from your authenticator app"
        disabled={busy}
        sx={{
          marginBottom: tokens.spacingSm,
          '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
          {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '& input[type="number"]': {
            '-moz-appearance': 'textfield',
          },
        }}
      />
      <LoginButton
        variant="contained"
        type="submit"
        disabled={busy || !totp?.match(/^\d{6}$/)}
        isShowProgress={busy}
      >
        Send me a secure link
      </LoginButton>
    </LoginCenterContainer>
  );
}
