'use client';

import Typography from '@mui/material/Typography';
import Link from 'next/link';
import React from 'react';
import { LoginContainer } from './common/LoginContainer';
import { LoginToVerifiMeTitle } from './common/LoginTitle';
import { SignInState } from './constants';
import MainLogin, { TMainLoginProps } from './MainLogin';
import PasswordlessLoginContextProvider from './PasswordlessLoginContextProvider';
import { SigningYouIn } from './SigningYouIn';
import SignInLinkRequested from './SignInLinkRequested';
import ValidatingSignInStatus from './ValidatingSignInStatus';

export default function PasswordlessLogin({
  signInState,
  children,
  ...props
}: {
  children?: React.ReactNode;
  signInState: SignInState;
} & TMainLoginProps) {
  return (
    <PasswordlessLoginContextProvider lastSignedInEmail={props.lastSignedInEmail}>
      <LoginContainer>
        {SignInState.NOT_SIGNED_IN === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <MainLogin {...props} />
            <Typography variant="body1">
              New to VerifiMe? <Link href="/sign-up">Create a wallet</Link>
            </Typography>
          </>
        )}
        {SignInState.SIGNIN_LINK_REQUESTED === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <SignInLinkRequested />
          </>
        )}
        {SignInState.VALIDATE_SIGN_IN_STATUS === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <ValidatingSignInStatus />
          </>
        )}
        {SignInState.SIGNING_YOU_IN === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <SigningYouIn />
          </>
        )}
        {SignInState.SIGNED_IN === signInState && children && (
          <>
            <LoginToVerifiMeTitle />
            {children}
          </>
        )}
        {}
      </LoginContainer>
    </PasswordlessLoginContextProvider>
  );
}
