import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import tokens from '@verifime/design-tokens';
import { formatToDDMMMYYYYHHMMAAA, stringUtils } from '@verifime/utils';
import { ReactNode, useEffect, useState } from 'react';
import { ColoredIcon } from '../ColoredIconAndChip';
import DocumentNotFound from '../DocumentNotFound';
import NameValueDisplay from '../NameValueDisplay';

type TRequestDetailProps = {
  onRequest: () => void;
  requestBy: string;
  requestDateTime: string;
};

export type TPortraitDisplayProps = Partial<{
  portraitImageUrl: string;
  footer: ReactNode;
  requestDetail?: TRequestDetailProps;
}>;

const RequestButton = ({ requestBy, requestDateTime, onRequest }: TRequestDetailProps) => {
  // We decided to use if the callback exists or not to decide whether display this request button
  if (!onRequest) {
    return;
  }

  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      {requestDateTime && (
        <NameValueDisplay
          displayType="horizontal"
          name="Requested on"
          value={formatToDDMMMYYYYHHMMAAA(requestDateTime)}
        />
      )}
      {requestBy && (
        <NameValueDisplay
          displayType="horizontal"
          name="by user"
          value={stringUtils.ellipsis(requestBy)}
        />
      )}
      <Button startIcon={<ColoredIcon iconName="pending" />} variant="outlined" onClick={onRequest}>
        {/** Just use one of requestDateTime and requestBy to determine whether this is the first request or not */}
        {requestDateTime ? 'Request again' : 'Request now'}
      </Button>
    </Stack>
  );
};

export default function PortraitDisplay({
  portraitImageUrl,
  footer,
  requestDetail,
}: Readonly<TPortraitDisplayProps>) {
  const [portraitBlobUrl, setPortraitBlobUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageBroken, setIsImageBroken] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!portraitImageUrl) {
      setIsLoading(false);
      setIsImageBroken(true);
      return;
    }

    fetch(portraitImageUrl)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then(setPortraitBlobUrl)
      .catch(() => setIsImageBroken(true))
      .finally(() => setIsLoading(false));
  }, [portraitImageUrl]);

  return (
    <Box minWidth={tokens.size2xs} maxWidth={tokens.size2xs} minHeight={tokens.size3xs}>
      {isLoading ? (
        <CircularProgress />
      ) : isImageBroken ? (
        <DocumentNotFound text="Not Available" slot={<RequestButton {...requestDetail} />} />
      ) : (
        <Stack alignItems="center">
          <img
            style={{ borderRadius: tokens.borderRadiusSm }}
            alt="liveness portrait"
            src={portraitBlobUrl}
            onError={() => setIsImageBroken(true)}
            width="100%"
          />
          {footer}
          <RequestButton {...requestDetail} />
        </Stack>
      )}
    </Box>
  );
}
