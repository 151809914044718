import { Stack, StackProps, Typography, TypographyProps, Theme } from '@mui/material';
import { ColoredIcon } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { TStatus } from '@verifime/utils';
import { ReactNode } from 'react';

type TVerificationStatusProps = {
  status: TStatus;
  showLabel?: boolean;
  showIcon?: boolean;
};

type TIconProps = Parameters<typeof ColoredIcon>[0];
type TVerificationStatusDisplayProps = {
  color: StackProps['color'];
  label: ReactNode;
  labelProps?: TypographyProps;
  iconName: TIconProps['iconName'];
  iconProps?: Omit<TIconProps, 'iconName'>;
} & Required<Omit<TVerificationStatusProps, 'status'>>;

function VerificationStatusDisplay({
  color,
  showLabel,
  showIcon,
  label,
  labelProps,
  iconName,
  iconProps,
}: TVerificationStatusDisplayProps) {
  return (
    <Stack
      direction="row"
      gap={tokens.spacingXs}
      alignItems="center"
      justifyItems="flex-start"
      color={color}
    >
      {showIcon && <ColoredIcon iconName={iconName} {...iconProps} />}
      {showLabel && <Typography {...labelProps}>{label}</Typography>}
    </Stack>
  );
}

const STATUS_MAPPING: {
  [status in TStatus]: Omit<TVerificationStatusDisplayProps, 'showLabel' | 'showIcon'>;
} = {
  Verified: {
    color: (theme: Theme) => theme.palette.success.main,
    iconName: 'checkCircle',
    label: 'Verified',
  },
  Rejected: {
    color: (theme: Theme) => theme.palette.error.main,
    iconName: 'block',
    label: 'Action required',
  },
  InProgress: {
    color: (theme: Theme) => theme.palette.text.secondary,
    iconName: 'pending',
    label: 'In Progress',
  },
  Expired: {
    color: (theme: Theme) => theme.palette.action.disabled,
    iconName: 'remove',
    label: 'Expired',
  },
  NotAvailable: {
    color: (theme: Theme) => theme.palette.action.disabled,
    iconName: 'remove',
    label: 'Not available',
  },
  Matched: {
    color: (theme: Theme) => theme.palette.error.main,
    iconName: 'block',
    label: 'Matched',
  },
  NotMatched: {
    color: (theme: Theme) => theme.palette.success.main,
    iconName: 'checkCircle',
    label: 'No Match',
  },
};

export default function VerificationStatus({
  status,
  showLabel = true,
  showIcon = true,
}: TVerificationStatusProps) {
  const verificationStatusDisplayProps = STATUS_MAPPING[status];
  return (
    <VerificationStatusDisplay
      {...verificationStatusDisplayProps}
      showLabel={showLabel}
      showIcon={showIcon}
    />
  );
}
