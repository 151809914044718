'use client';

import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, AutocompleteProps, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';

export type TComboBox<T> = {
  label?: string;
} & Omit<AutocompleteProps<T, any, any, true | false>, 'renderInput'>;

export default function ComboBox<T>({ sx, label, ...props }: TComboBox<T>) {
  const [isOpen, setOpen] = useState(false);

  return (
    // Had no idea to tackle the interesting build error `Type error: Expression produces a union type that is too complex to represent.`
    // Thus ignore the check for now
    // @ts-ignore
    <Autocomplete
      // No token yet for width
      sx={{ width: !props.fullWidth ? 400 : '100%', ...{ sx } }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      componentsProps={{
        paper: {
          elevation: 8,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={
            isOpen
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }
              : params.InputProps
          }
          label={label}
        />
      )}
      forcePopupIcon={false}
      {...props}
    />
  );
}
