import { Button, Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';
import { ColoredIcon } from '../ColoredIconAndChip';

export default function SkippingFaceCheck({
  customisedSkip,
  onRetry,
}: Readonly<{ customisedSkip?: ReactNode; onRetry: () => void }>) {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <ColoredIcon iconName="infoRounded" />
        <Typography variant="h6">Skipping Face Check</Typography>
        {customisedSkip ? (
          <>{customisedSkip}</>
        ) : (
          <Typography variant="body2" textAlign="center">
            Your service provider can choose to decline service in the absence of a Face check.
          </Typography>
        )}
        <Button onClick={onRetry}>Retry face check</Button>
      </Stack>
    </Paper>
  );
}
