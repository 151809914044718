import { Button, ButtonProps } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';

export default function FatButton({
  children,
  ...props
}: { target?: HTMLAttributeAnchorTarget } & ButtonProps) {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        ...props.sx,
        padding: '1rem',
        borderRadius: '0.8rem',
        minWidth: '7rem',

        fontSize: '1.5rem',
      }}
      size="large"
    >
      {children}
    </Button>
  );
}
