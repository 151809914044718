import { Stack, Typography, Button, Box } from '@mui/material';
import Image from 'next/image';
import NoSearch from './no-search-results-found.png';
import tokens from '@verifime/design-tokens';

export default function InvalidLink({
  buttonText,
  buttonHref,
}: {
  buttonText: string;
  buttonHref: string;
}) {
  return (
    <Stack gap={tokens.spacingXl} paddingX={tokens.spacingLg} marginTop={tokens.spacing4xl}>
      <Image src={NoSearch} height="121" width="128" alt="No Search Results Found" />
      <Typography variant="h3">Sorry, this link is not valid</Typography>
      <Typography variant="body1">
        This link is expired or incorrect. Please check and try again.
      </Typography>
      <Box>
        <Button variant="contained" href={buttonHref}>
          {buttonText}
        </Button>
      </Box>
    </Stack>
  );
}
