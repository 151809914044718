import React, { forwardRef } from 'react';
import { SnackbarContent, CustomContentProps } from 'notistack';
import { Alert } from '@mui/material';

export const SuccessNotification = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ ...props }, ref) => {
    return (
      <SnackbarContent ref={ref}>
        <Alert
          severity="success"
          elevation={6}
          variant="filled"
          sx={{
            color: 'white',
          }}
        >
          <b>Success: </b>
          {props.message}
        </Alert>
      </SnackbarContent>
    );
  },
);
SuccessNotification.displayName = 'SuccessNotification';

export const ErrorNotification = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ ...props }, ref) => {
    return (
      <SnackbarContent ref={ref}>
        <Alert
          severity="error"
          elevation={6}
          variant="filled"
          sx={{
            color: 'white',
          }}
        >
          <b>Error: </b>
          {props.message}
        </Alert>
      </SnackbarContent>
    );
  },
);
ErrorNotification.displayName = 'ErrorNotification';
