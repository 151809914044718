import { TAddress, TIndividual, formatToDDMMMYYYY } from '@verifime/utils';
import DisplayAddress from './DisplayAddress';
import InformationDisplay, { TInformationDisplayRows } from './InformationDisplay';

const individualInformationRows: TInformationDisplayRows<keyof TIndividual> = {
  row1: [
    {
      label: 'Name',
      fieldName: ['firstName', 'middleName', 'familyName'],
      copyable: true,
      valueFormatter: (values: string[]) => {
        return values.filter(Boolean).join(' ');
      },
    },
  ],
  row2: [
    {
      label: 'Date of Birth',
      fieldName: 'dateOfBirth',
      valueFormatter: (dateOfBirth) => formatToDDMMMYYYY(dateOfBirth),
    },
  ],
  row3: [{ label: 'Email', fieldName: 'email' }],
  row4: [
    {
      label: 'Address',
      fieldName: 'address',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

export default function IndividualContact({ identity }: { identity: TIndividual }) {
  return <InformationDisplay informationDisplaysRows={individualInformationRows} data={identity} />;
}
