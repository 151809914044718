import { Box, Typography } from '@mui/material';
import { TCountryCode } from '@verifime/utils';
import { FormSelect, TFieldsOperations, TFormFieldsAndRules } from '@verifime/components';
import Address, { TAddressProps } from './Address';

export type TAddressWithCountrySelectProps = {
  formValidations: TFormFieldsAndRules;
  onCountryChange?: (newCountryCode: TCountryCode) => void;
  defaultValues: Record<string, any>;
  addressInputLabel?: string;
  addressInputPlaceholder?: string;
  inputsFieldsOperations?: TFieldsOperations;
} & Omit<TAddressProps, 'addressFieldsAndRules'>;

export default function AddressWithCountrySelect({
  formValidations,
  countryCode,
  control,
  errors,
  onCountryChange,
  onPlaceSelected,
  trigger,
  setValue,
  label,
  addressInputLabel,
  addressInputPlaceholder,
  ...props
}: TAddressWithCountrySelectProps) {
  // We'd like to show country separately above other fields
  const countryField = formValidations.countryCode;

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column' }}
      data-cy={`address-${countryCode}`}
      key={countryCode}
    >
      <Typography sx={{ opacity: '.8' }}>{label ?? 'Address'}</Typography>

      <FormSelect
        required
        name={countryField.fieldName}
        label={countryField.label}
        control={control}
        defaultValue={countryCode}
        onOptionChange={(e) => {
          onCountryChange?.(e.target.value as TCountryCode);
        }}
        dataCy="select-country"
      >
        {countryField.renderItems()}
      </FormSelect>
      <Address
        countryCode={countryCode}
        addressFieldsAndRules={formValidations}
        control={control}
        errors={errors}
        trigger={trigger}
        setValue={setValue}
        onPlaceSelected={onPlaceSelected}
        label={addressInputLabel}
        placeholder={addressInputPlaceholder}
        {...props}
      />
    </Box>
  );
}
