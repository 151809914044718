import {
  EXCHANGE_LISTING_TYPE,
  IdentityTypePrivateCompany,
  TAddress,
  TCompany,
  TExchangeListingType,
} from '@verifime/utils';
import DisplayAddress from './DisplayAddress';
import InformationDisplay, { TInformationDisplayRows } from './InformationDisplay';

const privateCompanyInformationRows: TInformationDisplayRows<keyof TCompany> = {
  row1: [{ label: 'Company Name', fieldName: 'companyName' }],
  row2: [
    {
      label: 'Australian Company Number (ACN)',
      fieldName: 'companyNumber',
    },
  ],
  row3: [
    {
      label: 'Registered Office',
      fieldName: 'registeredOffice',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
  row4: [
    {
      label: 'Principal Place of Business',
      fieldName: 'principalPlaceOfBusiness',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

const publicCompanyInformationRows: TInformationDisplayRows<keyof TCompany> = {
  row1: [{ label: 'Company Name', fieldName: 'companyName' }],
  row2: [
    {
      label: 'Australian Company Number (ACN)',
      fieldName: 'companyNumber',
    },
  ],
  row3: [
    {
      label: 'Exchange listed on',
      fieldName: 'listedExchange',
      valueFormatter: (listedExchange) =>
        EXCHANGE_LISTING_TYPE[listedExchange as TExchangeListingType],
    },
    {
      label: 'Ticker',
      fieldName: 'ticker',
    },
  ],
};

export default function CompanyContact({ identity }: { identity: TCompany }) {
  return (
    <InformationDisplay<TCompany>
      informationDisplaysRows={
        identity.entityType === IdentityTypePrivateCompany.code
          ? privateCompanyInformationRows
          : publicCompanyInformationRows
      }
      data={identity}
    />
  );
}
