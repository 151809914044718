import FlexBox from '../../FlexBox';
import { Stack } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { BoxProps } from '@mui/material/Box';
import { PaperProps } from '@mui/material/Paper';

export function LoginCenterContainer({ ...props }: BoxProps) {
  return (
    <FlexBox fullWidth direction="column" gap={tokens.spacingLg} alignItems="center" {...props}>
      {props.children}
    </FlexBox>
  );
}

export function LoginMessageContainer({ ...props }: BoxProps) {
  return (
    <LoginCenterContainer
      alignItems="stretch"
      {...props}
      sx={{
        padding: `${tokens.spacingXl} ${tokens.spacingNone}`,
        ...props.sx,
      }}
    >
      {props.children}
    </LoginCenterContainer>
  );
}

export function LoginContainer({ ...props }: PaperProps) {
  return (
    <Stack
      {...props}
      sx={{
        width: tokens.sizeXs,
        minHeight: 208,
        borderRadius: tokens.borderRadiusLg,
        p: tokens.spacingBase,
        gap: tokens.spacingLg,
        flexDirection: 'column',
        alignItems: 'center',
        ...props.sx,
      }}
    >
      {props.children}
    </Stack>
  );
}
