'use client';

import React, { ReactNode } from 'react';
import SetupYourAccount from './SetupYourAccount';
import tokens from '@verifime/design-tokens';
import SetupAuthenticatorApp, { TSetupAuthenticatorAppProps } from './SetupAuthenticatorApp';
import { ContainerLoading } from './Loading';
import { SetupState } from '../../types';
import SetupPasskey, { TSetupPasskeyProps } from './SetupPasskey';
import { LoginCenterContainer, LoginContainer } from '../common/LoginContainer';
import { LoginMethod } from '@verifime/utils';

export type TCredentialSetupProps = {
  hasRegisteredFIDO: () => Promise<boolean>;
  hasRegisteredOTP: () => Promise<boolean>;
  noCredentialComponent?: ({
    otpUserDisplayName,
    goNext,
  }: {
    otpUserDisplayName: string;
    goNext: () => void;
  }) => ReactNode;
  hidePasskeySetupOption: boolean;
} & TSetupPasskeyProps &
  TSetupAuthenticatorAppProps;

export type TInitialCredentialSetupProps = Omit<TCredentialSetupProps, 'setSetupState'>;

export default function InitialCredentialSetup({
  hasRegisteredFIDO,
  hasRegisteredOTP,
  setupComplete,
  registerFIDO,
  registerOTP,
  otpIssuer,
  otpUserDisplayName,
  noCredentialComponent,
  hidePasskeySetupOption,
}: TInitialCredentialSetupProps) {
  const [setupState, setSetupState] = React.useState<SetupState>(SetupState.LOADING);

  React.useEffect(() => {
    setSetupState(SetupState.LOADING);
    Promise.all([hasRegisteredFIDO(), hasRegisteredOTP()]).then(
      ([fidoRegistered, otpRegistered]) => {
        if (fidoRegistered || otpRegistered) {
          setupComplete(LoginMethod.DirectLogin);
          return;
        }

        if (typeof noCredentialComponent === 'function') {
          setSetupState(SetupState.NO_CREDENTIAL);
          return;
        }

        setSetupState(hidePasskeySetupOption ? SetupState.TOTP : SetupState.FIDO_STEP1);
      },
    );
  }, []);

  return (
    <>
      {setupState === SetupState.LOADING && <ContainerLoading />}
      {setupState === SetupState.NO_CREDENTIAL &&
        noCredentialComponent({
          otpUserDisplayName,
          goNext: () => {
            setSetupState(hidePasskeySetupOption ? SetupState.TOTP : SetupState.FIDO_STEP1);
          },
        })}
      {setupState === SetupState.FIDO_STEP1 && (
        <SetupYourAccount setSetupState={setSetupState} otpUserDisplayName={otpUserDisplayName} />
      )}
      {setupState === SetupState.FIDO_STEP2 && (
        <SetupPasskey
          setSetupState={setSetupState}
          setupComplete={setupComplete}
          registerFIDO={registerFIDO}
        />
      )}
      {setupState === SetupState.TOTP && (
        <LoginContainer sx={{ px: tokens.spacingBase }}>
          <LoginCenterContainer gap={tokens.spacingSm}>
            <SetupAuthenticatorApp
              setSetupState={setSetupState}
              registerOTP={registerOTP}
              otpIssuer={otpIssuer}
              otpUserDisplayName={otpUserDisplayName}
              setupComplete={setupComplete}
              displayBackOnEntry={!hidePasskeySetupOption}
            />
          </LoginCenterContainer>
        </LoginContainer>
      )}
    </>
  );
}
