import { Avatar, useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';

type TCircleLabelProps = {
  label: number;
};

export default function CircleLabel({ label }: TCircleLabelProps) {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: tokens.size6xs,
        height: tokens.size6xs,
        background: theme.palette.primary.main,
        color: theme.palette.text.primary,
        fontSize: tokens.fontSize2xl,
        fontWeight: tokens.fontWeightSemibold,
      }}
    >
      {label}
    </Avatar>
  );
}
