import { useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import VerificationStatus from './VerificationStatus';
import { formatToDDMMMYYYY } from '@verifime/utils';
import { NoDataFound } from '@verifime/components';

export type TKycRow = {
  id: number;
  identitySource?: string;
  expires?: string;
  lastUpdated?: string;
  status: string;
};
export type TKycRows = TKycRow[];
type TColumnMapping = { [key in keyof Omit<Required<TKycRow>, 'id'>]: GridColDef };

export default function KYC({
  rows,
  columnNames,
}: {
  rows: TKycRows;
  columnNames: (keyof TColumnMapping)[];
}) {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const flex = isDesktop && 1;
  const MAPPINGS: TColumnMapping = {
    identitySource: {
      field: 'identitySource',
      headerName: 'Identity Source',
      flex,
      sortable: false,
    },
    expires: {
      field: 'expires',
      headerName: 'Expires',
      flex,
      sortable: false,
      renderCell: ({ value }) => formatToDDMMMYYYY(value),
    },
    lastUpdated: {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex,
      sortable: false,
      renderCell: ({ value }) => formatToDDMMMYYYY(value),
    },
    status: {
      field: 'status',
      headerName: 'Status',
      flex,
      sortable: false,
      renderCell: ({ value }) => {
        return <VerificationStatus status={value} />;
      },
    },
  };

  if (columnNames.length === 0) {
    throw new Error('Column specified cannot be empty.');
  }
  const columns = columnNames.map((columnMapping) => MAPPINGS[columnMapping]);
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableDensitySelector
      disableVirtualization
      disableRowSelectionOnClick
      autoHeight
      sx={{ border: '0' }}
      slots={{
        noRowsOverlay: NoDataFound,
      }}
    />
  );
}
