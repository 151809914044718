import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Drawer, DrawerProps, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { TSupportDocumentSubType } from '@verifime/api-definition';
import { useHideDocumentScrollbar } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { toDocumentType } from '@verifime/utils';
import { useState } from 'react';
import { DocumentUploaderServiceCallsProps } from '../MultiFileUploader/useDocumentUploader';
import UploadForVerification from './UploadForVerification';

export default function UploadDocumentDrawer({
  title,
  entityId,
  submitForVerification,
  documentUploadServiceCalls,
  supportDocumentType,
  open,
  onClose,
  onBack,
  anchor = 'bottom',
  ...otherProps
}: {
  entityId: string;
  supportDocumentType: TSupportDocumentSubType;
  onBack?: () => void;
  submitForVerification: (fileId: string) => Promise<void>;
  documentUploadServiceCalls: DocumentUploaderServiceCallsProps;
} & DrawerProps) {
  const theme = useTheme();
  useHideDocumentScrollbar(open);

  const [isSubmittingForVerification, setIsSubmittingForVerification] = useState(false);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      transitionDuration={{
        enter: theme.transitions.duration.complex,
        exit: theme.transitions.duration.leavingScreen,
      }}
      {...otherProps}
    >
      <Box sx={{ maxHeight: '70vh', padding: tokens.spacingBase }}>
        <Stack justifyContent="space-between" flexDirection="row">
          <Stack flexDirection="row" alignItems="center">
            {onBack && (
              <IconButton onClick={onBack}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            <Typography variant="h4">{title}</Typography>
          </Stack>
          <IconButton onClick={() => onClose('bottom', 'backdropClick')}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <UploadForVerification
          entityId={entityId}
          documentType={toDocumentType(supportDocumentType)}
          onClose={() => onClose('bottom', 'backdropClick')}
          onSubmit={(fileId) => submitForVerification(fileId)}
          documentUploadServiceCalls={documentUploadServiceCalls}
        />
      </Box>
    </Drawer>
  );
}
