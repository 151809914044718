import { Alert, FormControl, FormHelperText } from '@mui/material';
import { HTMLInputTypeAttribute } from 'react';
import { Control, Controller } from 'react-hook-form';
import { formFieldLabel } from './utils';

type InputBaseProps = {
  type?: HTMLInputTypeAttribute;
};

type FormFileInputProps = {
  name: string;
  control: Control;
  rules?: object;
  hidden?: boolean;
  accept?: string;
  label?: string;
  helpText?: string;
  onChange?: (file: any) => void;
  required?: boolean;
  disabled?: boolean;
} & InputBaseProps;

const FormFileInput = ({
  name,
  label,
  rules,
  control,
  helpText,
  onChange,
  required,
  ...props
}: FormFileInputProps) => {
  const requiredLabel = formFieldLabel(label, required);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const { value, onChange: fieldOnChange, ...otherField } = field;
        const error = (formState.errors[name] as any)?.['file'];
        return (
          <FormControl {...props}>
            {label && (
              <label style={{ display: 'block', marginBottom: '.5rem' }}>{requiredLabel}</label>
            )}
            {helpText && (
              <Alert severity="info" sx={{ mb: '0.5rem' }}>
                {helpText}
              </Alert>
            )}
            <input
              style={{ width: '100%' }}
              type="file"
              disabled={props.disabled}
              {...otherField}
              accept="image/jpg,image/jpeg,image/png,.pdf"
              onChange={(e) => {
                const file = e.target.files?.[0];

                // Pass the file in an object to the field so that the yup schema can perform validations on the file such as size validation etc.
                fieldOnChange({ file: { file } });

                // File is passed as an object to the Controller level so that the parent component uses the FormFileInput can receive the selected file via onChange callback.
                if (typeof onChange === 'function') {
                  onChange({ file });
                }
              }}
            />
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default FormFileInput;
