import TextField, { TextFieldProps } from '@mui/material/TextField';
import { ChangeEvent } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

type FormTextInputProps = {
  name: string;
  control: Control;
  label: string;
  rules?: object;
  error?: FieldError;
  isValueUpperCase?: boolean;
  onTextChange?: (text: string) => void;
  margin?: TextFieldProps['margin'];
  size?: TextFieldProps['size'];
} & TextFieldProps;

const FormTextInput = ({
  name,
  control,
  label,
  rules,
  error,
  isValueUpperCase,
  margin = 'normal',
  size = 'medium',
  onTextChange,
  sx,
  ...props
}: FormTextInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue=""
      render={({ field }) => (
        <TextField
          sx={{ flex: '1', ...sx }}
          label={label}
          margin={margin}
          size={size}
          {...field}
          {...props}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (isValueUpperCase) {
              e.target.value = e.target.value.toUpperCase();
            }
            onTextChange?.(e.target.value);
            field.onChange(e);
          }}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default FormTextInput;
