import { Stack, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { TAddress, getAddressString } from '@verifime/utils';
import tokens from '@verifime/design-tokens';

export default function DisplayAddress({
  address,
  showIcon = true,
}: {
  address: TAddress;
  showIcon?: boolean;
}) {
  const multiLineAddress = (getAddressString(address)?.split(',') || []).filter((part) =>
    Boolean(part.replace(/^\s+$/g, '')),
  );

  if (multiLineAddress.length < 1) {
    return;
  }

  return (
    <Stack direction="row" gap={tokens.spacingXs}>
      {showIcon && <LocationOnIcon />}
      <Stack direction="column" gap={tokens.spacingXs}>
        {multiLineAddress.map((part, index) => (
          <Typography key={index} variant="body2">
            {part}
            {index !== multiLineAddress.length - 1 && ','}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
}
