import { breakpoints } from './constants';
import { TBreakpoint } from './types';

/**
 *
 * Generated css value is for breakpoints: `['xs', 'sm', 'md', 'lg', 'xl']`
 *
 */
export function getResponsiveCssValue(
  responsiveOnWidth: TBreakpoint | 'none',
  responsiveValue: string,
  nonResponsiveValue: string,
): any[] {
  let responsiveBreakpointsIdx = breakpoints.findIndex((bp) => bp === responsiveOnWidth);
  if (responsiveOnWidth === 'none') {
    responsiveBreakpointsIdx = -1;
  }

  let responsiveValues = [];
  for (let i = 0; i <= responsiveBreakpointsIdx; i++) {
    responsiveValues.push(responsiveValue);
  }

  for (let i = 0; i < breakpoints.length - responsiveBreakpointsIdx - 1; i++) {
    responsiveValues.push(nonResponsiveValue);
  }

  return responsiveValues;
}

export function convertRemToPixels(rem : string) : number {    
  return parseFloat(rem.split('rem')[0]) * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
