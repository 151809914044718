/**
 * @description
 */
import * as postCompany from './postCompany';
import * as getCompanyById from './getCompanyById';
import * as putCompanyById from './putCompanyById';
import * as postCompanyByIdCompanyExtract from './postCompanyByIdCompanyExtract';

export {
  postCompany,
  getCompanyById,
  putCompanyById,
  postCompanyByIdCompanyExtract,
};
