import FlexBox from '../../FlexBox';
import tokens from '@verifime/design-tokens';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export function LoginStatusMessageDisplay({ ...props }: { children: React.ReactNode }) {
  return (
    <FlexBox gap={tokens.spacingBase}>
      <CircularProgress color="inherit" size={tokens.fontSizeBase} />
      <Typography variant="body1">{props.children}</Typography>
    </FlexBox>
  );
}
