/**
 * @description
 */
import * as getPermission from './getPermission';
import * as postPermission from './postPermission';
import * as getPermissionOrganisationByOrgId from './getPermissionOrganisationByOrgId';
import * as deletePermissionById from './deletePermissionById';

export {
  getPermission,
  postPermission,
  getPermissionOrganisationByOrgId,
  deletePermissionById,
};
