/**
 * @desc undefined
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {}

export const method = 'PUT';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/risk/mitigation/{id}/comment', params, 'PUT'),
    newValue,
    shouldRevalidate,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl(
    '/v1/risk/mitigation/{id}/comment',
    params,
    'PUT',
    option,
  );
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'PUT',
  });
}
