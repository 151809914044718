import { Box, BoxProps, useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { CSSProperties, ReactNode } from 'react';

type TGroupProps = {
  title?: string;
  children: ReactNode;
  titleColor?: string;
  borderColor?: string;
  groupStyle?: CSSProperties;
} & BoxProps;

export default function Group({
  title,
  borderColor,
  titleColor,
  children,
  groupStyle,
  ...props
}: TGroupProps) {
  const theme = useTheme();

  return (
    <fieldset
      style={{
        borderColor: borderColor || theme.palette.secondary.light,
        padding: tokens.spacingXl,
        borderRadius: tokens.spacingLg,
        ...groupStyle,
      }}
    >
      {title && (
        <legend
          style={{
            color: titleColor || theme.palette.primary.light,
            padding: `0 ${tokens.spacingBase}`,
            margin: 'auto',
            textTransform: 'uppercase',
            fontWeight: tokens.fontWeightBolder,
            letterSpacing: tokens.spacing3xs,
          }}
        >
          {title}
        </legend>
      )}

      <Box {...props}>{children}</Box>
    </fieldset>
  );
}
