import CircularProgress from '@mui/material/CircularProgress';
import { LoginCenterContainer, LoginContainer } from '../common/LoginContainer';
import { SecureYourAccountTitle } from '../common/LoginTitle';

export function Loading() {
  return (
    <LoginCenterContainer>
      <CircularProgress />
    </LoginCenterContainer>
  );
}

export function ContainerLoading() {
  return (
    <LoginContainer>
      <SecureYourAccountTitle />
      <Loading />
    </LoginContainer>
  );
}
