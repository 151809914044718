import { Typography, useTheme, SxProps, Box } from '@mui/material';
import tokens from '@verifime/design-tokens';
import FlexBox from '../FlexBox';
import FlexPaper from '../FlexPaper';
import { formatToDDMMMYYYYHHMMAAA } from '@verifime/utils';
import RiskLevelChip from './RiskLevelChip';
import RiskLevelIcon from './RiskLevelIcon';
import NameValueDisplay from '../NameValueDisplay';
import { TRiskLevels } from './types';
import { RISK_LEVEL_CHIP_COLOR_MAPPING } from './constant';
import { getColorByName } from '../ColoredIconAndChip';

export default function Risk({
  riskLevel,
  assessedDate,
  title = 'Current Risk',
  sx,
}: {
  riskLevel: TRiskLevels;
  assessedDate: string;
  title?: string;
  sx?: SxProps;
}) {
  const theme = useTheme();
  const riskColor = getColorByName(RISK_LEVEL_CHIP_COLOR_MAPPING[riskLevel])?.(theme);

  return (
    <FlexPaper
      sx={{
        border: `${tokens.spacing3xs} solid ${riskColor.borderColor || riskColor.fillColor}`,
        padding: tokens.spacingBase,
        gap: tokens.spacingBase,
        ...sx,
      }}
    >
      <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
        <FlexBox sx={{ alignItems: 'center' }}>
          <Typography>{title}</Typography>
          <RiskLevelChip riskLevel={riskLevel} />
        </FlexBox>
        <RiskLevelIcon riskLevel={riskLevel} />
        <Box>
          <NameValueDisplay
            name="Assessed on"
            value={formatToDDMMMYYYYHHMMAAA(assessedDate)}
            gap={tokens.spacing3xs}
          />
        </Box>
      </FlexBox>
    </FlexPaper>
  );
}
