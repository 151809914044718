import * as companyResource from './companyResource';
import * as customerResource from './customerResource';
import * as fileResource from './fileResource';
import * as metaEntityResource from './metaEntityResource';
import * as organisationResource from './organisationResource';
import * as partnershipResource from './partnershipResource';
import * as pepSanctionResource from './pepSanctionResource';
import * as permissionRequestResource from './permissionRequestResource';
import * as permissionResource from './permissionResource';
import * as personResource from './personResource';
import * as riskAssessmentResource from './riskAssessmentResource';
import * as riskAssessmentRuleResource from './riskAssessmentRuleResource';
import * as riskMitigationResource from './riskMitigationResource';
import * as riskMitigationTemplateResource from './riskMitigationTemplateResource';
import * as selfSignUpResource from './selfSignUpResource';
import * as soleTraderResource from './soleTraderResource';
import * as supportDocumentResource from './supportDocumentResource';
import * as trustResource from './trustResource';
import * as userResource from './userResource';
import * as verificationResource from './verificationResource';

export const person = {
  companyResource,
  customerResource,
  fileResource,
  metaEntityResource,
  organisationResource,
  partnershipResource,
  pepSanctionResource,
  permissionRequestResource,
  permissionResource,
  personResource,
  riskAssessmentResource,
  riskAssessmentRuleResource,
  riskMitigationResource,
  riskMitigationTemplateResource,
  selfSignUpResource,
  soleTraderResource,
  supportDocumentResource,
  trustResource,
  userResource,
  verificationResource,
};
