import { TRiskLevels } from './types';
import { TColorName, TIconName } from '../ColoredIconAndChip/constants';
import ColoredIcon from '../ColoredIconAndChip/ColoredIcon';

const RISK_LEVEL_ICON_MAPPING: {
  [riskLevel in TRiskLevels]: { colorName: TColorName; iconName: TIconName };
} = {
  Pending: {
    colorName: 'verificationPending',
    iconName: 'remove',
  },
  Low: {
    colorName: 'successMain',
    iconName: 'checkCircleRounded',
  },
  Medium: {
    colorName: 'warningDark',
    iconName: 'warningRounded',
  },
  High: {
    colorName: 'errorMain',
    iconName: 'cancelRounded',
  },
  Extreme: {
    colorName: 'extreme',
    iconName: 'blockRounded',
  },
};

export default function RiskLevelIcon({
  riskLevel,
  iconSize = 'large',
}: {
  riskLevel: TRiskLevels;
  iconSize?: 'small' | 'inherit' | 'large' | 'medium';
}) {
  const { colorName, iconName } = RISK_LEVEL_ICON_MAPPING[riskLevel] || {};
  return <ColoredIcon colorName={colorName} iconName={iconName} iconSize={iconSize}></ColoredIcon>;
}
