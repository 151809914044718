import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import MoreOptions from './MoreOptions';
import Accessories from './accessories.svg';
import ButtonIcon from './button.svg';
import CameraLevel from './cameraLevel.svg';
import Illumination from './illumination.svg';

export default function StartScreen({
  isEnableOptions = true,
  onButtonClick,
  onSkipConfirm,
}: Readonly<{ isEnableOptions?: boolean; onButtonClick: () => void; onSkipConfirm: () => void }>) {
  return (
    <Paper>
      <Stack
        gap={tokens.spacingBase}
        alignItems="center"
        maxWidth={tokens.sizeSm}
        paddingY={tokens.spacing2lg}
        paddingX={tokens.spacingBase}
      >
        <Typography variant="h5">Ready for a face check?</Typography>
        <Stack gap={tokens.spacingXs} paddingY={0} paddingX={tokens.spacingBase}>
          <Stack
            direction="row"
            gap={tokens.spacingBase}
            alignItems="center"
            marginBottom={tokens.spacingXs}
          >
            <Stack justifyContent="center" alignItems="center" flex={1}>
              <Illumination />
            </Stack>
            <Typography sx={{ flex: 5 }} variant="body1">
              Make sure the lighting is good
            </Typography>
          </Stack>
          <Stack direction="row" gap={tokens.spacingBase} alignItems="center">
            <Box width={tokens.spacing5xl} flex={1}>
              <Accessories />
            </Box>
            <Typography sx={{ flex: 5 }} variant="body1">
              Keep the camera at eye level.
            </Typography>
          </Stack>
          <Stack direction="row" gap={tokens.spacingBase} alignItems="center">
            <Box width={tokens.spacing5xl} flex={1}>
              <CameraLevel />
            </Box>
            <Typography variant="body1" sx={{ flex: 5 }}>
              Avoid wearing accessories like glasses, masks, hats, etc.
            </Typography>
          </Stack>
        </Stack>
        <Button startIcon={<ButtonIcon />} variant="contained" onClick={onButtonClick}>
          Complete Face Check
        </Button>
        {isEnableOptions && <MoreOptions onConfirm={onSkipConfirm} />}
      </Stack>
    </Paper>
  );
}
