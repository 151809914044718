'use client';

import { Box, Button, Divider, Link, Stack, Tooltip, Typography } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCodeRounded';
import OpenInFullIcon from '@mui/icons-material/OpenInFullRounded';
import tokens from '@verifime/design-tokens';
import QRCode from './QRCode';
import React, { ReactNode, useEffect, useState } from 'react';
import * as qrcode from 'qrcode';
import { IconLinkOpenNew } from './IconLinkOpenNew';
import CopyableText from './CopyableText';

export const enum InviteCustomerDisplayVariant {
  QR_CODE = 'QR_CODE',
  TEXT = 'TEXT',
}

export type TShowQrCodeVariantAction = () => void;

export type TInviteCustomerProps = {
  code: string;
  inviteCustomerLinkGenerator: (code: string) => URL;
  variant: InviteCustomerDisplayVariant;
  showQrCodeVariant: TShowQrCodeVariantAction;
};

type TInviteCustomerDisplayProps = {
  inviteCustomerLink: string;
};

type TShowQrCodeVariantProps = {
  showQrCodeVariant: TShowQrCodeVariantAction;
};

function SignUpCodeDisplay({ code }: { code: string }) {
  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      <Typography variant="body1">Your invite code is</Typography>
      <CopyableText textVariant="h3" text={code} />
    </Stack>
  );
}

function SignUpLinkDisplay({
  inviteCustomerLink,
  inviteCustomerLinkText,
  children,
}: {
  children?: ReactNode;
  inviteCustomerLinkText: string;
} & TInviteCustomerDisplayProps) {
  const [copied, setCopied] = useState(false);

  async function copyUrlToClipboard(url: string) {
    if (url && navigator.clipboard) {
      await navigator.clipboard.writeText(url);
      setCopied(true);
    }
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false); // Hide "copied to clipboard" tooltip after 3 seconds
      }, 3000);
    }
  }, [copied]);

  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      {children && (
        <Typography variant="body1" color="text.secondary">
          {children}
        </Typography>
      )}

      <Typography variant="body2" align="center">
        {inviteCustomerLink}
      </Typography>
      <Tooltip
        title="copied to clipboard"
        open={copied}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <Link
          sx={{
            cursor: 'pointer',
          }}
          onClick={async () => copyUrlToClipboard(inviteCustomerLink)}
        >
          {inviteCustomerLinkText}
        </Link>
      </Tooltip>
    </Stack>
  );
}

function QRCodeDisplay({
  inviteCustomerLink,
  showQrCodeVariant,
  showQRCode,
}: { showQRCode: boolean } & TInviteCustomerDisplayProps & TShowQrCodeVariantProps) {
  return (
    <Stack alignItems="center" gap={tokens.spacingBase}>
      {showQRCode ? (
        <QRCode value={inviteCustomerLink} size="medium" />
      ) : (
        <Button
          size="medium"
          variant="outlined"
          startIcon={<QrCodeIcon />}
          endIcon={<OpenInFullIcon />}
          fullWidth={true}
          onClick={showQrCodeVariant}
        >
          Show QR Code
        </Button>
      )}
    </Stack>
  );
}

function InviteCustomerTextQRCodeVariantDisplay({
  inviteCustomerLink,
  code,
  showQrCodeVariant,
}: {
  code: string;
} & TInviteCustomerDisplayProps &
  TShowQrCodeVariantProps) {
  return (
    <Stack gap={tokens.spacingXl}>
      <Stack gap={tokens.spacingXs}>
        <SignUpCodeDisplay code={code} />
        <QRCodeDisplay
          showQRCode
          inviteCustomerLink={inviteCustomerLink}
          showQrCodeVariant={showQrCodeVariant}
        />
      </Stack>
      <SignUpLinkDisplay
        inviteCustomerLink={inviteCustomerLink}
        inviteCustomerLinkText="Can't scan code? Click to copy Link"
      />
    </Stack>
  );
}

function InviteCustomerTextTextVariantDisplay({
  inviteCustomerLink,
  code,
  showQrCodeVariant,
}: {
  code: string;
} & TInviteCustomerDisplayProps &
  TShowQrCodeVariantProps) {
  return (
    <Stack gap={tokens.spacingXl}>
      <Stack gap={tokens.spacingXs}>
        <SignUpCodeDisplay code={code} />
        <SignUpLinkDisplay
          inviteCustomerLink={inviteCustomerLink}
          inviteCustomerLinkText="Copy Link"
        />
      </Stack>
      <QRCodeDisplay
        showQRCode={false}
        inviteCustomerLink={inviteCustomerLink}
        showQrCodeVariant={showQrCodeVariant}
      />
    </Stack>
  );
}

function InviteLinkQRCodeDownloadLink({ inviteCustomerLink }: TInviteCustomerDisplayProps) {
  const [qrCodeData, setQrCodeData] = React.useState<string | null>(null);
  React.useEffect(() => {
    qrcode
      .toDataURL(inviteCustomerLink, {
        type: 'image/jpeg',
        width: 250,
      })
      .then(setQrCodeData);
  }, [inviteCustomerLink]);

  return (
    <>
      {qrCodeData && (
        <Link download="QR.jpg" href={qrCodeData} target="_blank">
          Download QR code as image
        </Link>
      )}
    </>
  );
}

function InviteCustomerCode({
  variant,
  code,
  inviteCustomerLinkGenerator,
  showQrCodeVariant,
}: {
  variant: InviteCustomerDisplayVariant;
} & Omit<TInviteCustomerProps, 'qrCodeDownloadLink'> &
  TShowQrCodeVariantProps) {
  const inviteCustomerLinkUrl = inviteCustomerLinkGenerator(code).href;
  const inviteCustomerTextDisplayProps = {
    code,
    inviteCustomerLink: inviteCustomerLinkUrl,
    showQrCodeVariant,
  };

  return (
    <Stack alignItems="center" gap={tokens.spacingXl}>
      <Stack alignItems="center" gap={tokens.spacingXl}>
        {variant === InviteCustomerDisplayVariant.QR_CODE && (
          <InviteCustomerTextQRCodeVariantDisplay {...inviteCustomerTextDisplayProps} />
        )}
        {variant === InviteCustomerDisplayVariant.TEXT && (
          <InviteCustomerTextTextVariantDisplay {...inviteCustomerTextDisplayProps} />
        )}
        <InviteLinkQRCodeDownloadLink inviteCustomerLink={inviteCustomerLinkUrl} />
      </Stack>
      <Divider flexItem />
      <IconLinkOpenNew href="https://www.verifime.com/clientcustomeronboarding">
        Learn more about onboarding
      </IconLinkOpenNew>
    </Stack>
  );
}

export default function InviteCustomer({
  variant,
  showQrCodeVariant,
  ...props
}: TInviteCustomerProps) {
  return (
    <Box alignItems="center" gap={tokens.spacingXl}>
      <InviteCustomerCode showQrCodeVariant={showQrCodeVariant} variant={variant} {...props} />
    </Box>
  );
}
