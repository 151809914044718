import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { formFieldLabel } from './utils';

type TFormDateProps = {
  name: string;
  label: string;
  control: Control<FieldValues, any>;
  inputFormat?: string;
  required?: boolean;
  defaultValue?: string;
  maxDate?: Date;
  minDate?: Date;
  disableFuture?: boolean;
  disablePast?: boolean;
  margin?: TextFieldProps['margin'];
  size?: TextFieldProps['size'];
  onDateChange?: (date: Date) => void;
} & TextFieldProps;

export default function FormDate({
  name,
  label,
  control,
  inputFormat = 'dd/MM/yyyy',
  required = false,
  defaultValue = '',
  maxDate,
  minDate,
  disableFuture,
  disablePast,
  onDateChange,
  margin = 'normal',
  size = 'medium',
  ...props
}: TFormDateProps) {
  const requiredLabel = formFieldLabel(label, required);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...field}
            label={requiredLabel}
            inputFormat={inputFormat}
            maxDate={maxDate}
            minDate={minDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
            renderInput={(params: any) => (
              <TextField
                {...params}
                {...props}
                margin={margin}
                size={size}
                // To make sure sx not to be override, just spared outer sx into it
                sx={{
                  flex: '1',
                  width: '100%',
                  ...props.sx,
                }}
                // We do not wanna those two to be override
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
            // Validation is not fired with the default react-hook-form mode. So we need this custom onChange event handling.
            onChange={(date) => {
              field.onChange(date);
              onDateChange?.(date);
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}
