import { MenuItem } from '@mui/material';
import {
  COUNTRY_STATES,
  EXCHANGE_LISTING_TYPE_OPTIONS,
  IdentityTypePartnership,
  IdentityTypePrivateCompany,
  IdentityTypePublicCompany,
  IdentityTypeRegulatedTrust,
  IdentityTypeSMSF,
  IdentityTypeSoleTrader,
  IdentityTypeUnregulatedTrust,
  RegulatedTrustType,
  SUPPORTED_COUNTRY_MAP,
  TCountry,
  TCountryCode,
  TIdentityTypeCode,
  UnregulatedTrustType,
  addressFieldsPrefix,
  basicFieldsPrefix,
  enumToOptions,
  principalPlaceOfBusinessFieldsPrefix,
  registeredOfficeFieldsPrefix,
  validationRules,
} from '@verifime/utils';
import { subDays } from 'date-fns';
import { RenderType, TFormFieldsAndRules_New, TFromFieldInfo } from '../Form/utils';
import { TAddEntityAddresses } from './useAddEntity';

export type TWizardOrganisationFormFieldsAndRules = {
  [identityType in TIdentityTypeCode]?: {
    basic: TFormFieldsAndRules_New;
    addresses: TAddEntityAddresses;
  };
};

export const AVAILABLE_COUNTRIES = [
  SUPPORTED_COUNTRY_MAP.AU,
  SUPPORTED_COUNTRY_MAP.HK,
  SUPPORTED_COUNTRY_MAP.NZ,
  SUPPORTED_COUNTRY_MAP.SG,
  SUPPORTED_COUNTRY_MAP.GB,
];

export const individualFormFieldsAndRules: TFormFieldsAndRules_New = {
  row1: [
    {
      label: 'Given Name',
      fieldName: basicFieldsPrefix + 'FirstName',
      rules: validationRules.REQUIRED_FULL_NAME_VALIDATION_RULES,
      renderType: RenderType.Text,
    },
    {
      label: 'Middle Name',
      fieldName: basicFieldsPrefix + 'MiddleName',
      rules: validationRules.OPTIONAL_FULL_NAME_VALIDATION_RULES,
      renderType: RenderType.Text,
    },
    {
      label: 'Family Name',
      fieldName: basicFieldsPrefix + 'FamilyName',
      rules: validationRules.REQUIRED_FULL_NAME_VALIDATION_RULES,
      renderType: RenderType.Text,
    },
  ],
  row2: [
    {
      label: 'Date of Birth',
      fieldName: basicFieldsPrefix + 'DateOfBirth',
      rules: validationRules.REQUIRED_DATE_OF_BIRTH_VALIDATION_RULES,
      renderType: RenderType.Date,
      minDate: subDays(new Date(), validationRules.MIN_BIRTH_OF_DATE_GAP),
      maxDate: subDays(new Date(), 1),
    },
  ],
};

const generateUnitNumberField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Unit Number',
  fieldName: addressFieldsPrefix + 'UnitNumber',
  rules: validationRules.OPTIONAL_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-unit-number',
  isNewLine: true,
  showRequiredAsterisk: false,
});

const generateStreetNumberField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Street Number',
  fieldName: addressFieldsPrefix + 'StreetNumber',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-street-number',
  isNewLine: false,
  showRequiredAsterisk: true,
});

const generateStreetNameField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Street Name',
  fieldName: addressFieldsPrefix + 'StreetName',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-street-name',
  isNewLine: true,
  showRequiredAsterisk: true,
});

const generateStreetTypeField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Street Type',
  fieldName: addressFieldsPrefix + 'StreetType',
  rules: validationRules.OPTIONAL_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-street-type',
  isNewLine: false,
  showRequiredAsterisk: false,
});
const generateCityField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'City / Locality',
  fieldName: addressFieldsPrefix + 'City',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-city',
  isNewLine: false,
  showRequiredAsterisk: true,
});
const generateSuburbField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Suburb',
  fieldName: addressFieldsPrefix + 'Suburb',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-suburb',
  isNewLine: true,
  showRequiredAsterisk: true,
});
const generateStateField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'State',
  fieldName: addressFieldsPrefix + 'State',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Select,
  items: COUNTRY_STATES.AUS,
  dataCy: 'select-state',
  isNewLine: true,
  showRequiredAsterisk: true,
});

const generatePostcodeField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Postcode',
  fieldName: addressFieldsPrefix + 'Postcode',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Text,
  dataCy: 'text-postcode',
  isNewLine: false,
  showRequiredAsterisk: true,
});
const generateCountryField = (addressFieldsPrefix: string): TFromFieldInfo => ({
  label: 'Country of Residence',
  fieldName: addressFieldsPrefix + 'CountryCode',
  rules: validationRules.OPTIONAL_STRING_RULES,
  renderItems: () => {
    return AVAILABLE_COUNTRIES.map((option: TCountry) => (
      <MenuItem
        key={option.code}
        value={option.code}
        data-cy={`select-country-option-${option.code}`}
      >{`${option.label}`}</MenuItem>
    ));
  },
  dataCy: 'select-country',
});

export enum AddressFieldsAndRulesName {
  addressFieldsAndRules = 'addressFieldsAndRules',
  registeredOfficeFieldsAndRules = 'registeredOfficeFieldsAndRules',
  principalPlaceOfBusinessFieldsAndRules = 'principalPlaceOfBusinessFieldsAndRules',
}

export const COUNTRY_ADDRESS_FIELDS_AND_RULES: {
  [countryCode in TCountryCode]?: {
    [addressFieldsAndRulesName in AddressFieldsAndRulesName]?: {
      [addressField: string]: TFromFieldInfo;
    };
  };
} = {
  [SUPPORTED_COUNTRY_MAP.AU.code]: {
    addressFieldsAndRules: {
      unitNumber: generateUnitNumberField(addressFieldsPrefix),
      streetNumber: generateStreetNumberField(addressFieldsPrefix),
      streetName: generateStreetNameField(addressFieldsPrefix),
      streetType: generateStreetTypeField(addressFieldsPrefix),
      suburb: generateSuburbField(addressFieldsPrefix),
      state: generateStateField(addressFieldsPrefix),
      postcode: generatePostcodeField(addressFieldsPrefix),
      countryCode: generateCountryField(addressFieldsPrefix),
    },
    registeredOfficeFieldsAndRules: {
      unitNumber: generateUnitNumberField(registeredOfficeFieldsPrefix),
      streetNumber: generateStreetNumberField(registeredOfficeFieldsPrefix),
      streetName: generateStreetNameField(registeredOfficeFieldsPrefix),
      streetType: generateStreetTypeField(registeredOfficeFieldsPrefix),
      suburb: generateSuburbField(registeredOfficeFieldsPrefix),
      state: generateStateField(registeredOfficeFieldsPrefix),
      postcode: generatePostcodeField(registeredOfficeFieldsPrefix),
      countryCode: generateCountryField(registeredOfficeFieldsPrefix),
    },
    principalPlaceOfBusinessFieldsAndRules: {
      unitNumber: generateUnitNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetNumber: generateStreetNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetName: generateStreetNameField(principalPlaceOfBusinessFieldsPrefix),
      streetType: generateStreetTypeField(principalPlaceOfBusinessFieldsPrefix),
      suburb: generateSuburbField(principalPlaceOfBusinessFieldsPrefix),
      state: generateStateField(principalPlaceOfBusinessFieldsPrefix),
      postcode: generatePostcodeField(principalPlaceOfBusinessFieldsPrefix),
      countryCode: generateCountryField(principalPlaceOfBusinessFieldsPrefix),
    },
  },
  [SUPPORTED_COUNTRY_MAP.HK.code]: {
    addressFieldsAndRules: {
      unitNumber: generateUnitNumberField(addressFieldsPrefix),
      streetNumber: generateStreetNumberField(addressFieldsPrefix),
      streetName: generateStreetNameField(addressFieldsPrefix),
      streetType: generateStreetTypeField(addressFieldsPrefix),
      suburb: generateSuburbField(addressFieldsPrefix),
      countryCode: generateCountryField(addressFieldsPrefix),
    },
    registeredOfficeFieldsAndRules: {
      unitNumber: generateUnitNumberField(registeredOfficeFieldsPrefix),
      streetNumber: generateStreetNumberField(registeredOfficeFieldsPrefix),
      streetName: generateStreetNameField(registeredOfficeFieldsPrefix),
      streetType: generateStreetTypeField(registeredOfficeFieldsPrefix),
      suburb: generateSuburbField(registeredOfficeFieldsPrefix),
      countryCode: generateCountryField(registeredOfficeFieldsPrefix),
    },
    principalPlaceOfBusinessFieldsAndRules: {
      unitNumber: generateUnitNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetNumber: generateStreetNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetName: generateStreetNameField(principalPlaceOfBusinessFieldsPrefix),
      streetType: generateStreetTypeField(principalPlaceOfBusinessFieldsPrefix),
      suburb: generateSuburbField(principalPlaceOfBusinessFieldsPrefix),
      countryCode: generateCountryField(principalPlaceOfBusinessFieldsPrefix),
    },
  },
  [SUPPORTED_COUNTRY_MAP.NZ.code]: {
    addressFieldsAndRules: {
      unitNumber: generateUnitNumberField(addressFieldsPrefix),
      streetNumber: generateStreetNumberField(addressFieldsPrefix),
      streetName: generateStreetNameField(addressFieldsPrefix),
      streetType: generateStreetTypeField(addressFieldsPrefix),
      suburb: generateSuburbField(addressFieldsPrefix),
      postcode: generatePostcodeField(addressFieldsPrefix),
      countryCode: generateCountryField(addressFieldsPrefix),
    },
    registeredOfficeFieldsAndRules: {
      unitNumber: generateUnitNumberField(registeredOfficeFieldsPrefix),
      streetNumber: generateStreetNumberField(registeredOfficeFieldsPrefix),
      streetName: generateStreetNameField(registeredOfficeFieldsPrefix),
      streetType: generateStreetTypeField(registeredOfficeFieldsPrefix),
      suburb: generateSuburbField(registeredOfficeFieldsPrefix),
      postcode: generatePostcodeField(registeredOfficeFieldsPrefix),
      countryCode: generateCountryField(registeredOfficeFieldsPrefix),
    },
    principalPlaceOfBusinessFieldsAndRules: {
      unitNumber: generateUnitNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetNumber: generateStreetNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetName: generateStreetNameField(principalPlaceOfBusinessFieldsPrefix),
      streetType: generateStreetTypeField(principalPlaceOfBusinessFieldsPrefix),
      suburb: generateSuburbField(principalPlaceOfBusinessFieldsPrefix),
      postcode: generatePostcodeField(principalPlaceOfBusinessFieldsPrefix),
      countryCode: generateCountryField(principalPlaceOfBusinessFieldsPrefix),
    },
  },
  [SUPPORTED_COUNTRY_MAP.SG.code]: {
    addressFieldsAndRules: {
      unitNumber: generateUnitNumberField(addressFieldsPrefix),
      streetNumber: generateStreetNumberField(addressFieldsPrefix),
      streetName: generateStreetNameField(addressFieldsPrefix),
      streetType: generateStreetTypeField(addressFieldsPrefix),
      suburb: generateSuburbField(addressFieldsPrefix),
      postcode: generatePostcodeField(addressFieldsPrefix),
      countryCode: generateCountryField(addressFieldsPrefix),
    },
    registeredOfficeFieldsAndRules: {
      unitNumber: generateUnitNumberField(registeredOfficeFieldsPrefix),
      streetNumber: generateStreetNumberField(registeredOfficeFieldsPrefix),
      streetName: generateStreetNameField(registeredOfficeFieldsPrefix),
      streetType: generateStreetTypeField(registeredOfficeFieldsPrefix),
      suburb: generateSuburbField(registeredOfficeFieldsPrefix),
      postcode: generatePostcodeField(registeredOfficeFieldsPrefix),
      countryCode: generateCountryField(registeredOfficeFieldsPrefix),
    },
    principalPlaceOfBusinessFieldsAndRules: {
      unitNumber: generateUnitNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetNumber: generateStreetNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetName: generateStreetNameField(principalPlaceOfBusinessFieldsPrefix),
      streetType: generateStreetTypeField(principalPlaceOfBusinessFieldsPrefix),
      suburb: generateSuburbField(principalPlaceOfBusinessFieldsPrefix),
      postcode: generatePostcodeField(principalPlaceOfBusinessFieldsPrefix),
      countryCode: generateCountryField(principalPlaceOfBusinessFieldsPrefix),
    },
  },
  [SUPPORTED_COUNTRY_MAP.GB.code]: {
    addressFieldsAndRules: {
      unitNumber: generateUnitNumberField(addressFieldsPrefix),
      streetNumber: generateStreetNumberField(addressFieldsPrefix),
      streetName: generateStreetNameField(addressFieldsPrefix),
      streetType: generateStreetTypeField(addressFieldsPrefix),
      suburb: generateSuburbField(addressFieldsPrefix),
      city: generateCityField(addressFieldsPrefix),
      postcode: generatePostcodeField(addressFieldsPrefix),
      countryCode: generateCountryField(addressFieldsPrefix),
    },
    registeredOfficeFieldsAndRules: {
      unitNumber: generateUnitNumberField(registeredOfficeFieldsPrefix),
      streetNumber: generateStreetNumberField(registeredOfficeFieldsPrefix),
      streetName: generateStreetNameField(registeredOfficeFieldsPrefix),
      streetType: generateStreetTypeField(registeredOfficeFieldsPrefix),
      suburb: generateSuburbField(registeredOfficeFieldsPrefix),
      city: generateCityField(addressFieldsPrefix),
      postcode: generatePostcodeField(registeredOfficeFieldsPrefix),
      countryCode: generateCountryField(registeredOfficeFieldsPrefix),
    },
    principalPlaceOfBusinessFieldsAndRules: {
      unitNumber: generateUnitNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetNumber: generateStreetNumberField(principalPlaceOfBusinessFieldsPrefix),
      streetName: generateStreetNameField(principalPlaceOfBusinessFieldsPrefix),
      streetType: generateStreetTypeField(principalPlaceOfBusinessFieldsPrefix),
      suburb: generateSuburbField(principalPlaceOfBusinessFieldsPrefix),
      city: generateCityField(addressFieldsPrefix),
      postcode: generatePostcodeField(principalPlaceOfBusinessFieldsPrefix),
      countryCode: generateCountryField(principalPlaceOfBusinessFieldsPrefix),
    },
  },
};

const registeredAddressLabel = 'Registered address';
const principalPlaceOfBusinessLabel = 'Principal place of business';

const country = {
  label: 'Country',
  fieldName: basicFieldsPrefix + 'CountryCode',
  rules: validationRules.REQUIRED_STRING_RULES,
  renderType: RenderType.Select,
  // We only support AU for org wizards for now
  items: [SUPPORTED_COUNTRY_MAP.AU],
};

const abnFields = {
  label: 'ABN',
  fieldName: basicFieldsPrefix + 'BusinessNumber',
  renderType: RenderType.Text,
};

const abn = {
  ...abnFields,
  rules: validationRules.requiredNumberRule({ min: 11, max: 11 }),
};

const optionalAbn = {
  ...abnFields,
  rules: validationRules.optionalNumberRule({ min: 11, max: 11 }),
};

const acn = {
  label: 'ACN',
  fieldName: basicFieldsPrefix + 'CompanyNumber',
  rules: validationRules.requiredNumberRule({ min: 9, max: 9 }),
  renderType: RenderType.Text,
};

const address = {
  renderType: 'AddressOnly',
  fieldsAndRulesName: AddressFieldsAndRulesName.addressFieldsAndRules,
  label: 'Address',
  fieldsPrefix: addressFieldsPrefix,
};

const principalPlaceOfBusiness = {
  renderType: 'AddressOnly',
  fieldsAndRulesName: AddressFieldsAndRulesName.principalPlaceOfBusinessFieldsAndRules,
  label: principalPlaceOfBusinessLabel,
  fieldsPrefix: principalPlaceOfBusinessFieldsPrefix,
};

export const wizardOrganisationFormFieldsAndRules: TWizardOrganisationFormFieldsAndRules = {
  [IdentityTypeSMSF.code]: {
    basic: {
      row1: [
        {
          label: 'SMSF Name',
          fieldName: basicFieldsPrefix + 'TrustName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [abn],
    },
    addresses: [
      {
        renderType: 'AddressOnly',
        fieldsAndRulesName: AddressFieldsAndRulesName.addressFieldsAndRules,
        label: 'Registered Address',
        fieldsPrefix: addressFieldsPrefix,
      },
    ],
  },
  [IdentityTypePrivateCompany.code]: {
    basic: {
      row1: [
        {
          label: 'Company Name',
          fieldName: basicFieldsPrefix + 'CompanyName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [acn],
    },
    addresses: [
      {
        renderType: 'AddressOnly',
        fieldsAndRulesName: AddressFieldsAndRulesName.registeredOfficeFieldsAndRules,
        label: registeredAddressLabel,
        fieldsPrefix: registeredOfficeFieldsPrefix,
      },
      {
        ...principalPlaceOfBusiness,
        sameAsAddress: {
          addressFieldsAndRulesName: AddressFieldsAndRulesName.registeredOfficeFieldsAndRules,
          label: registeredAddressLabel,
        },
      },
    ],
  },
  [IdentityTypePublicCompany.code]: {
    basic: {
      row1: [
        {
          label: 'Company Name',
          fieldName: basicFieldsPrefix + 'CompanyName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [acn],
      row4: [
        {
          label: 'Exchange listed on',
          fieldName: basicFieldsPrefix + 'ListedExchange',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Select,
          items: EXCHANGE_LISTING_TYPE_OPTIONS,
        },
      ],
      row5: [
        {
          label: 'Ticker',
          fieldName: basicFieldsPrefix + 'Ticker',
          rules: validationRules.OPTIONAL_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
    },
  },
  [IdentityTypeRegulatedTrust.code]: {
    basic: {
      row1: [
        {
          label: 'Trust Name',
          fieldName: basicFieldsPrefix + 'TrustName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [
        {
          label: 'Regulated trust type',
          fieldName: basicFieldsPrefix + 'TrustType',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Select,
          items: enumToOptions(RegulatedTrustType),
        },
      ],
      row4: [
        {
          label: 'ARSN/ABN',
          fieldName: basicFieldsPrefix + 'BusinessNumber',
          rules: validationRules.requiredNumberRule({ min: 9, max: 11 }),
          renderType: RenderType.Text,
        },
      ],
    },
    addresses: [address],
  },
  [IdentityTypeUnregulatedTrust.code]: {
    basic: {
      row1: [
        {
          label: 'Trust Name',
          fieldName: basicFieldsPrefix + 'TrustName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [
        {
          label: 'Unregulated trust type',
          fieldName: basicFieldsPrefix + 'TrustType',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Select,
          items: enumToOptions(UnregulatedTrustType),
        },
      ],
      row4: [optionalAbn],
    },
    addresses: [address],
  },
  [IdentityTypeSoleTrader.code]: {
    basic: {
      row1: [
        {
          label: 'Business Name',
          fieldName: basicFieldsPrefix + 'SoleTraderName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [abn],
    },
    addresses: [principalPlaceOfBusiness],
  },
  [IdentityTypePartnership.code]: {
    basic: {
      row1: [
        {
          label: 'Partnership Name',
          fieldName: basicFieldsPrefix + 'PartnershipName',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Text,
        },
      ],
      row2: [country],
      row3: [abn],
    },
    addresses: [principalPlaceOfBusiness],
  },
};
