/**
 * @desc Dismiss the permission request
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** id */
  id;
}

export const method = 'DELETE';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/permission/request/{id}', params, 'DELETE'),
    newValue,
    shouldRevalidate,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl(
    '/v1/permission/request/{id}',
    params,
    'DELETE',
    option,
  );
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'DELETE',
  });
}
