import { useCallback, useEffect, useState } from 'react';
import { DocumentType } from '@verifime/utils';
import {
  TExistingUploadedFile,
  TFileToDelete,
  TFileUploadResult,
  TFileUploadStatus,
  TFileUploadUrl,
  TFilesUploadStatus,
  MultiFileUploader,
} from '@verifime/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FileType } from '@verifime/utils';
import React from 'react';
import { TFileItemPageResponse, TPresignResponse } from '@verifime/api-definition';

export type DocumentUploaderServiceCallsProps = {
  loadExistingUploadedFiles: (entityId: string, docType: DocumentType, signal?: AbortSignal) => Promise<TFileItemPageResponse>;
    handleRetrieveFileUploadUrls: (entityId: string, documentType: DocumentType, filenameList: string[]) => Promise<TPresignResponse>;
    handleUploadFile: (fileUploadStatus: TFileUploadStatus) => Promise<Response>;
    handleDeleteFile: (entityId: string, docType: DocumentType, fileId: string) => Promise<void>;
};

export type TUseDocumentUploaderProps = {
  entityId: string;
  documentType: DocumentType;
  label?: string;
  isReadonly?: boolean;
  isMultiple?: boolean;
  maxFileSizeInMB?: number;
  isDraggable?: boolean;
  ignoreExistingFiles?: boolean;
  onChange?: (filesUploadStatus: TFileUploadStatus[]) => void;
  onAllUploadsDone?: (results: TFilesUploadStatus) => void;
  onFileDeleted?: (fileId: string, existingUploadedFiles?: TExistingUploadedFile[]) => void;
  onUploadedFilesLoadOrChange?: (existingUploadedFiles: TExistingUploadedFile[]) => void;
  api: DocumentUploaderServiceCallsProps;
};

export default function useDocumentUploader({
  entityId,
  documentType,
  label = 'ADD DOCUMENT',
  isReadonly = false,
  isMultiple = true,
  maxFileSizeInMB = 100,
  isDraggable = false,
  ignoreExistingFiles = false,
  onChange,
  onAllUploadsDone,
  onFileDeleted,
  onUploadedFilesLoadOrChange,
  api,
}: TUseDocumentUploaderProps) {
  const [fileToDelete, setFileToDelete] = useState<TFileToDelete | null>(null);
  const [isShowDialog, setShowDialog] = useState(false);
  const [existingUploadedFiles, setExistingUploadedFiles] = useState<TExistingUploadedFile[]>([]);

  const loadExistingUploadedFiles = useCallback(
    async (signal?: AbortSignal) => {
      if (ignoreExistingFiles) {
        return;
      }
      try {
        const fileItemPageResponse = await api.loadExistingUploadedFiles(
          entityId,
          documentType,
          signal,
        );

        if (fileItemPageResponse && fileItemPageResponse.itemList) {
          const updatedExistingUploadedFiles = fileItemPageResponse.itemList
            .sort((existingUploadedFileA, existingUploadedFileB) => {
              return (
                new Date(existingUploadedFileB.lastModified).getTime() -
                new Date(existingUploadedFileA.lastModified).getTime()
              );
            })
            .map((existingUploadedFile) => {
              return {
                fileName: existingUploadedFile.filename,
                fileId: existingUploadedFile.fileId,
                fileSizeInBytes: existingUploadedFile.sizeInBytes,
              };
            });

          setExistingUploadedFiles(updatedExistingUploadedFiles);
        }
      } catch (error) { }
    },
    [entityId, documentType, api, ignoreExistingFiles],
  );

  useEffect(() => {
    const abortController = new AbortController();
    loadExistingUploadedFiles(abortController.signal);

    return () => abortController.abort();
  }, [loadExistingUploadedFiles]);

  useEffect(() => {
    onUploadedFilesLoadOrChange?.(existingUploadedFiles ?? []);
  }, [existingUploadedFiles, onUploadedFilesLoadOrChange]);

  const handleRetrieveFileUploadUrls = async (files: File[]): Promise<TFileUploadUrl[]> => {
    try {
      const presignResponse = await api.handleRetrieveFileUploadUrls(
        entityId,
        documentType,
        files.map((file) => file.name),
      );

      return presignResponse.presignUrlList.map((presignUrl) => ({
        fileName: presignUrl.filename,
        fileId: presignUrl.fileId,
        uploadUrl: presignUrl.url,
      }));
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const handleUploadFile = async (fileUploadStatus: TFileUploadStatus): Promise<TFileUploadResult> => {
    try {
      const res = await api.handleUploadFile(fileUploadStatus);

      if (res.status === 200) {
        await loadExistingUploadedFiles();
        return {
          fileUploadUrl: res.url,
          fileId: fileUploadStatus.fileId,
          isSuccess: true,
          isRemoveFileUploadResult: !ignoreExistingFiles,
        };
      }

      return {
        fileUploadUrl: fileUploadStatus.fileUploadUrl,
        fileId: fileUploadStatus.fileId,
        isSuccess: false,
      };
    } catch (err) {
      return {
        fileUploadUrl: fileUploadStatus.fileUploadUrl,
        fileId: fileUploadStatus.fileId,
        isSuccess: false,
      };
    }
  };

  const wrappedHandleDeleteFile = async () => {
    if (!fileToDelete) return;

    try {
      await api.handleDeleteFile(
        entityId,
        documentType,
        fileToDelete.fileId,
      );
      if (!ignoreExistingFiles) {
        await loadExistingUploadedFiles();
      }
      if (typeof onFileDeleted === 'function') {
        onFileDeleted(fileToDelete.fileId, existingUploadedFiles);
      }
      setFileToDelete(null);
      setShowDialog(false);
    } catch (error) {
      if (!ignoreExistingFiles) {
        setExistingUploadedFiles(
          existingUploadedFiles.map((existingUploadedFile) => {
            if (existingUploadedFile.fileId === fileToDelete.fileId) {
              return {
                ...existingUploadedFile,
                error: 'Failed to delete file, please contact support@verifime.com for help.',
              };
            }
            return { ...existingUploadedFile };
          }),
        );
      }
      setFileToDelete(null);
      setShowDialog(false);
    }
  };

  const renderedComponent = (
    <>
      <MultiFileUploader
        label={label}
        fileTypes={[FileType.PNG, FileType.JPG, FileType.JPEG, FileType.PDF]}
        maxFileSizeInMB={maxFileSizeInMB}
        existingUploadedFiles={existingUploadedFiles}
        isDetectSameFileSelection
        isMultiple={isMultiple}
        onRetrieveFileUploadUrls={handleRetrieveFileUploadUrls}
        onUploadFile={handleUploadFile}
        onDeleteFile={(fileToDelete) => {
          setFileToDelete(fileToDelete);
          setShowDialog(true);
        }}
        onChange={(filesUploadStatus) => {
          if (typeof onChange === 'function') {
            onChange(filesUploadStatus);
          }
        }}
        onAllUploadsDone={(filesUploadStatus) => {
          if (typeof onAllUploadsDone === 'function') {
            onAllUploadsDone(filesUploadStatus);
          }
        }}
        isReadonly={isReadonly}
        isDraggable={isDraggable}
      />

      {/* Will move the below dialog to the MultiFileUploader component */}
      <Dialog open={isShowDialog}>
        <DialogTitle>Deleting {fileToDelete?.fileName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {fileToDelete?.fileName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} autoFocus>
            CANCEL
          </Button>
          <Button color="error" onClick={wrappedHandleDeleteFile}>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return {
    fileToDelete,
    isShowDialog,
    existingUploadedFiles,
    setFileToDelete,
    setShowDialog,
    renderedComponent,
  };
};
