import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import Alert from './Alert';

export type TConfirmation = {
  title: string;
  subtitle?: string;
  confirmTitle: string;
  confirmText: string;
  confirmButtonText: string;
  confirmAction: () => void;
};

export const Confirmation = ({
  title,
  subtitle,
  confirmButtonText,
  confirmAction,
  confirmTitle,
  confirmText,
}: TConfirmation) => {
  return (
    <Stack gap={tokens.spacingLg}>
      <Stack gap={tokens.spacing2xs}>
        <Typography variant="h5" sx={{ mt: tokens.spacingXs }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Alert severity="success" title={confirmTitle}>
        {confirmText}
      </Alert>
      <Button variant="contained" type="button" onClick={confirmAction}>
        {confirmButtonText}
      </Button>
    </Stack>
  );
};

export default Confirmation;
