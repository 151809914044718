import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import { IdentityStatus, IdentityType } from './types';
import IdentityStatusChip from './IdentityStatusChip';
import IdentityAvatarIcon from './IdentityAvatarIcon';
import { ReactNode, CSSProperties } from 'react';

export interface IdentityCardProps {
  status: IdentityStatus;
  type: IdentityType;
  name: string;
  onClick: () => void;
  actions?: ReactNode;
  width?: CSSProperties['width'];
}

export default function IdentityCard({
  status,
  type,
  name,
  onClick,
  actions,
  width,
}: IdentityCardProps) {
  return (
    <Paper
      sx={{
        width: width ?? tokens.sizeXs,
        borderRadius: tokens.borderRadiusLg,
        padding: tokens.spacingSm,
        gap: tokens.spacingBase,
      }}
      elevation={4}
    >
      <Stack
        gap={tokens.spacingBase}
        sx={{
          cursor: 'pointer',
          height: '100%',
        }}
        onClick={onClick}
      >
        <IdentityStatusChip status={status} />
        <Stack direction="row" gap={tokens.spacingBase} sx={{ flex: '1' }}>
          <IdentityAvatarIcon type={type} />
          <Box gap={tokens.spacingXs}>
            <Typography
              variant="overline"
              sx={{
                textTransform: 'uppercase',
              }}
            >
              {type}
            </Typography>
            <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
              {name}
            </Typography>
          </Box>
        </Stack>
        <Box onClick={(e) => e.stopPropagation()}>{actions}</Box>
      </Stack>
    </Paper>
  );
}
