import { TextFieldProps } from '@mui/material/TextField';
import { Control, FieldError } from 'react-hook-form';
import FormTextInput from './FormTextInput';

type FormNumberInputProps = {
  name: string;
  control: Control;
  label: string;
  rules?: object;
  error?: FieldError;
  onNumberChange?: (text: string) => void;
  margin?: TextFieldProps['margin'];
  size?: TextFieldProps['size'];
} & TextFieldProps;

const FormNumberInput = ({
  name,
  control,
  label,
  rules,
  error,
  margin = 'normal',
  size = 'medium',
  onNumberChange,
  ...props
}: FormNumberInputProps) => {
  return (
    <FormTextInput
      InputLabelProps={{ shrink: true }}
      name={name}
      label={label}
      type="number"
      margin={margin}
      fullWidth
      size={size}
      control={control}
      error={error}
      onTextChange={onNumberChange}
      {...props}
    />
  );
};

export default FormNumberInput;
