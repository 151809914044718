import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import tokens from '@verifime/design-tokens';

import { ColoredIcon, DateDisplay, IconLinkOpenNew, useConfirm } from '../index';
import { TStoredCredential } from './types';
import React from 'react';
import { FidoSetupErrorMessage } from './common/LoginAlert';
import { LEARN_MORE_PASSKEY_URL } from '@verifime/utils';

export type PasskeySettingsProps = {
  credentials: TStoredCredential[];
  handleRename?: (index: number, newName: string) => Promise<void>;
  handleRemove?: (index: number) => Promise<void>;
  handleAddNew?: (newName: () => Promise<string>) => Promise<void>;
  openSetupDialog?: boolean;
};

type PasskeySettingProps = {
  credential: TStoredCredential;
  handleRename?: () => void;
  handleRemove?: () => void;
};

function PasskeySettingsHeading() {
  return (
    <Stack gap={tokens.spacing2xs}>
      <Typography variant="h6">Manage passkeys</Typography>
      <Typography variant="subtitle1">
        There's no need to remember another complex password. The fingerprint, PIN or facial
        recognition you use to sign in on this device can also help you sign into your VerifiMe
        account. Add your device as a passkey now.
      </Typography>
      <Box alignSelf="flex-start">
        <IconLinkOpenNew href={LEARN_MORE_PASSKEY_URL}>Learn more</IconLinkOpenNew>
      </Box>
    </Stack>
  );
}

function PasskeySetting({ credential, handleRename, handleRemove }: PasskeySettingProps) {
  const { friendlyName, createdAt, lastSignIn } = credential;
  return (
    <Paper
      sx={{
        p: `${tokens.spacingBase} ${tokens.spacingXs}`,
        borderRadius: tokens.borderRadiusSm,
      }}
    >
      <Stack justifyContent="space-between" alignItems="flex-start" direction="row">
        <Stack>
          <Typography variant="body1">{friendlyName}</Typography>
          <Typography variant="subtitle1">
            Created: <DateDisplay dateStr={createdAt.toISOString()} />
          </Typography>
          {lastSignIn && (
            <Typography variant="subtitle1">
              Last used:{' '}
              <DateDisplay dateStr={lastSignIn.toISOString()} dateFormat="dd-MMM-yyyy hh:mm" />
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={tokens.spacingBase}>
          <Button
            size="small"
            variant="text"
            startIcon={<ColoredIcon iconName="editRounded" />}
            onClick={handleRename}
          >
            rename
          </Button>
          <Button
            size="small"
            variant="text"
            startIcon={<ColoredIcon iconName="deleteRounded" colorName="errorMain" />}
            color="error"
            onClick={handleRemove}
          >
            remove
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default function PasskeySettings({
  credentials,
  handleAddNew,
  handleRemove,
  handleRename,
  openSetupDialog = false,
}: PasskeySettingsProps) {
  const confirm = useConfirm();
  const [error, setError] = React.useState<Error>(undefined);
  const dialogOpenRef = React.useRef(false);

  React.useEffect(() => {
    if(openSetupDialog && !dialogOpenRef.current) {
      addNewHandler();
      dialogOpenRef.current = true;
    }
  }, [openSetupDialog]);

  const confirmRemovePasskey = () =>
    confirm({
      dialogProps: {
        maxWidth: 'xs',
      },
      title: (
        <Typography component="span" variant="h6">
          Remove security key?
        </Typography>
      ),
      confirmationText: 'Remove Key',
      confirmationButtonProps: {
        color: 'error',
      },
      description: (
        <Typography component="span" variant="body1">
          You will no longer be able to use it to login to your Verifime account. You can create a
          new key for this device anytime.
        </Typography>
      ),
    });
  const alertCannotRemovePasskey = () =>
    confirm({
      dialogProps: {
        maxWidth: 'xs',
      },
      title: (
        <Typography component="span" variant="h6">
          Can’t remove security key
        </Typography>
      ),
      hideCancelButton: true,
      confirmationText: 'OK',
      description: (
        <Typography component="span" variant="body1">
          You need to add at least one other valid login method before you can remove this key
        </Typography>
      ),
    });

  const askNewNameOfPasskey = (oldName: string = '') =>
    new Promise<string>((resolve, reject) => {
      confirm({
        dialogProps: {
          maxWidth: 'xs',
        },
        title: (
          <Typography component="span" variant="h6">
            Rename security key
          </Typography>
        ),
        confirmationText: 'Done',
        inputbox: true,
        defaultInputboxValue: oldName,
        inputboxTextFieldProps: {
          label: 'Security Key name',
          margin: 'normal',
        },
      })
        .then((inputboxValue) => resolve(inputboxValue))
        .catch(reject);
    });

  const removeHandler = (index: number) => {
    setError(undefined);
    if (credentials?.length === 1) {
      alertCannotRemovePasskey();
    } else {
      confirmRemovePasskey()
        .then(() => handleRemove?.(index))
        .catch((err) => {
          if (err) {
            console.error('Security key removal failed:', err);
            setError(err);
          } else {
            console.debug('Security Key removal cancelled.');
          }
        });
    }
  };

  const renameHandler = async (index: number, oldName: string) => {
    setError(undefined);
    try {
      const newPasskeyName = await askNewNameOfPasskey(oldName);
      await handleRename?.(index, newPasskeyName);
    } catch (err) {
      if (err) {
        console.error('Security key rename failed:', err);
        setError(err as Error);
      } else {
        console.debug('Security Key rename cancelled.');
      }
    }
  };

  const addNewHandler = async () => {
    setError(undefined);
    handleAddNew?.(askNewNameOfPasskey).catch((err) => {
      if (err) {
        console.error('Add Security Key failed.', err);
        setError(err as Error);
      } else {
        console.debug('Add Security Key cancelled.');
      }
    });
  };

  return (
    <Stack spacing={tokens.spacingLg}>
      <PasskeySettingsHeading />
      <Stack spacing={tokens.spacingBase}>
        {error && <FidoSetupErrorMessage error={error} />}
        {credentials?.map((credential, index) => (
          <PasskeySetting
            key={credential.credentialId}
            credential={credential}
            handleRemove={() => removeHandler(index)}
            handleRename={() => renameHandler(index, credential.friendlyName)}
          />
        ))}
        <Box>
          <Button
            size="medium"
            variant="outlined"
            startIcon={<ColoredIcon iconName="addRounded" />}
            onClick={addNewHandler}
          >
            Add New
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
