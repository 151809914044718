import { ReactNode, useEffect } from 'react';
import {
  Drawer as MUIDrawer,
  DrawerProps as MUIDrawerProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import tokens from '@verifime/design-tokens';
import { useHideDocumentScrollbar } from './Hooks/useHideDocumentScrollbar';

export type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  PaperProps?: MUIDrawerProps['PaperProps'];
};

export default function Drawer({ isOpen, onClose, children, PaperProps }: DrawerProps) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  useHideDocumentScrollbar(isOpen);

  let width = tokens.sizeLg;

  // if viewport is less than 900p, set width to 100% to show drawer in full screen.
  if (isFullScreen) {
    width = '100%';
  }
  // otherwise if the viewport is extra large (1536p), set width to 1200p
  else if (isExtraLarge) {
    width = tokens.sizeLg;
  }
  // when viewport is between 1200p and 1536p, set width to 80%
  else {
    width = '80%';
  }

  return (
    <MUIDrawer
      anchor={isFullScreen ? 'bottom' : 'right'}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        ...PaperProps,
        sx: {
          width,
          height: '100%',
          py: tokens.spacingXl,
          px: tokens.spacingLg,
          ...PaperProps?.sx,
        },
      }}
      transitionDuration={{
        enter: theme.transitions.duration.complex,
        exit: theme.transitions.duration.leavingScreen,
      }}
    >
      {children}
    </MUIDrawer>
  );
}
