import { Box } from '@mui/material';
import tokens, { generateColor } from '@verifime/design-tokens';
import Logo, { TLogoColorMode, TLogoLayout, TLogoSize } from '../Logo';

export default function SideNavLogo({
  size = 'medium',
  layout = 'horizontal',
  colorMode = 'onPrimary',
}: {
  colorMode?: TLogoColorMode;
  layout?: TLogoLayout;
  size?: TLogoSize;
}) {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: generateColor(tokens.brandColorPrimary, '600'),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: tokens.spacingBase,
        paddingX: tokens.spacingLg,
      }}
    >
      <Logo size={size} layout={layout} colorMode={colorMode} />
    </Box>
  );
}
