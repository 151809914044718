import { Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';

type TLargeButtonProps = { children: ReactNode } & ButtonProps;

export default function LargeButton({ children, ...props }: TLargeButtonProps) {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        ...props.sx,
        fontSize: '1rem',
        borderRadius: '1rem',
        minWidth: '7rem',
      }}
      size="large"
    >
      {children}
    </Button>
  );
}
