import { Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { IDENTITY_TYPES_MAP, TIdentityTypeCode } from '@verifime/utils';

export default function SearchItemDisplay({ name, value }: { name: string; value: string }) {
  return (
    <Stack
      width="100%"
      gap={tokens.spacingXs}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      padding={tokens.spacing2xs}
    >
      <Typography sx={{ flex: 2 }} color="textPrimary">
        {name}
      </Typography>
      <Typography
        variant="caption"
        sx={{ flex: 1, textTransform: 'uppercase', textAlign: 'end' }}
        color="textSecondary"
      >
        {value}
      </Typography>
    </Stack>
  );
}
