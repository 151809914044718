import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IndividualAvatarIcon from './images/individual-avatar.svg';
import SMSFAvatarIcon from './images/smsf-avatar.svg';
import UnregulatedTrustAvatarIcon from './images/unregulated-trust-avatar.svg';
import RegulatedTrustAvatarIcon from './images/regulated-trust-avatar.svg';
import UnknownAvatarIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import PublicCompanyAvatarIcon from './images/public-company-avatar.svg';
import PrivateCompanyAvatarIcon from './images/private-company-avatar.svg';
import PartnershipAvatarIcon from './images/partnership-avatar.svg';
import SoleTraderAvatarIcon from './images/sole-trader-avatar.svg';
import tokens, { generateOpacityColor } from '@verifime/design-tokens';
import { IdentityType } from '../types';
import * as React from 'react';

const AVATAR_ICON_MAPPING: { [key in IdentityType]: React.JSX.Element } = {
  [IdentityType.INDIVIDUAL]: <IndividualAvatarIcon />,
  [IdentityType.SMSF]: <SMSFAvatarIcon />,
  [IdentityType.PRIVATE_COMPANY]: <PrivateCompanyAvatarIcon />,
  [IdentityType.UNREGULATED_TRUST]: <UnregulatedTrustAvatarIcon />,
  [IdentityType.REGULATED_TRUST]: <RegulatedTrustAvatarIcon />,
  [IdentityType.PUBLIC_COMPANY]: <PublicCompanyAvatarIcon />,
  [IdentityType.PARTNERSHIP]: <PartnershipAvatarIcon />,
  [IdentityType.SOLE_TRADER]: <SoleTraderAvatarIcon />,
  [IdentityType.UNKNOWN]: <UnknownAvatarIcon />,
};

export default function IdentityAvatarIcon({
  type,
  variant = 'square',
}: {
  type: IdentityType;
  variant?: 'circle' | 'square';
  backgroundColor?: React.CSSProperties['backgroundColor'];
}) {
  if (variant === 'circle') {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 'fit-content',
          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        {AVATAR_ICON_MAPPING[type]}
      </Stack>
    );
  }
  return (
    <Box
      sx={{
        borderRadius: tokens.borderRadiusXl,
        border: '2px', // border needs tokens as well.
        borderColor: generateOpacityColor(tokens.colorWhite, 0.2),
      }}
    >
      {AVATAR_ICON_MAPPING[type]}
    </Box>
  );
}
