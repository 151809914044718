'use client';

import {
  TIdentityType,
  TSupportedOrganisationIdentityTypeCode,
  basicFieldsPrefix,
  validationRules,
} from '@verifime/utils';
import { useEffect, useState } from 'react';
import { RenderType, TFormFieldsAndRules_New } from '../Form';
import useAddEntity from './useAddEntity';
import {
  AVAILABLE_COUNTRIES,
  COUNTRY_ADDRESS_FIELDS_AND_RULES,
  wizardOrganisationFormFieldsAndRules,
} from './utils';

type TUseAddEntity = Parameters<typeof useAddEntity>[0];
type TSubmitApiRequest = TUseAddEntity['submitApiRequest'];

export default function useAddNewOrganisationEntity({
  identityTypes,
  initialIdentityTypeCode,
  extraFormFieldsAndRules = {},
  submitApiRequestForIdentityType,
  onIdentityTypeChanged,
  ...props
}: {
  identityTypes: TIdentityType[];
  initialIdentityTypeCode: TSupportedOrganisationIdentityTypeCode;
  extraFormFieldsAndRules?: TFormFieldsAndRules_New;
  onIdentityTypeChanged?: (identityType: TSupportedOrganisationIdentityTypeCode) => void;
  submitApiRequestForIdentityType: (
    identityType: TSupportedOrganisationIdentityTypeCode,
  ) => TSubmitApiRequest;
} & Omit<
  TUseAddEntity,
  | 'customerId'
  | 'initialFormFieldsAndRules'
  | 'initialAddresses'
  | 'retrieveCustomerApiRequest'
  | 'onRetrieveComplete'
  | 'submitApiRequest'
>) {
  const [selectedIdentityTypeCode, setSelectedIdentityTypeCode] =
    useState<TSupportedOrganisationIdentityTypeCode>(initialIdentityTypeCode);

  const { reset, ...useAddEntityResult } = useAddEntity({
    initialFormFieldsAndRules: {
      row0: [
        {
          label: 'Entity Type',
          fieldName: basicFieldsPrefix + 'EntityType',
          rules: validationRules.REQUIRED_STRING_RULES,
          renderType: RenderType.Select,
          items: identityTypes,
          onValueChange: (value: TSupportedOrganisationIdentityTypeCode) => {
            setSelectedIdentityTypeCode(value);
            onIdentityTypeChanged?.(value);
          },
        },
      ],
      ...wizardOrganisationFormFieldsAndRules[selectedIdentityTypeCode]?.basic,
      ...extraFormFieldsAndRules,
    },
    initialAddresses: wizardOrganisationFormFieldsAndRules[selectedIdentityTypeCode]?.addresses,
    submitApiRequest: submitApiRequestForIdentityType(selectedIdentityTypeCode),
    ...props,
  });

  // Reset to empty form when identity type changes (previous entries are no longer valid.)
  useEffect(() => {
    let defaultAddressesCountriesCode = {};
    const defaultCountryCode = AVAILABLE_COUNTRIES[0].code;
    wizardOrganisationFormFieldsAndRules[selectedIdentityTypeCode]?.addresses?.forEach(
      (address) => {
        const selectedCountryAddressFieldsAndRules =
          COUNTRY_ADDRESS_FIELDS_AND_RULES[defaultCountryCode][address.fieldsAndRulesName];
        defaultAddressesCountriesCode = {
          ...defaultAddressesCountriesCode,
          [selectedCountryAddressFieldsAndRules.countryCode.fieldName]: defaultCountryCode,
        };
      },
    );

    reset({
      [basicFieldsPrefix + 'EntityType']: selectedIdentityTypeCode,
      ...defaultAddressesCountriesCode,
    });
  }, [selectedIdentityTypeCode]);

  return { reset, ...useAddEntityResult };
}
