import { throwIfNot2xx } from 'amazon-cognito-passwordless-auth/util';
import { retrieveTokens } from 'amazon-cognito-passwordless-auth/storage';

export interface Config {
  /** TOTP configuration */
  totp?: {
    /** The base URL (i.e. the URL with path "/") of your TOTP API */
    baseUrl: string;
    issuer?: string;
  };
  /**
   * Function that will be called with debug information,
   * e.g. you can use `console.debug` here.
   */
  debug?: (...args: unknown[]) => unknown;
}

let config_: Config | undefined = undefined;
export function configure(config?: Config) {
  if (config) {
    config_ = { ...config };
    config_.debug?.('Configuration loaded:', config);
  } else {
    if (!config_) {
      throw new Error('Call configure(config) first');
    }
  }
  return config_;
}

export async function otpAssociateCredential({
  secret,
}: {
  secret?: string | (() => string | Promise<string>);
}) {
  const { totp: { baseUrl } = {} } = configure();
  const { idToken } = (await retrieveTokens()) ?? {};
  if (!idToken) {
    throw new Error('No JWT to invoke Fido2 API with');
  }
  const url = new URL('/register', baseUrl);
  const method = 'POST';

  let body = undefined;
  if (secret) {
    body = { secret: typeof secret === 'string' ? secret : await secret() };
  }

  return fetch(url, {
    body: JSON.stringify(body),
    method,
    headers: {
      accept: 'application/json, text/javascript',
      'content-type': 'application/json; charset=UTF-8',
      authorization: `Bearer ${idToken}`,
    },
  })
    .then(throwIfNot2xx)
    .then((res) => res.json() as Promise<{ totpAuthUri: string }>)
    .then((res) => res.totpAuthUri);
}

export async function otpHasUserRegistered() {
  const { totp: { baseUrl } = {} } = configure();
  const { idToken } = (await retrieveTokens()) ?? {};
  if (!idToken) {
    throw new Error('No JWT to invoke Fido2 API with');
  }
  const url = new URL('/has-otp', baseUrl);
  const method = 'POST';

  return fetch(url, {
    method,
    headers: {
      accept: 'application/json, text/javascript',
      'content-type': 'application/json; charset=UTF-8',
      authorization: `Bearer ${idToken}`,
    },
  })
    .then(throwIfNot2xx)
    .then((res) => res.json() as Promise<{ hasTotp: boolean }>)
    .then((res) => res.hasTotp);
}
