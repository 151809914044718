import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TOrganisation } from './types';
import { ColoredIcon, FlexBox } from '../index';
import tokens from '@verifime/design-tokens';
import { alpha, useTheme } from '@mui/material/styles';

export default function OrganisationSelection({
  organisation,
  handleChange,
  modifiable = true,
}: {
  organisation: TOrganisation;
  handleChange?: () => void;
  modifiable?: boolean;
}) {
  const theme = useTheme();

  return (
    <Stack
      borderRadius={tokens.borderRadiusSm}
      padding={`${tokens.spacingBase} ${tokens.spacingXs}`}
      gap={tokens.spacing2xs}
      bgcolor={alpha(theme.palette.action.selected, 0.4)}
    >
      <Stack
        spacing={tokens.spacing2xs}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography variant="h6">{organisation.subEntityName}</Typography>
        <Box>
          <ColoredIcon iconName="verifiedRounded" colorName="successMain" />
        </Box>
      </Stack>
      <Typography variant="subtitle1">{organisation.reportingEntityName}</Typography>
      {modifiable && (
        <Stack direction="row-reverse" justifyContent="flex-start" alignItems="flex-start">
          <Button
            variant="text"
            size="small"
            sx={{
              py: tokens.spacing2xs,
              px: tokens.spacingSm,
            }}
            onClick={handleChange}
          >
            Change
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
