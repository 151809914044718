import {
  FormControl,
  FormHelperText,
  SelectChangeEvent,
  SelectProps,
  FormControlProps,
  Container,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldValues, UseFormSetError } from 'react-hook-form';
import { formFieldLabel } from './utils';
import ComboBox from '../ComboBox';
import { TOption } from '@verifime/utils';
import SearchItemDisplay from '../SearchItemDisplay';
import tokens from '@verifime/design-tokens';

type TValue = string | string[] | undefined;

type TReactHookFormSearchSelectProps = {
  name: string;
  label: string;
  control: Control<FieldValues, TValue>;
  setError?: UseFormSetError<FieldValues>;
  defaultValue?: TValue;
  required?: boolean;
  onOptionChange?: (event: SelectChangeEvent) => void;
  // see on why https://github.com/mui/material-ui/issues/18580
  // Select component does not support all margin property like other components,
  // it must be put into FormControl component to support margin property.
  margin?: FormControlProps['margin'];
  size?: SelectProps<TValue>['size'];
  optionProvider: (searchText: string) => Promise<TOption[]>;
} & Omit<SelectProps<TValue>, 'margin'>;

export default function FormComboBox({
  name,
  label,
  control,
  setError,
  required = false,
  defaultValue = '',
  margin = 'normal',
  sx,
  optionProvider,
}: TReactHookFormSearchSelectProps) {
  const requiredLabel = formFieldLabel(label, required);
  const [searchText, setSearchText] = useState<string>('');
  const [options, setOptions] = useState<TOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<TOption | null>(null);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      optionProvider(defaultValue as string)
        .then((data) => {
          setOptions(data);
          setSearchText(data[0].label);
        })
        .catch((err) => {
          setError?.(name, { message: err, type: 'server' });
        });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (searchText === '') {
      setOptions([]);
      return;
    }
    setSearching(true);
    optionProvider(searchText)
      .then((data) => setOptions(data))
      .catch((err) => setError?.(name, { message: err, type: 'server' }))
      .finally(() => setSearching(false));
  }, [searchText]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <FormControl
              sx={{
                flex: '1',
                marginTop: tokens.spacingXs,
                marginBottom: tokens.spacing2xs,
                '&.MuiFormControl-root .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root':
                  {
                    padding: `${tokens.spacing4xs} ${tokens.spacingXs}`,
                  },
                '&.MuiFormControl-root .MuiAutocomplete-root .MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root':
                  {
                    marginTop: `-${tokens.spacingXs}`,
                    color: !!fieldState.error?.message ? (theme) => theme.palette.error.main : null,
                  },
                '&.MuiFormControl-root .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: !!fieldState.error?.message
                      ? (theme) => theme.palette.error.main
                      : null,
                  },
                ...sx,
              }}
              fullWidth
              error={!!fieldState.error?.message}
              margin={margin}
            >
              <ComboBox
                fullWidth
                autoSelect
                disableClearable
                clearOnBlur={false}
                ListboxProps={{ style: { maxHeight: tokens.sizeSm } }}
                label={requiredLabel as string}
                inputValue={searchText}
                value={selectedOption}
                options={options}
                freeSolo={searchText === '' ? true : false}
                loading={isSearching}
                loadingText="Searching"
                noOptionsText="No result found"
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => {
                  return option.code === value.code;
                }}
                renderOption={(props, { code, label }, state) => {
                  return (
                    <Container sx={{ flex: '1' }} component="li" {...props} {...state}>
                      <SearchItemDisplay key={code} name={label} value={code} />
                    </Container>
                  );
                }}
                onInputChange={(event, newSearchText) => {
                  if (!event) {
                    return;
                  }

                  setSearchText(newSearchText);
                }}
                onChange={(event: any, newSelectedOption) => {
                  if (!newSelectedOption) {
                    return;
                  }

                  event.target.value = (newSelectedOption as TOption).code;
                  field.onChange(event);

                  setSelectedOption(newSelectedOption as TOption);
                }}
              />
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          </>
        );
      }}
    />
  );
}
