import {
  TBreakpoint,
  THorizontalAlign,
  TVertitcalAlign,
  getResponsiveCssValue,
} from '@verifime/styles';
import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

type TResponsiveBoxProps = {
  responsiveOnWidth?: TBreakpoint | 'none';
  verticalAlign?: TVertitcalAlign;
  align?: THorizontalAlign;
  responsiveAlign?: THorizontalAlign;
  children: ReactNode;
} & BoxProps;

export default function ResponsiveBox({
  responsiveOnWidth = 'sm',
  verticalAlign = 'top',
  align = 'left',
  responsiveAlign = 'left',
  children,
  ...props
}: TResponsiveBoxProps) {
  const defaultAlignItems =
    verticalAlign === 'top' ? 'flex-start' : verticalAlign === 'bottom' ? 'flex-end' : 'center';
  const responsiveAlignItems =
    responsiveAlign === 'left' ? 'flex-start' : responsiveAlign === 'right' ? 'flex-end' : 'center';

  const alignItems = getResponsiveCssValue(
    responsiveOnWidth,
    responsiveAlignItems,
    defaultAlignItems,
  );
  const flexDirection = getResponsiveCssValue(responsiveOnWidth, 'column', 'row');
  return (
    <Box
      {...props}
      sx={{
        flexDirection,
        alignItems,
        position: 'relative',
        width: '100%',
        display: 'flex',
        gap: '1rem',
        justifyContent: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center',
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
}
