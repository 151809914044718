import { Alert as MuiAlert, AlertTitle, AlertProps, Typography, Box } from '@mui/material';
import { ReactNode } from 'react';

export type TAlertProps = { title?: ReactNode } & AlertProps;

export default function Alert({ title, children, action, ...props }: TAlertProps) {
  return (
    <MuiAlert {...props} sx={{ ...props.sx, '& .MuiAlert-message': { width: '100%' } }}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {['string', 'number', 'boolean'].includes(typeof children) ? (
        <Typography variant="body2">{children}</Typography>
      ) : (
        <>{children}</>
      )}
      {action && (
        <Box display="flex" justifyContent="end">
          {action}
        </Box>
      )}
    </MuiAlert>
  );
}