import Alert, { AlertProps } from '@mui/material/Alert';
import { default as InfoIcon } from '@mui/icons-material/AutoAwesomeRounded';
import { default as MailIcon } from '@mui/icons-material/EmailRounded';

export default function LoginAlert({
  severity = 'info',
  fullwidth = false,
  ...props
}: { fullwidth?: boolean } & AlertProps) {
  return (
    <Alert
      severity={severity}
      sx={{
        width: fullwidth ? '100%' : 'auto',
        ...props.sx,
      }}
      {...props}
    >
      {props.children}
    </Alert>
  );
}

export function MagicLinkConfirmationPromptMessage() {
  return (
    <LoginAlert
      icon={<MailIcon />}
      sx={{
        alignItems: 'center',
      }}
    >
      <p>
        We've sent an email with a link for you to sign in. Please check your Inbox or Spam folder.
      </p>
      <p>You can close this tab now.</p>
    </LoginAlert>
  );
}

export function ErrorMessage({ error }: { error: Error }) {
  return (
    <LoginAlert severity="error" fullwidth>
      Unable to sign-in
    </LoginAlert>
  );
}

export function FidoSetupErrorMessage({ error }: { error: Error }) {
  return (
    <LoginAlert severity="error" fullwidth>
      Failed to setup passkey, try another way?
    </LoginAlert>
  );
}

export function OtpErrorMessage({ error }: { error: Error }) {
  return <ErrorMessage error={error} />;
}
