import { Button, Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import CheckErrorIcon from './error.svg';

export default function CheckError({
  isEnableSkip,
  onSkip,
}: {
  isEnableSkip?: boolean;
  onSkip?: () => void;
}) {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <CheckErrorIcon />
        <Typography variant="h6">Face check is unavailable at the moment</Typography>
        <Typography variant="body2" color="textSecondary">
          We are unable to complete a face check at the moment.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Please try again later.
        </Typography>
        {isEnableSkip && <Button onClick={onSkip}>Skip face check for now</Button>}
      </Stack>
    </Paper>
  );
}
