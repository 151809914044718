/**
 * @description
 */
import * as getCustomer from './getCustomer';
import * as postCustomerByAdmin from './postCustomerByAdmin';
import * as getCustomerMe from './getCustomerMe';
import * as getCustomerUser from './getCustomerUser';
import * as getCustomerById from './getCustomerById';
import * as deleteCustomerById from './deleteCustomerById';
import * as putCustomerByIdByAdmin from './putCustomerByIdByAdmin';
import * as deleteCustomerByIdCheck from './deleteCustomerByIdCheck';
import * as putCustomerByIdEntityStatus from './putCustomerByIdEntityStatus';
import * as putCustomerByIdIdentityDocumentStatus from './putCustomerByIdIdentityDocumentStatus';
import * as postCustomerByIdUser from './postCustomerByIdUser';

export {
  getCustomer,
  postCustomerByAdmin,
  getCustomerMe,
  getCustomerUser,
  getCustomerById,
  deleteCustomerById,
  putCustomerByIdByAdmin,
  deleteCustomerByIdCheck,
  putCustomerByIdEntityStatus,
  putCustomerByIdIdentityDocumentStatus,
  postCustomerByIdUser,
};
