/**
 * @description
 */
import * as postRule from './postRule';
import * as getRuleOrganisationByOrgId from './getRuleOrganisationByOrgId';
import * as getRuleById from './getRuleById';
import * as putRuleById from './putRuleById';
import * as deleteRuleById from './deleteRuleById';
import * as deleteRuleByIdCheck from './deleteRuleByIdCheck';
import * as putRuleByIdStatusByStatus from './putRuleByIdStatusByStatus';
import * as putRuleByIdTemplate from './putRuleByIdTemplate';
import * as deleteRuleByIdTemplate from './deleteRuleByIdTemplate';

export {
  postRule,
  getRuleOrganisationByOrgId,
  getRuleById,
  putRuleById,
  deleteRuleById,
  deleteRuleByIdCheck,
  putRuleByIdStatusByStatus,
  putRuleByIdTemplate,
  deleteRuleByIdTemplate,
};
