import Box from '@mui/material/Box';
import { ColoredChip, ColoredIcon, TIconName, TColorName } from '../ColoredIconAndChip';
import { IdentityStatus } from './types';

export interface IdentityStatusChipProp {
  status?: IdentityStatus;
  isCompact?: boolean;
}

type TColorAndIcon = {
  iconName: TIconName;
  colorName: TColorName;
};

const COLOR_AND_ICON_MAPPING: { [key in IdentityStatus]: TColorAndIcon } = {
  [IdentityStatus.DRAFT]: {
    iconName: 'editRounded',
    colorName: 'draftIdentity',
  },
  [IdentityStatus.IN_PROGRESS]: {
    iconName: 'pending',
    colorName: 'unverifiedIdentity',
  },
  [IdentityStatus.REJECTED]: {
    iconName: 'errorRounded',
    colorName: 'rejectedIdentity',
  },
  [IdentityStatus.VERIFIED]: {
    iconName: 'verifiedRounded',
    colorName: 'verifiedIdentity',
  },
};

export default function IdentityStatusChip({ status, isCompact }: IdentityStatusChipProp) {
  if (status === undefined) {
    return;
  }

  const colorAndIcon = COLOR_AND_ICON_MAPPING[status];

  if (isCompact) {
    return (
      <ColoredIcon {...colorAndIcon} colorName={colorAndIcon.colorName} isCompact={isCompact} />
    );
  }

  return (
    <Box>
      <ColoredChip
        icon={<ColoredIcon {...colorAndIcon} />}
        colorName={colorAndIcon.colorName}
        label={status}
      />
    </Box>
  );
}
