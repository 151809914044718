'use client';

import { Stack, TextField } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';

export type TEmailInputProps = {
  initialEmail?: string;
  onEmailChange?: (email: string) => void;
  isDisabled?: boolean;
  placeholder?: string;
};

export default function EmailInput({
  initialEmail,
  onEmailChange,
  isDisabled,
  placeholder = 'Email',
}: Readonly<TEmailInputProps>) {
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    // The initialEmail may null at the first then it is populated a value,
    // thus we need to reset the email by the actual value
    setEmail(initialEmail || '');
  }, [initialEmail]);

  return (
    <Stack width="100%" gap={tokens.spacingLg} alignItems="center">
      <TextField
        fullWidth
        variant="outlined"
        size="medium"
        type="email"
        autoFocus
        label="Email"
        placeholder={placeholder}
        value={email}
        autoComplete="webauthn"
        onChange={(e) => {
          const email = e.target.value as string;
          setEmail(email);
          onEmailChange?.(email);
        }}
        sx={{
          marginTop: tokens.spacingLg,
        }}
        disabled={isDisabled}
      />
    </Stack>
  );
}
