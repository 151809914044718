'use client';

import { Box, alpha, useTheme } from '@mui/material';
import {
  FaceEventActions,
  FaceLivenessDetailType,
  FaceLivenessWebComponent,
  IFaceLiveness,
} from '@regulaforensics/vp-frontend-face-components';
import { useMobile } from '@verifime/utils';
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import CheckError from './CheckError';
import CheckSuccess from './CheckSuccess';
import NoCamera from './NoCamera';
import ReachedMaxTries from './ReachedMaxTries';
import SkippingFaceCheck from './SkippingFaceCheck';
import StartScreen from './StartScreen';

type ErrorTypes = any;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'face-liveness': DetailedHTMLProps<
        IFaceLiveness & HTMLAttributes<FaceLivenessWebComponent>,
        FaceLivenessWebComponent
      >;
    }
  }
}

export default function FaceLiveness({
  url,
  sessionId,
  token,
  maxTryCount = Number.MAX_VALUE,
  customisedReachMaxTries,
  customisedSuccess,
  customisedSkip,
  isEnableOptions = true,
  isShowQrCodeWhenNoCamera = false,
  onFinish,
  onSuccess,
  onNoCamera,
  onReachMaxTries,
  onSkip,
  onRetry,
}: Readonly<{
  url: string;
  sessionId: string;
  token: string;
  customisedSkip?: ReactNode;
  isEnableOptions?: boolean;
  onSkip?: () => void;
  maxTryCount?: number;
  customisedReachMaxTries?: ReactNode;
  customisedSuccess?: ReactNode;
  isShowQrCodeWhenNoCamera?: boolean;
  // Finished the face check
  // status: 0 if the liveness is confirmed, 1 if not.
  onFinish?: (transactionId: string, status: number) => void;
  // The face check succeedeed
  onSuccess?: () => void;
  onNoCamera?: () => void;
  onReachMaxTries?: () => void;
  onRetry?: () => void;
}>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes | null>(null);
  const [triedCount, setTriedCount] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<FaceLivenessWebComponent>(null);
  const actionHistoryRef = useRef<FaceEventActions[]>([]);
  const theme = useTheme();
  const isMobile = useMobile();

  useEffect(() => {
    import('@regulaforensics/vp-frontend-face-components');
  }, []);

  const isReachedMaxTries = triedCount >= maxTryCount;

  useEffect(() => {
    if (isReachedMaxTries) {
      onReachMaxTries?.();
    }
  }, [onReachMaxTries, isReachedMaxTries]);

  const listener = (data: CustomEvent<FaceLivenessDetailType>) => {
    actionHistoryRef.current.push(data.detail.action);

    if (data.detail.action === 'PROCESS_FINISHED') {
      const { status: dataStatus, response, reason } = data.detail.data;
      // Errors happen
      if (dataStatus === 0) {
        setErrorType(reason as ErrorTypes);
      }

      if (dataStatus === 1 && response) {
        const { status, transactionId } = data.detail.data.response;
        onFinish?.(transactionId, status);
        setTriedCount((triedCount) => triedCount + 1);
        // success
        if (status === 0) {
          onSuccess?.();
          setIsSuccess(true);
        }
      }
    }

    if (actionHistoryRef.current.length > 1) {
      const [secondLastAction, lastAction] = actionHistoryRef.current.slice(-2);
      // In these unexpected actions will show Regula default `Selfie time` screen.
      // We just close this screen directly.
      if (
        (secondLastAction === 'PROCESS_FINISHED' && lastAction === 'ELEMENT_VISIBLE') ||
        (secondLastAction === 'ELEMENT_VISIBLE' && lastAction === 'ELEMENT_VISIBLE')
      ) {
        setIsOpen(false);
        actionHistoryRef.current = [];
      }
    }

    if (data.detail?.action === 'CLOSE' || data.detail?.action === 'RETRY_COUNTER_EXCEEDED') {
      setIsOpen(false);
      actionHistoryRef.current = [];
    }
  };

  useEffect(() => {
    if (isOpen && componentRef.current) {
      componentRef.current.settings = {
        url,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        videoRecording: true,
        copyright: false,
        tag: sessionId,
        startScreen: false,
        finishScreen: true,
        customization: {
          retryScreenRetryButtonBackground: theme.palette.primary.main,
          retryScreenRetryButtonBackgroundHover: alpha(theme.palette.primary.main, 0.8),
        },
      };

      // To set the face-liveness 100% width
      setTimeout(() => {
        const divContainer = componentRef.current.shadowRoot.querySelector('div');
        divContainer?.setAttribute('style', 'width:100%');
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const containerCurrent = containerRef.current;

    if (!containerCurrent) return;

    containerCurrent.addEventListener('face-liveness', listener);

    return () => {
      containerCurrent.removeEventListener('face-liveness', listener);
    };
  }, []);

  const OpenedFaceCheckComponent = () => {
    if (['CAMERA_PERMISSION_DENIED', 'NO_CAMERA', 'CAMERA_UNKNOWN_ERROR'].includes(errorType)) {
      onNoCamera?.();
      return (
        <NoCamera
          onSkipConfirm={() => {
            setIsSkip(true);
            onSkip?.();
          }}
          isEnableOptions={isEnableOptions}
          isShowQrCodeWhenNoCamera={isShowQrCodeWhenNoCamera}
        />
      );
    }

    if (['WASM_ERROR', 'UNKNOWN_ERROR', 'NOT_SUPPORTED', 'CONNECTION_ERROR'].includes(errorType)) {
      return (
        <CheckError
          onSkip={() => {
            setIsSkip(true);
            onSkip?.();
          }}
          isEnableSkip={isEnableOptions}
        />
      );
    }

    if (isSuccess) {
      return <CheckSuccess customisedSuccess={customisedSuccess} />;
    }

    if (isReachedMaxTries) {
      return <ReachedMaxTries customisedReachMaxTries={customisedReachMaxTries} />;
    }

    if (isOpen) {
      return (
        <face-liveness
          ref={componentRef}
          style={
            isMobile
              ? {
                  height: '100vh',
                  position: 'absolute',
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  right: 0,
                  width: '100vw',
                  bottom: 0,
                }
              : {}
          }
        ></face-liveness>
      );
    }
  };

  const FaceCheckComponent = () => {
    if (isSkip) {
      return (
        <SkippingFaceCheck
          customisedSkip={customisedSkip}
          onRetry={() => {
            setIsOpen(true);
            setIsSkip(false);
            onRetry?.();
          }}
        />
      );
    }

    if (isOpen) {
      return <OpenedFaceCheckComponent />;
    }

    return (
      <StartScreen
        isEnableOptions={isEnableOptions}
        onButtonClick={() => setIsOpen(true)}
        onSkipConfirm={() => {
          setIsSkip(true);
          onSkip?.();
        }}
      />
    );
  };

  return (
    <Box ref={containerRef}>
      <FaceCheckComponent />
    </Box>
  );
}
