import { isDate, parse, parseISO, format, subDays } from 'date-fns';

export function parseDateString(_: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());
  return parsedDate;
}

export function parseISODateString(_: string, originalValue: string) {
  return isDate(originalValue) ? originalValue : parseISO(originalValue);
}

/**
 *
 * Gets formated dd/MM/yyyy string date
 *
 * @param strDate string type of date
 * @param emptyPlacement use this placement as return value if pased string date is empty
 * @returns dd/MM/yyyy formated string date
 */
export function formatToDDMMYYYY(strDate: string, emptyPlacement = 'N/A') {
  if (!strDate) {
    return emptyPlacement ?? '';
  }
  try {
    return format(new Date(strDate), 'dd/MM/yyyy');
  } catch {
    return emptyPlacement ?? '';
  }
}

/**
 *
 * Gets formated yyyy-MM-dd string date
 *
 * @param strDate string type of date
 * @param emptyPlacement use this placement as return value if pased string date is empty
 * @returns yyyy-MM-dd formated string date
 */
export function formatToYYYYMMDD(strDate: string, emptyPlacement = 'N/A') {
  if (!strDate) {
    return emptyPlacement ?? '';
  }
  try {
    return format(new Date(strDate), 'yyyy-MM-dd');
  } catch {
    return emptyPlacement ?? '';
  }
}

/**
 *
 * Gets formated dd MMM yyyy string date
 *
 * e.g. 04 Aug 2023
 *
 * @param strDate string date
 * @param emptyPlacement use this placement as return value if pased string date is empty
 * @returns dd MMM yyyy formated date
 */
export function formatToDDMMMYYYY(strDate: string, emptyPlacement = 'N/A') {
  if (!strDate) {
    return emptyPlacement ?? '';
  }
  try {
    return format(new Date(strDate), 'dd MMM yyyy');
  } catch {
    return emptyPlacement ?? '';
  }
}

/**
 *
 * Gets formated dd MMM yyyy hh:mmaaa string date
 *
 * e.g. 07 Aug 2023 10:20am
 *
 * @param strDate string date
 * @param emptyPlacement use this placement as return value if pased string date is empty
 * @returns dd MMM yyyy hh:mmaaa formated date
 */
export function formatToDDMMMYYYYHHMMAAA(strDate: string, emptyPlacement = 'N/A') {
  if (!strDate) {
    return emptyPlacement ?? '';
  }
  try {
    return format(new Date(strDate), 'dd MMM yyyy hh:mmaaa');
  } catch {
    return emptyPlacement ?? '';
  }
}

/**
 * Get the date of the last nth day from the given date (inclusive) or from today (inclusive) if no date is given.
 *
 * @param fromDate the given date
 * @param n the last nth day, or defaults to 7 if not given
 * @returns the date of the last nth day from the given date
 *
 */
export function lastNDays(date: Date = new Date(), n: number = 7) {
  return subDays(date, n - 1);
}
