import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  CircularProgressButton,
  DisplayErrors,
  NameValueDisplay,
  TPermission,
  useConfirm,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { SERVER_ERRORS, formatToDDMMMYYYYHHMMAAA } from '@verifime/utils';
import { useState } from 'react';

export interface TPermissionListItemProps {
  permission: TPermission;
  showRevokeButton?: boolean;
  onRevokeSuccess?: () => void;
  revokePermission?: (permission: TPermission) => Promise<void>;
}

export default function PermissionListItem({
  permission,
  showRevokeButton,
  ...props
}: TPermissionListItemProps) {
  const [isRevoking, setIsRevoking] = useState(false);
  const [isRevokeError, setIsRevokeError] = useState(false);
  const confirm = useConfirm();
  const permissionRevoked = !!permission.removedDate;
  const revokePermission = props.revokePermission || (() => Promise.resolve());
  const onRevokeSuccess = props.onRevokeSuccess || (() => {});

  const confirmAndRevoke = async (permission: TPermission) => {
    confirm({
      dialogProps: {
        maxWidth: 'xs',
      },
      title: <Typography variant="h6">Revoke Permission</Typography>,
      confirmationText: 'Revoke',
      confirmationButtonProps: {
        color: 'error',
      },
      description: (
        <Stack spacing={tokens.spacingBase}>
          <Typography variant="body1">
            This organisation will no longer have access to your details once you revoke permission.
          </Typography>
          <Typography variant="body1">
            They will still be able to request for some data from VerifiMe as part of an audit.
          </Typography>
        </Stack>
      ),
    }).then(async () => {
      setIsRevoking(true);
      revokePermission(permission)
        .then(() => onRevokeSuccess())
        .catch(() => setIsRevokeError(true))
        .finally(() => setIsRevoking(false));
    });
  };

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: tokens.borderRadiusSm,
        p: tokens.spacingSm,
      }}
    >
      <Stack>
        <Typography variant="h6">{permission.subEntityName}</Typography>
        <Typography variant="subtitle1">{permission.reportingEntityName}</Typography>
        <Stack padding={`${tokens.spacingXs} 0`}>
          <NameValueDisplay
            displayType="horizontal"
            name="Granted on"
            value={formatToDDMMMYYYYHHMMAAA(permission.addedDate)}
          />
          {permissionRevoked && (
            <NameValueDisplay
              displayType="horizontal"
              name="Revoked on"
              value={formatToDDMMMYYYYHHMMAAA(permission.removedDate)}
            />
          )}
        </Stack>
        {!permissionRevoked && showRevokeButton && (
          <Stack direction="row-reverse" justifyContent="flex-start" alignItems="flex-start">
            <CircularProgressButton
              size="medium"
              color="error"
              variant="text"
              onClick={() => confirmAndRevoke(permission)}
              isShowProgress={isRevoking}
            >
              Revoke
            </CircularProgressButton>
          </Stack>
        )}
      </Stack>
      {isRevokeError && <DisplayErrors errors={[{ message: SERVER_ERRORS.default }]} />}
    </Paper>
  );
}
