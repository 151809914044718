import { useState } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';

export type TPaginationModel = {
  startIndex: number;
  lastIndex: number;
} & GridPaginationModel;

export function usePaginationModel(pageSize = 10) {
  const page = 0;
  const startIndex = page * pageSize;

  const [paginationModel, setPaginationModel] = useState<TPaginationModel>({
    page,
    pageSize,
    startIndex,
    lastIndex: startIndex + pageSize,
  });

  const updatePaginationModel = (update: GridPaginationModel) => {
    const updatedStartIndex = update.page * update.pageSize;
    setPaginationModel({
      ...update,
      startIndex: updatedStartIndex,
      lastIndex: updatedStartIndex + update.pageSize,
    });
  };

  const resetPaginationModel = () => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
      startIndex: 0,
      lastIndex: paginationModel.pageSize,
    });
  };

  return { paginationModel, updatePaginationModel, resetPaginationModel };
}
