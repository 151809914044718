import { SUPPORTED_COUNTRY_MAP, TAddress, TCountryCode, TObject } from '@verifime/utils';

const getShortNameFromAddressComponent = (
  places_address_components: any,
  typeNames: string[],
): string => {
  const addressComponent: TObject = (places_address_components as [])?.find(function (c: any) {
    return typeNames.find((typeName) => c.types.includes(typeName));
  });
  return addressComponent?.short_name;
};

const getUnitNumber = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, ['subpremise']);
};

const getStreetNumber = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, ['street_number']);
};

const getStreetNameAndStreetType = (
  places_address_components: any,
): [streetName: string, streetType: string] => {
  const route = getShortNameFromAddressComponent(places_address_components, ['route']);
  const streetTokens: string[] = route.split(' ');
  if (streetTokens.length < 2) {
    return [streetTokens.join(' '), null];
  } else {
    return [streetTokens.slice(0, -1).join(' '), streetTokens.slice(-1).pop()];
  }
};

const getPostcode = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, ['postal_code']);
};

const getSuburb = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, [
    'neighborhood',
    'sublocality',
    'sublocality_level_1',
    'locality',
    'postal_town',
    'political',
  ]);
};

const getCity = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, [
    'locality',
    'postal_town',
    'administrative_area_level_2',
  ]);
};

const getState = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, [
    'administrative_area_level_1',
  ]);
};

const getProvince = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, [
    'administrative_area_level_1',
  ]);
};

const getCountry = (places_address_components: any) => {
  return getShortNameFromAddressComponent(places_address_components, ['country']);
};

const getBasicAddress = (places_address_components: any) => {
  const unitNumber = getUnitNumber(places_address_components);
  const streetNumber = getStreetNumber(places_address_components);
  const [streetName, streetType] = getStreetNameAndStreetType(places_address_components);
  const countryCode = getCountry(places_address_components);
  return { unitNumber, streetNumber, streetName, streetType, countryCode };
};

const getAddressForAU = (places_address_components: any): TAddress => {
  const suburb = getSuburb(places_address_components);
  const state = getState(places_address_components);
  const postcode = getPostcode(places_address_components);
  return {
    ...getBasicAddress(places_address_components),
    suburb,
    state,
    postcode,
  };
};

const generateAddressForHK = (places_address_components: any) => {
  const suburb = getSuburb(places_address_components);
  return {
    ...getBasicAddress(places_address_components),
    suburb,
  };
};

const generateAddressForNZ = (places_address_components: any) => {
  const suburb = getSuburb(places_address_components);
  const postcode = getPostcode(places_address_components);
  return {
    ...getBasicAddress(places_address_components),
    suburb,
    postcode,
  };
};

const generateAddressForSG = (places_address_components: any) => {
  const suburb = getSuburb(places_address_components);
  const postcode = getPostcode(places_address_components);
  return {
    ...getBasicAddress(places_address_components),
    suburb,
    postcode,
  };
};

const generateAddressForGB = (places_address_components: any) => {
  const suburb = getSuburb(places_address_components);
  const postcode = getPostcode(places_address_components);
  const city = getCity(places_address_components);
  return {
    ...getBasicAddress(places_address_components),
    suburb,
    city,
    postcode,
  };
};

export const countryAddressFormatter: {
  [countryCode in TCountryCode]?: (places_address_components: any) => TAddress;
} = {
  [SUPPORTED_COUNTRY_MAP.AU.code]: (places_address_components: any): TAddress =>
    getAddressForAU(places_address_components),
  [SUPPORTED_COUNTRY_MAP.HK.code]: (places_address_components: any): TAddress =>
    generateAddressForHK(places_address_components),
  [SUPPORTED_COUNTRY_MAP.NZ.code]: (places_address_components: any): TAddress =>
    generateAddressForNZ(places_address_components),
  [SUPPORTED_COUNTRY_MAP.SG.code]: (places_address_components: any): TAddress =>
    generateAddressForSG(places_address_components),
  [SUPPORTED_COUNTRY_MAP.GB.code]: (places_address_components: any): TAddress =>
    generateAddressForGB(places_address_components),
};
