/**
 * @desc undefined
 */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** id */
  id;
}

export const method = 'PATCH';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/organisation/{id}/deactivate', params, 'PATCH'),
    newValue,
    shouldRevalidate,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl(
    '/v1/organisation/{id}/deactivate',
    params,
    'PATCH',
    option,
  );
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'PATCH',
  });
}
