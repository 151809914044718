import HomeIcon from '@mui/icons-material/Home';
import InboxIcon from '@mui/icons-material/Inbox';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import { Role, TNavItem } from '@verifime/components';

export const BASE_PATH = '/dashboard';
export const MITIGATION_TEMPLATE_PATH = `${BASE_PATH}/mitigationTemplate`;
export const RULE_PATH = `${BASE_PATH}/rule`;

export const NAV_ITEM_HOME: TNavItem = {
  path: BASE_PATH,
  label: 'Home',
  icon: HomeIcon,
  role: Role.Client,
};

export const NAV_ITEM_ASSESSMENTS: TNavItem = {
  path: `${BASE_PATH}/assessments`,
  label: 'Assessments',
  icon: InboxIcon,
  role: Role.Client,
};

export const NAV_ITEM_CUSTOMERS: TNavItem = {
  path: `${BASE_PATH}/customers`,
  label: 'Customers',
  icon: PeopleIcon,
  role: Role.Client,
};

export const NAV_ITEM_CONFIGURATION: TNavItem = {
  path: `${BASE_PATH}/configuration`,
  label: 'Configuration',
  icon: SettingsIcon,
  role: Role.Client,
};

export const NAV_ITEM_DIVDER: TNavItem = {
  label: '---',
  path: '#',
  isDivider: true,
};

export const NAV_ITEMS: TNavItem[] = [
  NAV_ITEM_ASSESSMENTS,
  NAV_ITEM_CUSTOMERS,
  NAV_ITEM_DIVDER,
  NAV_ITEM_CONFIGURATION,
];

export const BOTTOM_NAV_ITEMS: TNavItem[] = [
  NAV_ITEM_ASSESSMENTS,
  NAV_ITEM_CUSTOMERS,
  NAV_ITEM_CONFIGURATION,
];

export function generateCustomerDetailsHref(customerId: string, orgId: string) {
  return `${NAV_ITEM_CUSTOMERS.path}/${customerId}?orgId=${orgId}`;
}

export function getMitigationTemplateUrl(params: { mitigationTemplateId: string; orgId: string }) {
  const { mitigationTemplateId, orgId } = params;
  return `${MITIGATION_TEMPLATE_PATH}?mitigationTemplateId=${mitigationTemplateId}&orgId=${orgId}`;
}
