import { Chip, useTheme, ChipProps } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { COLOR_MAPPING, TColorName } from './constants';

export default function ColoredChip({
  colorName,
  label,
  sx,
  size = 'small',
  ...props
}: { colorName?: TColorName } & ChipProps) {
  const theme = useTheme();

  const color = COLOR_MAPPING[colorName]?.(theme);

  let isShowBorder = false;
  if (color) {
    if ('borderColor' in color) {
      isShowBorder = true;
    }
  }

  return (
    <Chip
      sx={{
        bgcolor: color?.fillColor,
        color: color?.textColor,
        padding: `${tokens.spacing3xs} ${tokens.spacing2xs}`,
        // TODO: change to border tokens
        border: isShowBorder && `1px solid ${color?.borderColor}`,
        '& .MuiChip-deleteIcon': { color: color?.textColor },
        ...sx,
      }}
      label={label}
      size={size}
      {...props}
    />
  );
}
