'use client';

import Layout from '@/components/Layout';
import createEmotionCache from '@/createEmotionCache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@verifime/design-tokens/dist/tokens.css';
import { ToggleTheme } from '@verifime/styles';
import '@verifime/styles/styles/globals.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  MITIGATION_TEMPLATE_PATH,
  NAV_ITEM_ASSESSMENTS,
  NAV_ITEM_CONFIGURATION,
  NAV_ITEM_CUSTOMERS,
  NAV_ITEM_HOME,
  RULE_PATH,
} from '../path';

import { Passwordless, PasswordlessContextProvider } from '@verifime/cognito-passwordless-auth';
import {
  ConfirmProvider,
  ErrorNotification,
  FavIcons,
  GlobalStyleOverride,
  SuccessNotification,
} from '@verifime/components';
import { isClient } from '@verifime/utils';
import { SnackbarProvider } from 'notistack';
import AuthenticatedContainer from '../components/AuthenticatedContainer';
import { SWRProvider } from '../services/hooks';
import '../services/index';
import { APPLICATION_NAME } from '../utils/constants';
import { LANDING_DESCRIPTION } from '../utils/messages';

if (isClient) {
  Passwordless.configure({
    cognitoIdpEndpoint: process.env.NEXT_PUBLIC_COGNITO_IDP_ENDPOINT,
    clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USERPOOL_ID,
    storage: window.localStorage,
    fido2: {
      baseUrl: process.env.NEXT_PUBLIC_FIDO2_BASE_URL,
      authenticatorSelection: {
        userVerification: 'required',
      },
      rp: {
        id: process.env.NEXT_PUBLIC_FIDO2_RELYING_PARTY_ID, // default is not set and fallback to hostname e.g. localhost
      },
    },
    totp: {
      baseUrl: process.env.NEXT_PUBLIC_TOTP_BASE_URL,
      issuer: process.env.NEXT_PUBLIC_TOTP_ISSUER ?? 'VerifiMeDev',
    },
    debug: console.debug,
  });
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } });

const PATH_WITH_LAYOUT: string[] = [
  NAV_ITEM_HOME.path,
  NAV_ITEM_ASSESSMENTS.path,
  NAV_ITEM_CUSTOMERS.path,
  NAV_ITEM_CONFIGURATION.path,
  MITIGATION_TEMPLATE_PATH,
  RULE_PATH,
  `${NAV_ITEM_CUSTOMERS.path}/[customerId]`, // customer details page
  `${NAV_ITEM_ASSESSMENTS.path}/[id]`, // Assessment details page
];

type TAppProps = {
  emotionCache: EmotionCache;
} & AppProps;

const App = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: TAppProps) => {
  const router = useRouter();

  return (
    <SWRProvider>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{APPLICATION_NAME}</title>
            <meta name="description" content={LANDING_DESCRIPTION} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <FavIcons />
          </Head>

          <ToggleTheme>
            <GlobalStyleOverride />
            <SnackbarProvider
              Components={{
                successNotification: SuccessNotification,
                errorNotification: ErrorNotification,
              }}
            >
              <ConfirmProvider>
                <PasswordlessContextProvider enableLocalUserCache={true}>
                  {PATH_WITH_LAYOUT.includes(router.pathname) ? (
                    <AuthenticatedContainer>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </AuthenticatedContainer>
                  ) : (
                    <Component {...pageProps} />
                  )}
                </PasswordlessContextProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ToggleTheme>
        </CacheProvider>
      </QueryClientProvider>
    </SWRProvider>
  );
};

export default App;
