import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
  alpha,
} from '@mui/material';

import tokens from '@verifime/design-tokens';
import React from 'react';
import { SearchAutocomplete } from '../index';
import { TOrganisation } from './types';

type TOptionType = TOrganisation | string;

type TAutoCompleteHandlers = {
  handleInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  handleValueChange?: (
    event: React.SyntheticEvent,
    value: TOrganisation,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TOrganisation>,
  ) => void;
};

export type TOrganisationSearchAutoCompleteProps = {
  isSearching?: boolean;
  options?: TOrganisation[];
  label?: string;
} & TAutoCompleteHandlers;

export default function OrganisationSearchAutoComplete({
  isSearching = false,
  options = [],
  label="Search organisations by name or id",
  handleInputChange,
  handleValueChange,
}: TOrganisationSearchAutoCompleteProps) {
  const [searchName, setSearchName] = React.useState('');
  const [selectedOrganisation, setSelectedOrganisation] = React.useState<TOrganisation>(undefined);

  return (
    <SearchAutocomplete
      fullWidth
      filterOptions={(x) => x}
      options={options}
      label={label}
      value={selectedOrganisation}
      freeSolo={searchName === '' ? true : false}
      loading={isSearching}
      loadingText="Searching organisations"
      noOptionsText="No organisation found"
      groupBy={(option) => option.reportingEntityName}
      renderGroup={(params) => (
        <Box component="li" key={params.key} paddingX={tokens.spacingBase}>
          <Typography variant="caption">{params.group}</Typography>
          <Box
            component="ul"
            sx={{
              paddingLeft: 0,
            }}
          >
            {params.children}
          </Box>
        </Box>
      )}
      onChange={(event, value, reason, details) => {
        setSelectedOrganisation(value as TOrganisation);
        handleValueChange?.(event, value as TOrganisation, reason, details);
      }}
      renderOption={(props, organisation, state) => {
        return (
          <React.Fragment key={organisation.organisationId}>
            <MenuItem
              key={organisation.organisationId}
              selected={state.selected}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.2),
                },
              }}
              {...props}
            >
              <ListItemText>
                <Typography>{organisation.subEntityName}</Typography>
              </ListItemText>
            </MenuItem>
          </React.Fragment>
        );
      }}
      getOptionLabel={(organisation) =>
        typeof organisation !== 'string' ? organisation.subEntityName : organisation
      }
      onInputChange={(_event, organisationName, reason) => {
        setSearchName(organisationName);
        if (typeof handleInputChange !== 'undefined') {
          handleInputChange(_event, organisationName, reason);
        }
      }}
      isOptionEqualToValue={(option, value) => {
        return option.organisationId === value.organisationId;
      }}
    />
  );
}
