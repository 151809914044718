import { Divider, Stack, Typography } from '@mui/material';
import {
  TSupportDocument,
  TSupportDocumentSubType,
  TWholesaleCertificate,
  apiSchemas,
} from '@verifime/api-definition';
import {
  Alert,
  DocumentUploaderServiceCallsProps,
  InformationDisplay,
  VerificationStatus,
  WholesaleCertificate,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { TStatus } from '@verifime/utils';
import React, { HTMLAttributeAnchorTarget, useEffect, useState } from 'react';
import {
  SupportDocumentTypeOptions,
  generateDisplayRowsAndFieldsAndRules,
  getKeyAttributeLabel,
} from './utils';

interface SupportDocumentDetailsViewProps {
  supportDocumentType: TSupportDocumentSubType;
  data: TSupportDocument[];
  certificateHolder: {
    holderName: string;
    holderLink: {
      href: string;
      target?: HTMLAttributeAnchorTarget;
    };
  };
  showVerifiedOnly?: boolean;
  UploadView?: React.ComponentType<{
    entityId: string;
    document: TSupportDocument;
    rejected?: boolean;
    onSubmit: (data: TSupportDocument) => void;
    documentUploadServiceCalls: DocumentUploaderServiceCallsProps;
  }>;
  documentUploadServiceCalls?: DocumentUploaderServiceCallsProps;
  entityId?: string;
}

export default function SupportDocumentDetailsView({
  supportDocumentType,
  data,
  certificateHolder,
  UploadView,
  entityId,
  documentUploadServiceCalls,
}: SupportDocumentDetailsViewProps) {
  const [hasMultipleDocuments, setHasMultipleDocuments] = useState(false);
  const [documents, setDocuments] = useState(data);

  useEffect(() => {
    setHasMultipleDocuments(documents.length > 1);
  }, [documents]);

  useEffect(() => {
    setDocuments(data);
  }, [data]);

  const updatedFile = (data: TSupportDocument) => {
    setDocuments((prevDocuments) => {
      return prevDocuments.map((doc) => (doc.id === data.id ? data : doc));
    });
  };

  const detailView = (document: TSupportDocument, displayKeyAttribute: boolean) => {
    switch (document.status) {
      case apiSchemas.EntityStatus.Enum.InProgress:
        return <Alert severity="info"> We are verifying the document at the moment. </Alert>;
      case apiSchemas.EntityStatus.Enum.Draft:
        return <></>;
      case apiSchemas.EntityStatus.Enum.Rejected:
        return UploadView && entityId ? (
          <UploadView
            document={document}
            entityId={entityId}
            onSubmit={updatedFile}
            rejected={true}
            documentUploadServiceCalls={documentUploadServiceCalls}
          />
        ) : (
          <Alert severity="error"> We were unable to verify the document provided. </Alert>
        );
      case apiSchemas.EntityStatus.Enum.Verified:
        return (
          <Stack gap={tokens.spacingBase}>
            {(document as TWholesaleCertificate)?.accountantNumber !== undefined ? (
              <WholesaleCertificate
                holder={certificateHolder}
                accountantNumber={document.accountantNumber as string}
                dateIssued={document.dateIssued as string}
                dateOfExpiry={document.dateOfExpiry as string}
              />
            ) : (
              <InformationDisplay<TSupportDocument>
                informationDisplaysRows={
                  generateDisplayRowsAndFieldsAndRules({
                    subType: document.supportDocumentSubType,
                    displayKeyAttribute: displayKeyAttribute,
                    keyAttribute: document.keyAttributeName,
                  }).displayRows
                }
                data={document}
              />
            )}
            {/* Will support the following for verified document later
          <UploadView document={document} entityId={identity.id} onSubmit={updatedFile} /> */}
          </Stack>
        );
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={tokens.spacing2xs}>
          <Typography variant="h6">
            {SupportDocumentTypeOptions[supportDocumentType].type}
          </Typography>
          <Typography variant="body2">
            {SupportDocumentTypeOptions[supportDocumentType].description}
          </Typography>
        </Stack>
        {!hasMultipleDocuments && <VerificationStatus status={documents[0].status as TStatus} />}
      </Stack>
      {documents.map((document, index) => (
        <Stack key={document.id} gap={tokens.spacingBase}>
          {hasMultipleDocuments && (
            <Stack direction="row" gap={tokens.spacing2xs} justifyContent="space-between">
              <Stack direction="row" gap={tokens.spacing2xs}>
                {document.keyAttributeName && document[document.keyAttributeName] && (
                  <>
                    <Typography color="textSecondary" variant="body2">
                      {getKeyAttributeLabel(supportDocumentType, document.keyAttributeName)}
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {document[document.keyAttributeName] as string}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack alignItems="flex-end">
                <VerificationStatus status={document.status as TStatus} />
              </Stack>
            </Stack>
          )}
          {detailView(document, !hasMultipleDocuments)}
          {index < documents.length - 1 && <Divider />}
        </Stack>
      ))}
    </>
  );
}
