import Stack, { StackProps } from '@mui/material/Stack';
import { DisplayVariant } from './utils';
import tokens from '@verifime/design-tokens';

type TContainerProps = { children: React.ReactNode };
export function createFormFieldStack(
  variant: DisplayVariant,
  stackProps: StackProps = {},
): React.FunctionComponent<TContainerProps> {
  return ({ children }: TContainerProps) => {
    return (
      <Stack
        {...stackProps}
        direction={variant === DisplayVariant.MULTIPLE_COLUMNS ? 'row' : 'column'}
      >
        {children}
      </Stack>
    );
  };
}
