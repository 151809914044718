import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import { ColoredIcon, IconLinkOpenNew } from '../index';
import React from 'react';
import LoginAlert from './common/LoginAlert';
import { LEARN_MORE_AUTHENTICATOR_URL } from '@verifime/utils';

type OTPSettingsProps = {
  hasOtp: boolean;
  error?: Error;
  handleOtpSetup: () => void;
};

function OTPSettingsHeading() {
  return (
    <Stack spacing={tokens.spacing2xs}>
      <Typography variant="h6">Authenticator App</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Sign-in to your Verifime account securely with an authenticator app eg. Google
        Authenticator, Microsoft Authenticator, Yubico Authenticator etc.{' '}
      </Typography>
      <Box alignSelf="flex-start">
        <IconLinkOpenNew href={LEARN_MORE_AUTHENTICATOR_URL}>Learn more</IconLinkOpenNew>
      </Box>
    </Stack>
  );
}

function OTPSettingDetails({ hasOtp, handleOtpSetup }: Omit<OTPSettingsProps, 'error'>) {
  return (
    <>
      {hasOtp ? (
        <Stack direction="row" justifyContent="space-between" mt={tokens.spacingBase}>
          <Stack direction="row" alignItems="center" spacing={tokens.spacing2xs}>
            <ColoredIcon iconName="checkCircleRounded" colorName="successMain" />
            <Typography variant="body1">Two-factor authentication is set up</Typography>
          </Stack>
          <Button
            size="small"
            variant="text"
            startIcon={<ColoredIcon iconName="editRounded" />}
            onClick={handleOtpSetup}
          >
            Change
          </Button>
        </Stack>
      ) : (
        <Box mt={tokens.spacingXl}>
          <Button
            size="medium"
            variant="outlined"
            startIcon={<ColoredIcon iconName="addRounded" />}
            onClick={handleOtpSetup}
          >
            Add New
          </Button>
        </Box>
      )}
    </>
  );
}

export default function OTPSettings(props: OTPSettingsProps) {
  const { error } = props;

  return (
    <Stack>
      <OTPSettingsHeading />
      {error ? (
        <LoginAlert severity="error">Failed to retrieve authenticator app settings</LoginAlert>
      ) : (
        <OTPSettingDetails {...props} />
      )}
    </Stack>
  );
}
