'use client';
import { Box } from '@mui/material';
import CompactIdentityCard from './CompactIdentityCard';
import { TIdentity } from '../types';
import Carousel from '../Carousel';
import tokens from '@verifime/design-tokens';
import { useState } from 'react';

export default function CarouselIdentityCards({
  identities,
  initialSelectedIdentity,
  onIdentitySelect,
}: {
  identities: TIdentity[];
  initialSelectedIdentity?: TIdentity;
  onIdentitySelect?: (identity: TIdentity) => void;
}) {
  const [selectedIdentity, setSelectedIdentity] = useState<TIdentity>(
    initialSelectedIdentity || identities[0],
  );

  const selectedIdentityIndex = identities.findIndex(
    (identity) => identity.id === selectedIdentity.id,
  );

  return (
    <Carousel
      showArrow="hide-on-edge"
      keenSliderOptions={{
        initial: selectedIdentityIndex,
        loop: true,
        slides: { origin: 'center', perView: 'auto', spacing: 12 },
      }}
      leftArrowStyle={{ left: '2%' }}
      rightArrowStyle={{ right: '2%' }}
    >
      {identities.map((identity) => (
        <Box
          sx={{
            display: 'flex',
            // Tells keen-slider use those specific ones instead of calculate by itself
            minWidth: `${tokens.size3xs} !important`,
            maxWidth: `${tokens.size3xs} !important`,
          }}
          className="keen-slider__slide"
          key={identity.id}
        >
          <CompactIdentityCard
            identity={identity}
            isSelected={identity.id === selectedIdentity?.id}
            onClick={(identity) => {
              onIdentitySelect(identity);
              setSelectedIdentity(identity);
            }}
          />
        </Box>
      ))}
    </Carousel>
  );
}
