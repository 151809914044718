import { Passwordless as AwsCognitoPasswordless } from 'amazon-cognito-passwordless-auth';
export {
  PasswordlessContextProvider,
  usePasswordless,
  useLocalUserCache,
} from './components/hooks/usePasswordless';
export { default as PasswordlessLogin } from './components/PasswordlessLogin';
export { default as PasswordlessInitialSetup } from './components/PasswordlessInitialSetup';
export { default as PasskeySettings } from './components/PasskeySettings';
import { configure as configureOtp } from './otp';

export { default as useTokens } from './components/hooks/useTokens';
export { default as OTPSettings } from './components/OTPSettings';
export { default as DisplayCredentialSetupReminder } from './components/DisplayCredentialSetupReminder';

export { getSignedInCustomerEmail, getLastSignedInCustomerEmail } from './utils';

type TAwsCognitoPasswordlessConfig = Parameters<typeof AwsCognitoPasswordless.configure>[0];
type TPasswordlessOtpConfig = Parameters<typeof configureOtp>[0];
function passwordlessConfigure(config?: TAwsCognitoPasswordlessConfig & TPasswordlessOtpConfig) {
  return {
    ...AwsCognitoPasswordless.configure(config),
    ...configureOtp(config),
  };
}

export const Passwordless = {
  configure: passwordlessConfigure,
  configureFromAmplify: AwsCognitoPasswordless.configureFromAmplify,
};
