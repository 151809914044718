import { Outfit } from 'next/font/google';
import { Theme, Components } from '@mui/material/styles';
import tokens from '@verifime/design-tokens';

export const font = Outfit({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

export const typographyTheme = {
  fontFamily: font.style.fontFamily,
  h1: {
    fontSize: tokens.fontSize8xl,
    fontWeight: tokens.fontWeightLight,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  h2: {
    fontSize: tokens.fontSize7xl,
    fontWeight: tokens.fontWeightLight,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  h3: {
    fontSize: tokens.fontSize4xl,
    fontWeight: tokens.fontWeightSemibold,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  h4: {
    fontSize: tokens.fontSize2xl,
    fontWeight: tokens.fontWeightSemibold,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  h5: {
    fontSize: tokens.fontSizeXl,
    fontWeight: tokens.fontWeightSemibold,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  h6: {
    fontSize: tokens.fontSizeBase,
    fontWeight: tokens.fontWeightSemibold,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  body1: {
    fontSize: tokens.fontSizeBase,
    fontWeight: tokens.fontWeightRegular,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  body2: {
    fontSize: tokens.fontSizeSm,
    fontWeight: tokens.fontWeightRegular,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  subtitle1: {
    fontSize: tokens.fontSizeSm,
    fontWeight: tokens.fontWeightRegular,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  subtitle2: {
    fontSize: tokens.fontSizeXs,
    fontWeight: tokens.fontWeightSemibold,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  overline: {
    fontSize: tokens.fontSizeXs,
    fontWeight: tokens.fontWeightRegular,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  caption: {
    fontSize: tokens.fontSizeXs,
    fontWeight: tokens.fontWeightRegular,
    letterSpacing: tokens.letterSpacingReg,
    lineHeight: tokens.lineHeightShort,
  },
  // Override for default button (size: medium)
  button: {
    fontSize: tokens.fontSizeSm,
    fontWeight: tokens.fontWeightSemibold,
    letterSpacing: tokens.letterSpacingLg,
    lineHeight: tokens.lineHeightDouble,
  },
};

export const paletteGrey = {
  '50': tokens.colorGrey050,
  '100': tokens.colorGrey100,
  '200': tokens.colorGrey200,
  '300': tokens.colorGrey300,
  '400': tokens.colorGrey400,
  '500': tokens.colorGrey500,
  '600': tokens.colorGrey600,
  '700': tokens.colorGrey700,
  '800': tokens.colorGrey800,
  '900': tokens.colorGrey900,
  A100: tokens.colorGreyA100,
  A200: tokens.colorGreyA200,
  A400: tokens.colorGreyA400,
  A700: tokens.colorGreyA700,
};

export const commonComponentsOverride: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    variants: [
      {
        props: { size: 'small' },
        style: {
          fontSize: tokens.fontSizeXs,
          fontWeight: tokens.fontWeightSemibold,
          letterSpacing: tokens.letterSpacingLg,
          lineHeight: tokens.lineHeightDouble,
        },
      },
      {
        props: { size: 'large' },
        style: {
          fontSize: tokens.fontSizeBase,
          fontWeight: tokens.fontWeightSemibold,
          letterSpacing: tokens.letterSpacingLg,
          lineHeight: tokens.lineHeightDouble,
        },
      },
    ],
  },
};
