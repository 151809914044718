import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  ColoredIcon,
  FlexPaper,
  IdentityStatusChip,
  determinCustomerIdentityStatus,
  determinIdentityType,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import {
  TCustomer,
  TIdentityTypeCode,
  TOrganisation,
  getAssociatedPartiesSummaryByIdentityType,
} from '@verifime/utils';
import { HTMLAttributeAnchorTarget } from 'react';

type TAssociatedPartyItem = { id: string; customer: TCustomer };
type TAssociatedPartyItems = TAssociatedPartyItem[];
type TDrillDown = {
  href: (itemId: string, identityTypeCode: TIdentityTypeCode) => string;
  target?: HTMLAttributeAnchorTarget;
};

const AssociatedParty = ({
  partyName,
  items,
  drillDown,
}: {
  partyName: string;
  items: TAssociatedPartyItems;
  drillDown: TDrillDown;
}) => {
  if ((items?.filter(Boolean)?.length ?? 0) < 1) {
    return;
  }

  return (
    <Stack gap={tokens.spacingBase}>
      <Typography variant="h6" sx={{ pt: tokens.spacingSm }}>
        {partyName}
      </Typography>
      <Stack direction="row" gap={tokens.spacingBase} sx={{ flexWrap: 'wrap' }}>
        {items.map((item) => {
          return (
            <FlexPaper
              key={item.id}
              sx={{ flexDirection: 'row', width: '100%', maxWidth: tokens.sizeXs }}
            >
              <Stack direction="column" sx={{ flex: 'auto', gap: tokens.spacingSm }}>
                <Box>
                  <Typography variant="overline" color="textSecondary">
                    {determinIdentityType(item.customer)}
                  </Typography>
                  <Typography variant="h6">{item.customer.customerName}</Typography>
                </Box>
                <IdentityStatusChip status={determinCustomerIdentityStatus(item.customer)} />
              </Stack>
              <Box sx={{ alignSelf: 'center' }}>
                <IconButton
                  target={drillDown.target}
                  href={drillDown.href(item.id, item.customer.entityType as TIdentityTypeCode)}
                >
                  <ColoredIcon iconName="arrowRightRounded" iconSize="medium" />
                </IconButton>
              </Box>
            </FlexPaper>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default function AssociatedPartiesPanel({
  identity,
  drillDown: drillDownHref,
}: {
  identity: TOrganisation;
  drillDown: TDrillDown;
}) {
  const parties = getAssociatedPartiesSummaryByIdentityType(
    identity.entityType as TIdentityTypeCode,
  );

  if (parties.length < 1) {
    return null;
  }

  return (
    <Stack gap={tokens.spacingBase}>
      {parties.map((party) => {
        const typedFieldName = party.fieldName as keyof typeof identity;
        const partyItems = Array.isArray(identity[typedFieldName])
          ? (identity[typedFieldName] as TAssociatedPartyItems)
          : [identity[typedFieldName] as TAssociatedPartyItem];
        return (
          <AssociatedParty
            key={party.fieldName}
            partyName={party.label}
            items={partyItems}
            drillDown={drillDownHref}
          />
        );
      })}
    </Stack>
  );
}
