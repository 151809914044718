'use client';

import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';

export type TSignUpCodeInputProps = {
  initalSignUpCode?: string;
  onSignUpCodeChange?: (signUpCode: string) => void;
  isDisabled?: boolean;
};

export default function SignUpCodeInput({
  initalSignUpCode,
  onSignUpCodeChange,
  isDisabled,
}: Readonly<TSignUpCodeInputProps>) {
  const [hasSignUpCode, setHasSignUpCode] = useState(false);
  const [signUpCode, setSignUpCode] = useState<string>('');

  useEffect(() => {
    if (!initalSignUpCode) {
      return;
    }
    setSignUpCode(initalSignUpCode);
    setHasSignUpCode(true);
  }, [initalSignUpCode]);

  return (
    <Stack width="100%" gap={tokens.spacingLg} alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            checked={hasSignUpCode}
            disabled={isDisabled}
            onChange={(event) => setHasSignUpCode(event.target.checked)}
          />
        }
        label="I have an invite code from my service provider"
      />

      {hasSignUpCode && (
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          type="text"
          autoFocus
          label="Invite code"
          value={signUpCode}
          onChange={(e) => {
            const signUpCode = e.target.value as string;
            setSignUpCode(signUpCode);
            onSignUpCodeChange?.(signUpCode);
          }}
          sx={{
            marginTop: tokens.spacingLg,
          }}
          disabled={isDisabled}
        />
      )}
    </Stack>
  );
}
