import { Box, BoxProps } from '@mui/material';
import tokens from '@verifime/design-tokens';

export default function FlexBox({
  fullWidth,
  direction = 'row',
  ...props
}: { fullWidth?: boolean; direction?: 'row' | 'column' } & BoxProps) {
  return (
    <Box
      gap={tokens.spacingXs}
      {...props}
      sx={{
        width: fullWidth ? '100%' : 'auto',
        display: 'flex',
        flexDirection: direction === 'row' ? 'row' : 'column',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
}
