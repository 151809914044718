import { FormControl, RadioGroup, RadioGroupProps, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React, { ReactNode } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { formFieldLabel } from './utils';

type TFormRadioGroupProps = {
  name: string;
  label: string;
  control: Control<FieldValues, any>;
  children: ReactNode;
  required?: boolean;
  defaultValue?: string;
  dataCy?: string;
  onRadioChange?: (value: string) => void;
} & Omit<RadioGroupProps, 'defaultValue' | 'defaultChecked' | 'required'>;

export default function FormRadioGroup({
  name,
  control,
  label,
  children,
  required,
  defaultValue,
  onRadioChange,
  dataCy,
  ...props
}: TFormRadioGroupProps) {
  const requiredLabel = formFieldLabel(label, required);
  return (
    <FormControl component="fieldset" sx={{ gap: tokens.spacingXs }}>
      <Typography variant="h6">{requiredLabel}</Typography>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup
            {...field}
            {...props}
            sx={{ flexDirection: 'row' }}
            data-cy={dataCy}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
              field.onChange(event);
              // This customized event must be called last to allow field level onChange event propagate first.
              onRadioChange?.(value);
            }}
          >
            {children}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
