import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { TErrors } from './utils';

export default function DisplayErrors({
  errors,
  ...props
}: {
  errors: TErrors;
} & BoxProps) {
  const theme = useTheme();

  const validErrors = errors?.filter(Boolean);

  if (validErrors.length < 1) {
    return null;
  }

  return (
    <Box {...props}>
      {errors?.map((error, index) => (
        <Typography key={index} sx={{ color: theme.palette.error.main }}>
          {error?.message}
        </Typography>
      ))}
    </Box>
  );
}
