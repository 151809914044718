'use client';

import React from 'react';

export type TContextStore<TContext> = {
  context: TContext;
  setContext: React.Dispatch<Partial<TContext>>;
};

export function createContextStore<TContext>(defaultContext: TContext): TContextStore<TContext> {
  const [context, setContext] = React.useReducer(
    (context: TContext, newContext: Partial<TContext>) => ({
      ...context,
      ...newContext,
    }),
    defaultContext,
  );
  return {
    context,
    setContext,
  };
}
