'use client';

import { useState } from 'react';
import { TextField, TextFieldProps, SxProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { max, min } from 'date-fns';
import FlexBox from '../FlexBox';
import tokens from '@verifime/design-tokens';
import { TFormDateRangeLabels } from './utils';
import { stringUtils } from '@verifime/utils';

export type TFormDateRangeRequired = 'none' | 'both' | 'start' | 'end';

type TFormDateRangeProps = {
  name: string;
  labels: TFormDateRangeLabels;
  control: Control<FieldValues, any>;
  inputFormat?: string;
  required?: TFormDateRangeRequired;
  defaultValues?: Partial<TFormDateRangeLabels>;
  maxDate?: Date;
  minDate?: Date;
  containerStyle?: SxProps;
  margin?: TextFieldProps['margin'];
  size?: TextFieldProps['size'];
  onDateRangeChange?: (dateFieldType: 'start' | 'end', date: Date) => void;
} & Omit<TextFieldProps, 'defaultValue' | 'required'>;

export default function FormDateRange({
  name,
  labels,
  control,
  inputFormat = 'dd/MM/yyyy',
  required = 'none',
  defaultValues,
  maxDate,
  minDate,
  containerStyle,
  margin = 'normal',
  size = 'medium',
  onDateRangeChange,
  ...props
}: TFormDateRangeProps) {
  const [startValue, setStartValue] = useState<Date | null>(null);
  const [endValue, setEndValue] = useState<Date | null>(null);
  const [isOpenEndDatePicker, setIsOpenEndDatePicker] = useState(false);

  const requiredStart = ['both', 'start'].includes(required);
  const requiredEnd = ['both', 'end'].includes(required);

  return (
    <FlexBox sx={{ flexDirection: ['column', 'row'], gap: tokens.spacingBase, ...containerStyle }}>
      <Controller
        name={stringUtils.generateCamelCaseString(name, labels.start)}
        defaultValue={defaultValues?.start || ''}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                {...field}
                label={labels.start}
                inputFormat={inputFormat}
                maxDate={min([maxDate, endValue, field.value].filter(Boolean))}
                minDate={minDate}
                onAccept={() => setIsOpenEndDatePicker(true)}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...props}
                    margin={margin}
                    size={size}
                    // To make sure sx not to be override, just spared outer sx into it
                    sx={{
                      flex: '1',
                      width: '100%',
                      marginTop: tokens.spacingBase,
                      marginBottom: tokens.spacingXs,
                      ...props.sx,
                    }}
                    required={requiredStart}
                    value={field.value || startValue}
                    // We do not wanna those two to be override
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
                // Validation is not fired with the default react-hook-form mode. So we need this custom onChange event handling.
                onChange={(date) => {
                  field.onChange(date);
                  setStartValue(date);
                  onDateRangeChange?.('start', date);
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
      <Controller
        name={stringUtils.generateCamelCaseString(name, labels.end)}
        control={control}
        defaultValue={defaultValues?.end || ''}
        render={({ field, fieldState }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              label={labels.end}
              inputFormat={inputFormat}
              maxDate={maxDate}
              minDate={max([minDate, startValue, field.value].filter(Boolean))}
              open={isOpenEndDatePicker}
              onOpen={() => setIsOpenEndDatePicker(true)}
              onAccept={() => setIsOpenEndDatePicker(false)}
              onClose={() => setIsOpenEndDatePicker(false)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  {...props}
                  margin={margin}
                  size={size}
                  // To make sure sx not to be override, just spared outer sx into it
                  sx={{
                    flex: '1',
                    width: '100%',
                    marginTop: tokens.spacingBase,
                    marginBottom: tokens.spacingXs,
                    ...props.sx,
                  }}
                  required={requiredEnd}
                  value={field.value || endValue}
                  // We do not wanna those two to be override
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              // Validation is not fired with the default react-hook-form mode. So we need this custom onChange event handling.
              onChange={(date) => {
                field.onChange(date);
                setEndValue(date);
                onDateRangeChange?.('end', date);
              }}
            />
          </LocalizationProvider>
        )}
      />
    </FlexBox>
  );
}
