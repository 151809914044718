import { Box, Typography, Stack, Chip, useTheme } from '@mui/material';
import IdentityAvatarIcon from './IdentityAvatarIcon';
import { TIdentity } from '../types';
import IdentityStatusChip from './IdentityStatusChip';
import tokens from '@verifime/design-tokens';

export default function CompactIdentityCard({
  identity,
  isSelected,
  onClick,
}: {
  identity: TIdentity;
  isSelected?: boolean;
  onClick?: (identity: TIdentity) => void;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: tokens.size3xs,
        borderRadius: tokens.borderRadiusLg,
        border: `2px solid ${
          isSelected ? theme.palette.primary.main : theme.palette.action.disabledBackground
        }`,
        padding: tokens.spacingSm,
        gap: tokens.spacingSm,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        marginBottom: tokens.spacingLg,
      }}
      onClick={() => onClick(identity)}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          flex: 1,
        }}
      >
        <IdentityAvatarIcon type={identity.type} variant="circle" />
        <Box sx={{ position: 'absolute', bottom: '-5%', left: '20%' }}>
          <IdentityStatusChip status={identity.status} isCompact={true} />
        </Box>
      </Stack>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          paddingBottom: tokens.spacingBase,
          textAlign: 'center',
          flex: 2,
          wordBreak: 'break-all',
        }}
      >
        {identity.name}
      </Typography>
      {isSelected && (
        <Chip
          label="Selected"
          color="primary"
          sx={{
            position: 'absolute',
            bottom: `-${tokens.spacingBase}`,
          }}
        />
      )}
    </Stack>
  );
}
