import { Button, Stack } from '@mui/material';
import { TDocumentType } from '@verifime/api-definition';
import {
  CircularProgressButton,
  TFilesUploadStatus,
  useDocumentUploader,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { DocumentType } from '@verifime/utils';
import { useState } from 'react';
import { DocumentUploaderServiceCallsProps } from '../MultiFileUploader/useDocumentUploader';

interface UploadForVerificationProps {
  entityId: string;
  documentType: TDocumentType;
  onSubmit: (fileId: string) => Promise<void>;
  documentUploadServiceCalls: DocumentUploaderServiceCallsProps;
  onClose?: () => void;
  submitButtonText?: string;
  isShowCancelButton?: boolean;
}

export default function UploadForVerification({
  entityId,
  documentType,
  onClose,
  onSubmit,
  documentUploadServiceCalls,
  submitButtonText = 'Submit for Verification',
  isShowCancelButton = true,
}: Readonly<UploadForVerificationProps>) {
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const [fileId, setFileId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const completeUpload = (res: TFilesUploadStatus) => {
    setFileId(Object.keys(res)[0]);
    setEnableSubmitButton(true);
  };

  const { renderedComponent } = useDocumentUploader({
    entityId: entityId,
    documentType: documentType as DocumentType,
    label: 'Click to upload',
    isMultiple: false,
    isDraggable: true,
    ignoreExistingFiles: true,
    onAllUploadsDone: completeUpload,
    onFileDeleted: () => {
      setEnableSubmitButton(false);
    },
    api: documentUploadServiceCalls,
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(fileId).finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Stack gap={tokens.spacingBase} alignItems="center" padding={tokens.spacingSm}>
        {renderedComponent}
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={tokens.spacingLg}
      >
        {isShowCancelButton && (
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        )}

        <CircularProgressButton
          variant="contained"
          onClick={handleSubmit}
          isShowProgress={isSubmitting}
          disabled={!enableSubmitButton}
        >
          {submitButtonText}
        </CircularProgressButton>
      </Stack>
    </>
  );
}
