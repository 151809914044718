import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import { IconLinkOpenNew } from './IconLinkOpenNew';

const privacyPolicyUrl = 'https://www.verifime.com/privacy-policy';

export default function About({ appName, commitSha }: { appName: string; commitSha: string }) {
  const version = commitSha.slice(0, 7); // only need to show the first 7 character of the git sha version
  return (
    <Stack gap={tokens.spacing2xs}>
      <Typography variant="h6">About</Typography>
      <Typography variant="body1">
        {appName} version v{version}
      </Typography>
      <IconLinkOpenNew href={privacyPolicyUrl}>Read Privacy Policy</IconLinkOpenNew>
    </Stack>
  );
}
