import * as yup from 'yup';
import { TFromFieldInfo } from '@verifime/components';
import {
  stringUtils,
  basicFieldsPrefix,
  IdentityTypeCode,
  IdentityTypeSMSF,
  TIdentityTypeCode,
  IdentityTypeRegulatedTrust,
  IdentityTypeUnregulatedTrust,
  IdentityTypePrivateCompany,
  IdentityTypePublicCompany,
  TOrganisation,
  IdentityTypeSoleTrader,
  IdentityTypePartnership,
} from '.';

export type TAssociatedParty = TFromFieldInfo & {
  acceptedWalletIdentityTypeCodes: TIdentityTypeCode[];
  acceptedOrgIdentityTypeCodes?: TIdentityTypeCode[];
  readonly?: boolean;
  ownerRequired?: boolean;
};

export const ASSOCIATED_PARTY_TRUSTEES_ID = 'trusteeIds';
export const ASSOCIATED_PARTY_UBO_ID = 'ultimateBeneficialOwnerIds';
export const ASSOCIATED_PARTY_SETTLOR_ID = 'settlorIds';
export const ASSOCIATED_PARTY_DIRECTORS_ID = 'directorIds';
export const ASSOCIATED_PARTY_SOLE_TRADER_ID = 'individual';
export const ASSOCIATED_PARTY_PARTNERS_ID = 'partnerIds';

export const ASSOCIATED_PARTY_LABEL_TRUSTEES = 'Trustees';
export const ASSOCIATED_PARTY_LABEL_UBO = 'Members | Ultimate beneficial owners (UBOs)';
export const ASSOCIATED_PARTY_LABEL_SETTLOR = 'Settlor';
export const ASSOCIATED_PARTY_LABEL_DIRECTORS = 'Directors';
export const ASSOCIATED_PARTY_LABEL_SHAREHOLDERS =
  'Shareholders | Ultimate beneficial owners (UBOs)';
export const ASSOCIATED_PARTY_LABEL_SOLE_TRADER = 'Sole Trader';
export const ASSOCIATED_PARTY_LABEL_PARTNERS = 'Partners';

export const ASSOCIATED_PARTY_TRUSTEES: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_TRUSTEES,
  fieldName: stringUtils.generateCamelCaseString(basicFieldsPrefix, ASSOCIATED_PARTY_TRUSTEES_ID),
  rules: yup.array().min(1, 'Required'),
  dataCy: 'text-array-trustee-ids',
  helpText: 'Add all individuals or entities who are appointed to make decisions for this entity.',
  acceptedWalletIdentityTypeCodes: [
    IdentityTypeCode.Individual_Domestic,
    IdentityTypeCode.SMSF,
    IdentityTypeCode.Sole_Trader,
    IdentityTypeCode.Partnership,
    IdentityTypeCode.Private_Company,
    IdentityTypeCode.Public_Company,
    IdentityTypeCode.Trust_Regulated,
    IdentityTypeCode.Trust_Unregulated,
    IdentityTypeCode.Charity,
  ],
  acceptedOrgIdentityTypeCodes: [IdentityTypeCode.Private_Company],
};

export const ASSOCIATED_PARTY_TRUST_TRUSTEES = {
  ...ASSOCIATED_PARTY_TRUSTEES,
  acceptedOrgIdentityTypeCodes: [IdentityTypeCode.Private_Company, IdentityTypeCode.Public_Company],
};

export const ASSOCIATED_PARTY_UBO: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_UBO,
  fieldName: stringUtils.generateCamelCaseString(basicFieldsPrefix, ASSOCIATED_PARTY_UBO_ID),
  rules: yup.array(),
  dataCy: 'text-array-ubo-ids',
  helpText: 'Add all individuals who own 25% or more, or ultimately control this entity.',
  acceptedWalletIdentityTypeCodes: [IdentityTypeCode.Individual_Domestic],
};

export const ASSOCIATED_PARTY_SETTLOR: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_SETTLOR,
  fieldName: stringUtils.generateCamelCaseString(basicFieldsPrefix, ASSOCIATED_PARTY_SETTLOR_ID),
  rules: yup.array(),
  dataCy: 'text-array-settlor-ids',
  helpText: 'Required only if the material asset contribution was greater than $10,000',
  maxAcceptedItems: 1,
  acceptedWalletIdentityTypeCodes: [IdentityTypeCode.Individual_Domestic],
};

export const ASSOCIATED_PARTY_DIRECTORS: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_DIRECTORS,
  fieldName: stringUtils.generateCamelCaseString(basicFieldsPrefix, ASSOCIATED_PARTY_DIRECTORS_ID),
  rules: yup.array(),
  dataCy: 'text-array-director-ids',
  helpText: 'Add all individuals or entities who are appointed to make decisions for this entity',
  items: [IdentityTypeCode.Individual_Domestic],
  acceptedWalletIdentityTypeCodes: [IdentityTypeCode.Individual_Domestic],
};

export const ASSOCIATED_PARTY_SHAREHOLDERS: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_SHAREHOLDERS,
  fieldName: stringUtils.generateCamelCaseString(basicFieldsPrefix, ASSOCIATED_PARTY_UBO_ID),
  rules: yup.array(),
  dataCy: 'text-array-shareholders-ids',
  helpText: 'Add all individuals who own 25% or more, or ultimately control this entity.',
  acceptedWalletIdentityTypeCodes: [IdentityTypeCode.Individual_Domestic],
};

export const ASSOCIATED_PARTY_SOLE_TRADER: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_SOLE_TRADER,
  fieldName: stringUtils.generateCamelCaseString(
    basicFieldsPrefix,
    ASSOCIATED_PARTY_SOLE_TRADER_ID,
  ),
  rules: yup.array().min(1, 'Required'),
  maxAcceptedItems: 1,
  dataCy: 'text-array-sole-trader-id',
  acceptedWalletIdentityTypeCodes: [IdentityTypeCode.Individual_Domestic],
  readonly: true,
};

export const ASSOCIATED_PARTY_PARTNERSHIP: TAssociatedParty = {
  label: ASSOCIATED_PARTY_LABEL_PARTNERS,
  fieldName: stringUtils.generateCamelCaseString(basicFieldsPrefix, ASSOCIATED_PARTY_PARTNERS_ID),
  rules: yup.array().min(2, 'Required'),
  dataCy: 'text-array-partners-id',
  helpText:
    "Add all individuals who are partners. Also add the partners' ultimate beneficial owners and controllers if the partners are organisations (e.g. companies, trusts, etc.).",
  acceptedWalletIdentityTypeCodes: [IdentityTypeCode.Individual_Domestic],
  ownerRequired: true,
};

export const ASSOCIATED_PARTIES_MAPPING: {
  [identityType in TIdentityTypeCode]?: TAssociatedParty[];
} = {
  [IdentityTypeSMSF.code]: [
    ASSOCIATED_PARTY_TRUSTEES,
    ASSOCIATED_PARTY_UBO,
    ASSOCIATED_PARTY_SETTLOR,
  ],
  [IdentityTypeRegulatedTrust.code]: [ASSOCIATED_PARTY_TRUST_TRUSTEES],
  [IdentityTypeUnregulatedTrust.code]: [
    ASSOCIATED_PARTY_TRUST_TRUSTEES,
    ASSOCIATED_PARTY_UBO,
    ASSOCIATED_PARTY_SETTLOR,
  ],
  [IdentityTypePrivateCompany.code]: [ASSOCIATED_PARTY_DIRECTORS, ASSOCIATED_PARTY_SHAREHOLDERS],
  [IdentityTypePublicCompany.code]: [ASSOCIATED_PARTY_DIRECTORS, ASSOCIATED_PARTY_SHAREHOLDERS],
  [IdentityTypeSoleTrader.code]: [ASSOCIATED_PARTY_SOLE_TRADER],
  [IdentityTypePartnership.code]: [ASSOCIATED_PARTY_PARTNERSHIP],
};

export function generateAssociatedPartiesCustomersIds(
  associatedParties: TAssociatedParty[],
  customer: TOrganisation,
) {
  let partiesCustomersIds = {};

  if (!associatedParties) {
    return partiesCustomersIds;
  }
  associatedParties.forEach((party) => {
    const partyFieldName = getPartyFieldName(party);
    const partyCustomersFieldName = getPartyCustomersFieldName(party);
    const customerPartyData = customer[partyCustomersFieldName as keyof TOrganisation];
    const partyCustomers = Array.isArray(customerPartyData)
      ? customerPartyData
      : [customerPartyData].filter(Boolean);

    if (partyCustomers.length > 0) {
      partiesCustomersIds = {
        ...partiesCustomersIds,
        [partyFieldName]: partyCustomers.map((item: any) => item.id),
      };
    }
  });
  return partiesCustomersIds;
}

/** Such as `['trustees', 'ultimateBeneficialOwners', 'settlors']` */
export function getAssociatedPartiesFieldsName(associatedParties: TAssociatedParty[]) {
  const partiesFieldsName: string[] = [];
  associatedParties.forEach((party) => {
    const partyCustomersFieldName = getPartyCustomersFieldName(party);
    partiesFieldsName.push(partyCustomersFieldName);
  });
  return partiesFieldsName;
}

/** Such as `trusteeIds` */
export function getPartyFieldName(party: TAssociatedParty) {
  return stringUtils.lowerCaseFirstLetter(party.fieldName.replace(basicFieldsPrefix, ''));
}

/** Such as `trustees` */
export function getPartyCustomersFieldName(party: TAssociatedParty) {
  return getPartyFieldName(party).replace('Id', '');
}

export function getAssociatedPartiesSummaryByIdentityType(identityType: TIdentityTypeCode) {
  const partiesInfo = ASSOCIATED_PARTIES_MAPPING[identityType];
  if (!partiesInfo) {
    return [];
  }

  return partiesInfo.reduce(
    (
      tally: {
        label: string;
        fieldName: string;
      }[],
      party,
    ) => {
      return [...tally, { label: party.label, fieldName: getPartyCustomersFieldName(party) }];
    },
    [],
  );
}
