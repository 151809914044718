import Typography, { TypographyProps } from '@mui/material/Typography';

export default function Title({ ...props }: TypographyProps) {
  return (
    <Typography variant="h5" {...props}>
      {props.children}
    </Typography>
  );
}

export function LoginToVerifiMeTitle() {
  return <Title>Login to VerifiMe</Title>;
}

export function SecureYourAccountTitle() {
  return <Title>Secure your account</Title>;
}
