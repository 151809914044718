import { Button, Stack } from '@mui/material';
import {
  TSupportDocumentSubType,
  TSupportDocumentSubTypeWithAllowedStatus,
} from '@verifime/api-definition';
import { ColoredIcon, SelectItemDrawer } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { SupportDocumentTypeOptions } from '@verifime/utils';
import { useState } from 'react';
import { DocumentUploaderServiceCallsProps } from '../MultiFileUploader/useDocumentUploader';
import UploadDocumentDrawer from './UploadDocumentDrawer';

interface DocumentDrawerProps {
  entityId: string;
  documentOptions: TSupportDocumentSubTypeWithAllowedStatus[];
  submitForVerification: (
    fileId: string,
    setSelectedDocumentType: (value: TSupportDocumentSubType) => void,
    selectedDocumentType: TSupportDocumentSubType,
  ) => Promise<void>;
  documentUploadServiceCalls: DocumentUploaderServiceCallsProps;
}

const generateDocumentOptions = (res: TSupportDocumentSubTypeWithAllowedStatus[]) => {
  return Object.keys(SupportDocumentTypeOptions).map((key) => {
    const subType = key as TSupportDocumentSubType;
    const details = SupportDocumentTypeOptions[subType];
    const allowedItem = res.find((item) => item.supportDocumentSubType === subType);
    return {
      ...details,
      isDisabled: !allowedItem?.allowed,
    };
  });
};

export default function DocumentDrawer({
  entityId,
  documentOptions,
  submitForVerification,
  documentUploadServiceCalls,
}: DocumentDrawerProps) {
  const [showSelectDrawer, setShowSelectDrawer] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState<TSupportDocumentSubType>();

  return (
    <>
      <Stack alignItems="flex-end" sx={{ mt: tokens.spacingXl }}>
        <Button
          variant="contained"
          size="medium"
          startIcon={<ColoredIcon iconName="addRounded" />}
          onClick={() => setShowSelectDrawer(true)}
        >
          Add Document
        </Button>
      </Stack>

      <SelectItemDrawer
        title="Select document type"
        options={generateDocumentOptions(documentOptions)}
        open={showSelectDrawer}
        onSelect={(type) => {
          setSelectedDocumentType(
            Object.entries(SupportDocumentTypeOptions).find(
              ([_, value]) => value.type === type,
            )?.[0] as TSupportDocumentSubType | undefined,
          );
          setShowSelectDrawer(false);
        }}
        onClose={() => setShowSelectDrawer(false)}
      />

      {selectedDocumentType && (
        <UploadDocumentDrawer
          entityId={entityId}
          title="Add your document"
          open={!!selectedDocumentType}
          supportDocumentType={selectedDocumentType}
          onClose={() => setSelectedDocumentType(null)}
          onBack={() => {
            setSelectedDocumentType(null);
            setShowSelectDrawer(true);
          }}
          documentUploadServiceCalls={documentUploadServiceCalls}
          submitForVerification={(fileId) =>
            submitForVerification(fileId, setSelectedDocumentType, selectedDocumentType)
          }
        />
      )}
    </>
  );
}
