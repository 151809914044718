import { default as OpenNewIcon } from '@mui/icons-material/OpenInNewRounded';
import { LinkProps } from '@mui/material/Link';
import { IconLink } from './IconLink';

export function IconLinkOpenNew({ children, ...props }: LinkProps) {
  return (
    <IconLink target="_blank" icon={<OpenNewIcon color="primary" fontSize="inherit" />} {...props}>
      {children}
    </IconLink>
  );
}
