/**
     * @desc Find a list of permissions for a given organisation
This method finds a list of Permissions for a given CLIENT. The caller must have the admin or client role to execute this query.
     */

import * as defs from '../../baseClass';
import * as Hooks from '../../../hooks';

import { getUrl } from '../../../url.utils';

import * as SWR from 'swr';

import { PontCore } from '../../../pontCore';

export class Params {
  /** The organisation id to find the permissions for */
  orgId;
  /** The type of entity to search for */
  entityType;
  /** The format of the response */
  format;
  /** The from date to search for last assessed customers. Deprecated in favour of lastAssessedFromDate */
  fromDate;
  /** The from date to search for last assessed customers */
  lastAssessedFromDate;
  /** The from date to search for last assessed customers. */
  lastAssessedToDate;
  /** The last index of the page to return */
  lastIndex;
  /** The name of the customer to search for */
  name;
  /** The field to order by */
  orderBy;
  /** The direction to order by */
  orderDirection;
  /** The from date to search for when customers given permission to client. */
  permissionFromDate;
  /** The from date to search for when customers given permission to client. */
  permissionToDate;
  /** The risk level of the customer to search for */
  riskLevel;
  /** The start index of the page to return */
  startIndex;
  /** The status(es) of the customer permission to search for which are comma separated. Deprecated in favour of statuses */
  status;
  /** The status(es) of the customer permission to search. */
  statuses;
  /** The from date to search for last assessed customers. Deprecated in favour of lastAssessedToDate */
  toDate;
  /** The wholesale certificate status of the customer to search for */
  wholesaleCertificateStatus;
}

export const method = 'GET';

export function mutate(
  params = {},
  newValue = undefined,
  shouldRevalidate = true,
) {
  return SWR.mutate(
    Hooks.getUrlKey('/v1/permission/organisation/{orgId}', params, 'GET'),
    newValue,
    shouldRevalidate,
  );
}

export function useRequest(params = {}, swrOptions = {}) {
  return Hooks.useRequest(
    '/v1/permission/organisation/{orgId}',
    params,
    swrOptions,
  );
}

export function request(params = {}, option = {}) {
  const { url, body } = getUrl(
    '/v1/permission/organisation/{orgId}',
    params,
    'GET',
    option,
  );
  return PontCore.fetch(url, {
    body,
    ...option,
    method: 'GET',
  });
}
