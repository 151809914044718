import { useEffect, useRef, useState } from 'react';

export default function usePolling({
  callback,
  autoStart = true,
  pollingIntervalInMs = 1000,
}: {
  callback: () => Promise<void>;
  autoStart?: boolean;
  pollingIntervalInMs?: number;
}) {
  const [isKeepPolling, setIsKeepPolling] = useState(autoStart);
  const timerRef = useRef<NodeJS.Timer>();

  const startPolling = () => {
    setIsKeepPolling(true);
  };

  const stopPolling = () => {
    setIsKeepPolling(false);
  };

  useEffect(() => {
    if (!isKeepPolling) {
      clearInterval(timerRef.current);
      return;
    }

    timerRef.current = setInterval(() => callback(), pollingIntervalInMs);

    return () => clearInterval(timerRef.current);
  }, [isKeepPolling, callback, pollingIntervalInMs]);

  return { startPolling, stopPolling };
}
