export const NO_SELECTED_PERMISSION = 'Please select an organisation to grant the permission';
export const NO_ORGANISATION = 'There is no any organisation';
export const NOT_SEARCHED = 'Please search to find organisation';
export const NO_ACTIVE_PERMISSIONS = 'No active permissions';
export const FAILED_TO_LOAD_IDENTITIES = 'Failed to load identities';
export const FAILED_TO_LOAD_RULE = 'Failed to load rule';
export const FAILED_TO_LOAD_MITIGATION_TEMPLATE = 'Failed to load mitigation template';
export const FORM_VALIDATION_FAILED =
  'There are validation errors in the form, please correct the data.';

// Field validation error messages
export const FIELD_VALIDATION_REQUIRED = 'Required';
export const FIELD_VALIDATION_INVALID_POST_CODE =
  'Invalid Postcode, only numbers, letters and spaces are allowed.';

// notification messages
export const IDENTITY_SUBMISSION_SUCCESS =
  'Your identity record has been submitted for verification.';
export const WHOLESALE_CERTIFICATE_SUBMISSION_SUCCESS =
  'Your Wholesale Certificate has been submitted for verification.';
export const PERMISSION_GRANTED_SUCCESS = 'Your permission record has been submitted.';
export const PERMISSION_REVOKED_SUCCESS =
  'Your permission cancellation request has been submitted.';

export const ACTIVE_PERMISSION_CANCEL_CONFIRM = 'Are you sure you wish to cancel this permission?';

export const LANDING_TITLE = 'Client Portal';
export const LANDING_DESCRIPTION =
  'Automate your compliance program without compromising privacy and customer experience';
