import Consent, { consentSchema } from './Consent';
import { useCustomForm } from './useCustomForm';

export { default as FormDate } from './FormDate';
export { default as FormFileInput } from './FormFileInput';
export { default as FormSelect } from './FormSelect';
export { default as FormTextInput } from './FormTextInput';
export { default as FormCheckbox } from './FormCheckbox';
export { default as FormDateRange } from './FormDateRange';
export { default as FormSwitch } from './FormSwitch';
export { default as FormTextEditor } from './FormTextEditor';
export { createFormFieldStack } from './FormFieldsContainerSupportDisplayVariant';
/**
 * TODO:
 *  1. delete existing GenerateFormFields
 *  2. rename the new one to GenerateFormFields
 */
export { default as GenerateFormFields } from './GenerateFormFields';
export { default as GenerateFormFields_New } from './GenerateFormFields_New';
export type { TFieldsOperations } from './GenerateFormFields_New';
export { default as Address, type TAddressProps, type TSameAsAddress } from './Address';
export {
  default as AddressWithCountrySelect,
  type TAddressWithCountrySelectProps,
} from './AddressWithCountrySelect';
export { default as DisplayErrors } from './DisplayErrors';
export { default as DisplayAlertErrors } from './DisplayAlertErrors';
export { default as CircularProgressButton } from './CircularProgressButton';

export * from './utils';
export * from './FormDateRange';

export { useCustomForm, Consent, consentSchema };
