import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import tokens from '@verifime/design-tokens';
import { isBefore } from 'date-fns';
import NoDataFound from '../NoDataFound';
import PermissionListItem, { TPermissionListItemProps } from './PermissionListItem';
import { TPermission } from './types';

export type TPermissionListProps = {
  isLoadingPermissions: boolean;
  permissions: TPermission[];
} & Omit<TPermissionListItemProps, 'permission'>;

export default function PermissionList({
  isLoadingPermissions,
  permissions,
  ...props
}: TPermissionListProps) {
  const showRevokeButton = props.showRevokeButton ?? false;
  const onRevokeSuccess = props.onRevokeSuccess ?? (() => {});
  const sortedPermissions = permissions.sort((a, b) =>
    isBefore(new Date(a.addedDate), new Date(b.addedDate)) ? 1 : -1,
  );

  return (
    <>
      {isLoadingPermissions ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {sortedPermissions.length > 0 ? (
            <Stack gap={tokens.spacingBase}>
              {sortedPermissions.map((permission) => (
                <PermissionListItem
                  key={permission.id}
                  permission={permission}
                  showRevokeButton={showRevokeButton}
                  onRevokeSuccess={onRevokeSuccess}
                  revokePermission={props.revokePermission}
                />
              ))}
            </Stack>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  );
}
