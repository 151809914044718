import {
  EntityStatus,
  IdentityTypeCode,
  TCompany,
  TCustomer,
  TIndividual,
  TPartnership,
  TSoleTrader,
  TTrust,
} from '@verifime/utils';
import { IdentityStatus, IdentityType, TIdentity } from './types';

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export function determinCustomerIdentityStatus(identity: TCustomer): IdentityStatus {
  return determinIdentityStatus(identity.entityStatus);
}

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export function determinIdentityStatus(entityStatus: string): IdentityStatus {
  switch (entityStatus) {
    case EntityStatus.InProgress:
      return IdentityStatus.IN_PROGRESS;
    case EntityStatus.Rejected:
      return IdentityStatus.REJECTED;
    case EntityStatus.Verified:
      return IdentityStatus.VERIFIED;
    case EntityStatus.Empty:
    default:
      return IdentityStatus.DRAFT;
  }
}

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export function determinIdentityType(identity: TCustomer): IdentityType {
  switch (identity.entityType) {
    case IdentityTypeCode.Individual_Domestic:
      return IdentityType.INDIVIDUAL;
    case IdentityTypeCode.Private_Company:
      return IdentityType.PRIVATE_COMPANY;
    case IdentityTypeCode.Public_Company:
      return IdentityType.PUBLIC_COMPANY;
    case IdentityTypeCode.SMSF:
      return IdentityType.SMSF;
    case IdentityTypeCode.Trust_Regulated:
      return IdentityType.REGULATED_TRUST;
    case IdentityTypeCode.Trust_Unregulated:
      return IdentityType.UNREGULATED_TRUST;
    case IdentityTypeCode.Sole_Trader:
      return IdentityType.SOLE_TRADER;
    case IdentityTypeCode.Partnership:
      return IdentityType.PARTNERSHIP;
    default:
      return IdentityType.UNKNOWN;
  }
}

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export function toIdentity(identity: TCustomer): TIdentity {
  switch (identity.entityType) {
    case IdentityTypeCode.Individual_Domestic:
      const person = identity as TIndividual;
      return {
        name: person.customerName,
        type: determinIdentityType(person),
        status: determinCustomerIdentityStatus(person),
        id: person.id,
      };
    case IdentityTypeCode.Private_Company:
    case IdentityTypeCode.Public_Company:
      const company = identity as TCompany;
      return {
        name: company.customerName,
        type: determinIdentityType(company),
        status: determinCustomerIdentityStatus(company),
        id: company.id,
      };
    case IdentityTypeCode.SMSF:
    case IdentityTypeCode.Trust_Regulated:
    case IdentityTypeCode.Trust_Unregulated:
      const trust = identity as TTrust;
      return {
        name: trust.customerName,
        type: determinIdentityType(trust),
        status: determinCustomerIdentityStatus(trust),
        id: trust.id,
      };
    case IdentityTypeCode.Sole_Trader:
      const soleTrader = identity as TSoleTrader;
      return {
        name: soleTrader.customerName,
        type: determinIdentityType(soleTrader),
        status: determinCustomerIdentityStatus(soleTrader),
        id: soleTrader.id,
      };
    case IdentityTypeCode.Partnership:
      const partnership = identity as TPartnership;
      return {
        name: partnership.customerName,
        type: determinIdentityType(partnership),
        status: determinCustomerIdentityStatus(partnership),
        id: partnership.id,
      };
    default:
      return {
        name: 'unknown identity',
        type: determinIdentityType(identity),
        status: determinCustomerIdentityStatus(identity),
        id: identity.id,
      };
  }
}
