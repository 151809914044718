import { ChipProps } from '@mui/material';
import { TRiskLevels } from './types';
import ColoredChip from '../ColoredIconAndChip/ColoredChip';
import { RISK_LEVEL_CHIP_COLOR_MAPPING } from './constant';

export type RiskLevelChipProps = {
  riskLevel: TRiskLevels;
} & Omit<ChipProps, 'variant'>;

export default function RiskLevelChip({ riskLevel, sx, ...props }: RiskLevelChipProps) {
  const riskLevelLabel = riskLevel === 'Pending' ? 'Verification Pending' : riskLevel;
  return (
    <ColoredChip
      colorName={RISK_LEVEL_CHIP_COLOR_MAPPING[riskLevel]}
      label={riskLevelLabel}
      sx={sx}
      {...props}
    />
  );
}
