import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import tokens from '@verifime/design-tokens';

export default function LoginButton({
  isShowProgress = false,
  ...props
}: { isShowProgress?: boolean } & ButtonProps) {
  return (
    <Button
      size="large"
      color="primary"
      fullWidth
      {...props}
      sx={{
        gap: tokens.spacingXs,
        ...props.sx,
      }}
    >
      {isShowProgress && <CircularProgress color="inherit" size={tokens.fontSizeBase} />}
      {props.children}
    </Button>
  );
}
