import {
  IdentityTypeRegulatedTrust,
  TAddress,
  TTrust,
  TTrustType,
  TrustType,
} from '@verifime/utils';
import DisplayAddress from './DisplayAddress';
import InformationDisplay, { TInformationDisplayRows } from './InformationDisplay';

const regulatedTrustInformationRows: TInformationDisplayRows<keyof TTrust> = {
  row1: [{ label: 'Trust Name', fieldName: 'trustName' }],
  row2: [
    {
      label: 'Trust Type',
      fieldName: 'trustType',
      valueFormatter: (trustType: TTrustType) => TrustType[trustType] ?? 'Unknown',
    },
  ],
  row3: [
    {
      label: 'Australian Business Number (ABN) / Australian Registered Scheme Number (ARSN)',
      fieldName: 'businessNumber',
    },
  ],
  row4: [
    {
      label: 'Address',
      fieldName: 'address',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

const unregulatedTrustInformationRows: TInformationDisplayRows<keyof TTrust> = {
  row1: [{ label: 'Trust Name', fieldName: 'trustName' }],
  row2: [
    {
      label: 'Trust Type',
      fieldName: 'trustType',
      valueFormatter: (trustType: TTrustType) => TrustType[trustType] ?? 'Unknown',
    },
  ],
  row3: [{ label: 'Australian Business Number', fieldName: 'businessNumber' }],
  row4: [
    {
      label: 'Address',
      fieldName: 'address',
      valueFormatter: (address: TAddress) => <DisplayAddress address={address} showIcon={false} />,
    },
  ],
};

export default function TrustContact({ identity }: { identity: TTrust }) {
  return (
    <InformationDisplay<TTrust>
      informationDisplaysRows={
        identity.entityType === IdentityTypeRegulatedTrust.code
          ? regulatedTrustInformationRows
          : unregulatedTrustInformationRows
      }
      data={identity}
    />
  );
}
