import { Logout as LogoutIcon } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { stringUtils } from '@verifime/utils';

export type TLogoutButtonEmail = {
  value: string;
  fullwidth?: boolean;
};

export default function LogoutButton({
  doSignOut,
  email,
}: Readonly<{
  doSignOut: () => void;
  email?: TLogoutButtonEmail;
}>) {
  return (
    <ListItemButton
      onClick={doSignOut}
      sx={{ gap: tokens.spacingBase, justifyContent: 'space-between' }}
    >
      <ListItemIcon sx={{ minWidth: 'auto' }}>
        <LogoutIcon />
      </ListItemIcon>
      <Stack>
        {email && (
          <Typography color="textSecondary">
            {email.fullwidth
              ? email.value
              : stringUtils.ellipsis(email.value, 'end', { firstCharCount: 20 })}
          </Typography>
        )}
        <ListItemText primary="Logout" />
      </Stack>
    </ListItemButton>
  );
}
