import {
  IdentityTypeIndividual,
  IdentityTypePartnership,
  IdentityTypePrivateCompany,
  IdentityTypePublicCompany,
  IdentityTypeRegulatedTrust,
  IdentityTypeSMSF,
  IdentityTypeSoleTrader,
  IdentityTypeUnregulatedTrust,
  TCompany,
  TCustomer,
  TIndividual,
  TPartnership,
  TSoleTrader,
  TTrust,
} from '@verifime/utils';
import SmsfContact from './SmsfContact';
import IndividualContact from './IndividualContact';
import TrustContact from './TrustContact';
import CompanyContact from './CompanyContact';
import SoleTraderContact from './SoleTraderContact';
import PartnershipContact from './PartnershipContact';

export default function CustomerContact({ customer }: { customer: TCustomer }) {
  switch (customer.entityType) {
    case IdentityTypeIndividual.code:
      const individual = customer as TIndividual;
      return <IndividualContact identity={individual} />;
    case IdentityTypeSMSF.code:
      const smsf = customer as TTrust;
      return <SmsfContact identity={smsf} />;
    case IdentityTypeRegulatedTrust.code:
    case IdentityTypeUnregulatedTrust.code:
      const trust = customer as TTrust;
      return <TrustContact identity={trust} />;
    case IdentityTypePrivateCompany.code:
    case IdentityTypePublicCompany.code:
      const company = customer as TCompany;
      return <CompanyContact identity={company} />;
    case IdentityTypeSoleTrader.code:
      const soleTrader = customer as TSoleTrader;
      return <SoleTraderContact identity={soleTrader} />;
    case IdentityTypePartnership.code:
      const partnership = customer as TPartnership;
      return <PartnershipContact identity={partnership} />;
    default:
      return;
  }
}
