import Stack from '@mui/material/Stack';
import Link, { LinkProps } from '@mui/material/Link';
import tokens from '@verifime/design-tokens';

export function IconLink({ ...props }: { icon?: JSX.Element } & LinkProps) {
  return (
    <Stack justifyItems="center" alignItems="center" direction="row" gap={tokens.spacingXs} sx={{ cursor: 'pointer' }}>
      <Link {...props}>{props.children}</Link>
      {props.icon}
    </Stack>
  );
}
