/**
 *
 * @param path url path with placeholder (if any)
 * @param queryParams query params of http request
 * @param method  one of http request method
 * @param options http request options
 * @returns {{url: string, body?: string | undefined}}
 *
 */

export function getUrl(path, queryParams, method, options) {
  const params = Object.assign({}, queryParams || {});
  const replacedUrl = replacePlaceholder(path, params);

  if ('GET' === method) {
    return { url: `${replacedUrl}?${new URLSearchParams(params).toString()}` };
  }

  // For multipart/form-data content type, just treat queryParams as body which is passed in as FormData
  if (options?.isMultipart) {
    return {
      url: replacedUrl,
      body: queryParams,
    };
  }

  return {
    url: replacedUrl,
    body: JSON.stringify(params),
  };
}

/**
 *
 * To replace path placeholder with real value.
 *
 * `Note: this function is side effect function, which deletes placeholder keys from params`.
 *
 */
function replacePlaceholder(path, params) {
  return path.replace(/\{([^\\}]*(?:\\.[^\\}]*)*)\}/gm, function (_, key) {
    key = key.trim();
    if (params[key]) {
      return params[key];
    }
    console.warn('Please set value for template key: ', key);
    return '';
  });
}
