'use client';

import React, { useEffect, useState } from 'react';
import {
  alpha,
  Box,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Link,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { ChangeCircleRounded as SwitchIcon, Star as StarIcon } from '@mui/icons-material';
import { TOrganisation } from './types';
import tokens from '@verifime/design-tokens';
import FlexBox from './FlexBox';

export type TOrganisationSwitcherProps = {
  defaultOrgId: string;
  potentialOrgs: TOrganisation[];
  onChangeCurrentOrgId?: (currentOrgId: string) => void;
};

export default function OrganisationSwitcher({
  defaultOrgId,
  potentialOrgs,
  onChangeCurrentOrgId,
}: TOrganisationSwitcherProps): React.JSX.Element {
  const theme = useTheme();
  const [currentOrg, setCurrentOrg] = useState<TOrganisation>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const parentOrgs = potentialOrgs.filter((org) => org.children?.length > 0);
  const childOrgs = potentialOrgs.filter((org) => org.children?.length === 0);

  useEffect(() => {
    setCurrentOrg(childOrgs.find((org) => org.id === defaultOrgId) || childOrgs[0]);
  }, [defaultOrgId, childOrgs]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedOrg: TOrganisation,
  ) => {
    setCurrentOrg(selectedOrg);
    if (typeof onChangeCurrentOrgId === 'function') {
      onChangeCurrentOrgId(selectedOrg.id);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        maxWidth: tokens.size2xs,
        paddingX: tokens.spacingXs,
      }}
    >
      {currentOrg ? (
        <>
          <Typography variant="h6">{currentOrg.name}</Typography>
          {childOrgs.length > 1 && (
            <>
              <Box onClick={handleClickListItem}>
                <Link color={theme.palette.secondary.main}>Switch Organisation</Link>
                <SwitchIcon color="secondary" fontSize="inherit" />
              </Box>
              <Menu
                id="org-selection-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}
              >
                {parentOrgs.map((parentOrg) => {
                  return (
                    <Box paddingX={tokens.spacingBase} key={parentOrg.id}>
                      <Typography variant="caption">{parentOrg.name}</Typography>
                      {parentOrg.children.map((childOrg) => (
                        <MenuItem
                          key={childOrg.id}
                          selected={childOrg.id === currentOrg.id}
                          onClick={(event) => handleMenuItemClick(event, childOrg)}
                          sx={{
                            '&.Mui-selected': {
                              backgroundColor: alpha(theme.palette.primary.main, 0.2),
                            },
                          }}
                        >
                          <ListItemIcon>
                            <StarIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography>{childOrg.name}</Typography>
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </Box>
                  );
                })}
              </Menu>
            </>
          )}
        </>
      ) : (
        <FlexBox justifyContent="center">
          <CircularProgress />
        </FlexBox>
      )}
    </Box>
  );
}
