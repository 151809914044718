import { Typography, IconButton } from '@mui/material';
import { IdentityType } from '../types';
import Box from '@mui/material/Box';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { List, Stack, useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';
import CloseIcon from '@mui/icons-material/Close';
import FlexBox from '../FlexBox';
import { useHideDocumentScrollbar } from '../Hooks/useHideDocumentScrollbar';
import ItemToSelect from './ItemToSelect';
export type TItemType = {
  isDisabled?: boolean;
  type: IdentityType | string;
  description?: string;
  onClick?: () => void;
  path?: string;
};

export default function SelectItemDrawer({
  title,
  options,
  open,
  onClose,
  onSelect,
  anchor = 'bottom',
  ...otherProps
}: {
  onSelect?: (type: IdentityType | string) => void;
  options: TItemType[];
} & DrawerProps) {
  const theme = useTheme();
  useHideDocumentScrollbar(open);

  const handleItemClick = (type: IdentityType | string, onClick?: () => void) => {
    if (onSelect) {
      onSelect(type);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      transitionDuration={{
        enter: theme.transitions.duration.complex,
        exit: theme.transitions.duration.leavingScreen,
      }}
      {...otherProps}
    >
      <Box sx={{ maxHeight: '70vh', padding: tokens.spacingBase }}>
        <FlexBox justifyContent="space-between">
          <Typography variant="h4">{title}</Typography>
          <IconButton onClick={() => onClose('bottom', 'backdropClick')}>
            <CloseIcon />
          </IconButton>
        </FlexBox>
        <List sx={{ mt: tokens.spacingXl }} >
          <Stack gap={tokens.spacingBase} sx={{ alignItems: 'center'}}>
            {options.map(({ type, description, isDisabled, onClick, path }, index) => (
              <ItemToSelect
                name={type}
                description={description}
                key={index}
                onClick={() => handleItemClick(type, onClick)}
                path={path}
                disabled={isDisabled}
              />
            ))}
          </Stack>
        </List>
      </Box>
    </Drawer>
  );
}
