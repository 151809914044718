import {
  Checkbox,
  FormControlLabel,
  CheckboxProps,
  FormHelperText,
  FormControl,
  useTheme,
} from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { formFieldLabel } from '@verifime/components';
import { ReactNode } from 'react';

type TFormCheckboxProps = {
  name: string;
  control: Control;
  label: ReactNode | string;
  rules?: object;
  required?: boolean;
  error?: FieldError;
  size?: CheckboxProps['size'];
  onCheckBoxChange?: (isChecked: boolean) => void;
} & Omit<CheckboxProps, 'defaultValue'>;

export default function FormCheckbox({
  name,
  control,
  label,
  rules,
  required,
  defaultChecked,
  error,
  size = 'medium',
  onCheckBoxChange,
  ...props
}: TFormCheckboxProps) {
  const theme = useTheme();

  const requiredLabel: ReactNode = formFieldLabel(label, required);

  return (
    <FormControl>
      <FormControlLabel
        sx={{
          opacity: '0.8',
          color: error ? theme.palette.error.main : '',
          ...props.sx,
        }}
        control={
          <Controller
            defaultValue={defaultChecked}
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <Checkbox
                  size={size}
                  sx={{
                    color: error ? theme.palette.error.main : '',
                  }}
                  {...field}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    field.onChange(isChecked);
                    onCheckBoxChange?.(isChecked);
                  }}
                  {...props}
                  checked={field.value === true}
                />
              );
            }}
          />
        }
        label={requiredLabel}
      />
      {error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}
