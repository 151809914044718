import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { default as BackIcon } from '@mui/icons-material/ArrowBackRounded';
import Title from '../common/LoginTitle';
import tokens from '@verifime/design-tokens';

export type TAuthenticatorSetupHeader = {
  displayBack: boolean;
  title: string;
  subtitle: string;
  onBackClick: () => void;
};

export const AuthenticatorSetupHeader = ({
  displayBack,
  onBackClick,
  title,
  subtitle,
}: TAuthenticatorSetupHeader) => {
  return (
    <Stack sx={{ width: '100%' }}>
      {displayBack && (
        <IconButton
          onClick={() => {
            onBackClick?.();
          }}
          sx={{
            alignSelf: 'start',
            p: 0,
          }}
        >
          <BackIcon color="action" />
        </IconButton>
      )}
      <Title sx={{ mt: tokens.spacingXs }}>{title}</Title>
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
      <Icon />
    </Stack>
  );
};

export default AuthenticatorSetupHeader;
